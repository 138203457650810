import { PlLanguageService } from '../../../../../angular-common';
import { Injectable } from '@angular/core';
import { WebLanguage } from '../../../../../common/language/weblanguage.service'

@Injectable()
//t4Languages": "0.8.2.0
export class ImportLanguage extends WebLanguage {
  public constructor(plLanguageService: PlLanguageService) {
    super(plLanguageService);
  }

  /**
   * ConnectorAppName from f3Connector.csv (in EN: Imagine Connector )
   */
  public key_ConnectorAppName = "ConnectorAppName";
  /**
   * ConnectorAppName from f3Connector.csv (in EN: Imagine Connector )
   */
  public get ConnectorAppName(): string { return this.getStringDef(this.key_ConnectorAppName, "Imagine Connector"); }

  /**
   * ListingItem from f3Connector.csv (in EN: --}  )
   */
  public key_ListingItem = "ListingItem";
  /**
   * ListingItem from f3Connector.csv (in EN: --}  )
   */
  public get ListingItem(): string { return this.getStringDef(this.key_ListingItem, "--> "); }

  /**
   * ConfirmRemovalOfMutationDataOnClearingDataset from f3Connector.csv (in EN: You have choosen a target dataset with content ({0}, are you sure you want to remove those? )
   */
  public key_ConfirmRemovalOfMutationDataOnClearingDataset = "ConfirmRemovalOfMutationDataOnClearingDataset";
  /**
   * ConfirmRemovalOfMutationDataOnClearingDataset from f3Connector.csv (in EN: You have choosen a target dataset with content ({0}, are you sure you want to remove those? )
   */
  public ConfirmRemovalOfMutationDataOnClearingDataset(val1): string { return this.getStringDef("ConfirmRemovalOfMutationDataOnClearingDataset", "You have choosen a target dataset with content ({0}, are you sure you want to remove those?").replace("{0}", val1); }

  /**
   * ErrorCannotOverwrite from f3Connector.csv (in EN: The readonly file cannot be overwritten. )
   */
  public key_ErrorCannotOverwrite = "ErrorCannotOverwrite";
  /**
   * ErrorCannotOverwrite from f3Connector.csv (in EN: The readonly file cannot be overwritten. )
   */
  public get ErrorCannotOverwrite(): string { return this.getStringDef(this.key_ErrorCannotOverwrite, "The readonly file cannot be overwritten."); }

  /**
   * ErrorMustSelectDataSet from f3Connector.csv (in EN: Select a dataset. )
   */
  public key_ErrorMustSelectDataSet = "ErrorMustSelectDataSet";
  /**
   * ErrorMustSelectDataSet from f3Connector.csv (in EN: Select a dataset. )
   */
  public get ErrorMustSelectDataSet(): string { return this.getStringDef(this.key_ErrorMustSelectDataSet, "Select a dataset."); }

  /**
   * ErrorDataSetAlreadyExists from f3Connector.csv (in EN: The dataset name is already in use. )
   */
  public key_ErrorDataSetAlreadyExists = "ErrorDataSetAlreadyExists";
  /**
   * ErrorDataSetAlreadyExists from f3Connector.csv (in EN: The dataset name is already in use. )
   */
  public get ErrorDataSetAlreadyExists(): string { return this.getStringDef(this.key_ErrorDataSetAlreadyExists, "The dataset name is already in use."); }

  /**
   * ErrorInvalidDataSetName from f3Connector.csv (in EN: The dataset name is invalid. )
   */
  public key_ErrorInvalidDataSetName = "ErrorInvalidDataSetName";
  /**
   * ErrorInvalidDataSetName from f3Connector.csv (in EN: The dataset name is invalid. )
   */
  public get ErrorInvalidDataSetName(): string { return this.getStringDef(this.key_ErrorInvalidDataSetName, "The dataset name is invalid."); }

  /**
   * ErrorSavingFile from f3Connector.csv (in EN: An error occurred during saving of the file:'{0}' )
   */
  public key_ErrorSavingFile = "ErrorSavingFile";
  /**
   * ErrorSavingFile from f3Connector.csv (in EN: An error occurred during saving of the file:'{0}' )
   */
  public ErrorSavingFile(val1): string { return this.getStringDef("ErrorSavingFile", "An error occurred during saving of the file:'{0}'").replace("{0}", val1); }

  /**
   * ErrorOpeningFile from f3Connector.csv (in EN: An error occurred during opening of the file:'{0}' )
   */
  public key_ErrorOpeningFile = "ErrorOpeningFile";
  /**
   * ErrorOpeningFile from f3Connector.csv (in EN: An error occurred during opening of the file:'{0}' )
   */
  public ErrorOpeningFile(val1): string { return this.getStringDef("ErrorOpeningFile", "An error occurred during opening of the file:'{0}'").replace("{0}", val1); }

  /**
   * ErrorNoFilesToImport from f3Connector.csv (in EN: No files found. )
   */
  public key_ErrorNoFilesToImport = "ErrorNoFilesToImport";
  /**
   * ErrorNoFilesToImport from f3Connector.csv (in EN: No files found. )
   */
  public get ErrorNoFilesToImport(): string { return this.getStringDef(this.key_ErrorNoFilesToImport, "No files found."); }

  /**
   * ErrorFileNotSpecified from f3Connector.csv (in EN: No file has defined. )
   */
  public key_ErrorFileNotSpecified = "ErrorFileNotSpecified";
  /**
   * ErrorFileNotSpecified from f3Connector.csv (in EN: No file has defined. )
   */
  public get ErrorFileNotSpecified(): string { return this.getStringDef(this.key_ErrorFileNotSpecified, "No file has defined."); }

  /**
   * ErrorFileDoesNotExist from f3Connector.csv (in EN: File '{0}' is not found.The file cannot be opened. )
   */
  public key_ErrorFileDoesNotExist = "ErrorFileDoesNotExist";
  /**
   * ErrorFileDoesNotExist from f3Connector.csv (in EN: File '{0}' is not found.The file cannot be opened. )
   */
  public ErrorFileDoesNotExist(val1): string { return this.getStringDef("ErrorFileDoesNotExist", "File '{0}' is not found.The file cannot be opened.").replace("{0}", val1); }

  /**
   * ErrorNoDataFound from f3Connector.csv (in EN: Data cannot be retrieved. In case of Excel, check whether the correct Excel sheet is specified. Maybe the sheet is removed or renamed. )
   */
  public key_ErrorNoDataFound = "ErrorNoDataFound";
  /**
   * ErrorNoDataFound from f3Connector.csv (in EN: Data cannot be retrieved. In case of Excel, check whether the correct Excel sheet is specified. Maybe the sheet is removed or renamed. )
   */
  public get ErrorNoDataFound(): string { return this.getStringDef(this.key_ErrorNoDataFound, "Data cannot be retrieved. In case of Excel, check whether the correct Excel sheet is specified. Maybe the sheet is removed or renamed."); }

  /**
   * ErrorFileUnavailable from f3Connector.csv (in EN: The file {0} can not be opened, it might be in use. )
   */
  public key_ErrorFileUnavailable = "ErrorFileUnavailable";
  /**
   * ErrorFileUnavailable from f3Connector.csv (in EN: The file {0} can not be opened, it might be in use. )
   */
  public ErrorFileUnavailable(val1): string { return this.getStringDef("ErrorFileUnavailable", "The file {0} can not be opened, it might be in use.").replace("{0}", val1); }

  /**
   * ErrorNoColumnsFound from f3Connector.csv (in EN: No columns found. )
   */
  public key_ErrorNoColumnsFound = "ErrorNoColumnsFound";
  /**
   * ErrorNoColumnsFound from f3Connector.csv (in EN: No columns found. )
   */
  public get ErrorNoColumnsFound(): string { return this.getStringDef(this.key_ErrorNoColumnsFound, "No columns found."); }

  /**
   * ErrorNoColumnsFoundInExcel from f3Connector.csv (in EN: No columns found.Check whether the correct Excel sheet is specified.Maybe the sheet is removed or renamed. )
   */
  public key_ErrorNoColumnsFoundInExcel = "ErrorNoColumnsFoundInExcel";
  /**
   * ErrorNoColumnsFoundInExcel from f3Connector.csv (in EN: No columns found.Check whether the correct Excel sheet is specified.Maybe the sheet is removed or renamed. )
   */
  public get ErrorNoColumnsFoundInExcel(): string { return this.getStringDef(this.key_ErrorNoColumnsFoundInExcel, "No columns found.Check whether the correct Excel sheet is specified.Maybe the sheet is removed or renamed."); }

  /**
   * QuestionRemoveNewExogs from f3Connector.csv (in EN: Do you want to delete the newly defined exogenous variables? )
   */
  public key_QuestionRemoveNewExogs = "QuestionRemoveNewExogs";
  /**
   * QuestionRemoveNewExogs from f3Connector.csv (in EN: Do you want to delete the newly defined exogenous variables? )
   */
  public get QuestionRemoveNewExogs(): string { return this.getStringDef(this.key_QuestionRemoveNewExogs, "Do you want to delete the newly defined exogenous variables?"); }

  /**
   * QuestionUseOtherProject from f3Connector.csv (in EN: The import file'{0}'\works on a different project file.Select Yes for project file '{1}' te gebruikenSelect No for project file '{2}'. )
   */
  public key_QuestionUseOtherProject = "QuestionUseOtherProject";
  /**
   * QuestionUseOtherProject from f3Connector.csv (in EN: The import file'{0}'\works on a different project file.Select Yes for project file '{1}' te gebruikenSelect No for project file '{2}'. )
   */
  public QuestionUseOtherProject(val1, val2, val3): string { return this.getStringDef("QuestionUseOtherProject", "The import file'{0}'\works on a different project file.Select Yes for project file '{1}' te gebruikenSelect No for project file '{2}'.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * WarningFileIsReadonly from f3Connector.csv (in EN: The selected file is readonly and can only be saved with a different name. )
   */
  public key_WarningFileIsReadonly = "WarningFileIsReadonly";
  /**
   * WarningFileIsReadonly from f3Connector.csv (in EN: The selected file is readonly and can only be saved with a different name. )
   */
  public get WarningFileIsReadonly(): string { return this.getStringDef(this.key_WarningFileIsReadonly, "The selected file is readonly and can only be saved with a different name."); }

  /**
   * WarningSourcesWithoutData from f3Connector.csv (in EN: The following sources contain no data: )
   */
  public key_WarningSourcesWithoutData = "WarningSourcesWithoutData";
  /**
   * WarningSourcesWithoutData from f3Connector.csv (in EN: The following sources contain no data: )
   */
  public get WarningSourcesWithoutData(): string { return this.getStringDef(this.key_WarningSourcesWithoutData, "The following sources contain no data:"); }

  /**
   * ProjectFileDoesNotExist from f3Connector.csv (in EN: Project file '{0}' cannot be found. The currently active project file will be used. )
   */
  public key_ProjectFileDoesNotExist = "ProjectFileDoesNotExist";
  /**
   * ProjectFileDoesNotExist from f3Connector.csv (in EN: Project file '{0}' cannot be found. The currently active project file will be used. )
   */
  public ProjectFileDoesNotExist(val1): string { return this.getStringDef("ProjectFileDoesNotExist", "Project file '{0}' cannot be found. The currently active project file will be used.").replace("{0}", val1); }

  /**
   * ButtonSaveImport from f3Connector.csv (in EN: Save import report )
   */
  public key_ButtonSaveImport = "ButtonSaveImport";
  /**
   * ButtonSaveImport from f3Connector.csv (in EN: Save import report )
   */
  public get ButtonSaveImport(): string { return this.getStringDef(this.key_ButtonSaveImport, "Save import report"); }

  /**
   * OpenDialogFilterExcelExtensions from f3Connector.csv (in EN: *.xls;*.xlsx )
   */
  public key_OpenDialogFilterExcelExtensions = "OpenDialogFilterExcelExtensions";
  /**
   * OpenDialogFilterExcelExtensions from f3Connector.csv (in EN: *.xls;*.xlsx )
   */
  public get OpenDialogFilterExcelExtensions(): string { return this.getStringDef(this.key_OpenDialogFilterExcelExtensions, "*.xls;*.xlsx"); }

  /**
   * OpenDialogFilterCSVExtensions from f3Connector.csv (in EN: *.csv;*.txt;*.tab;*.asc )
   */
  public key_OpenDialogFilterCSVExtensions = "OpenDialogFilterCSVExtensions";
  /**
   * OpenDialogFilterCSVExtensions from f3Connector.csv (in EN: *.csv;*.txt;*.tab;*.asc )
   */
  public get OpenDialogFilterCSVExtensions(): string { return this.getStringDef(this.key_OpenDialogFilterCSVExtensions, "*.csv;*.txt;*.tab;*.asc"); }

  /**
   * OpenDialogFilterAllExtensions from f3Connector.csv (in EN: *.xls;*.xlsx;*.csv;*.txt;*.tab;*.asc )
   */
  public key_OpenDialogFilterAllExtensions = "OpenDialogFilterAllExtensions";
  /**
   * OpenDialogFilterAllExtensions from f3Connector.csv (in EN: *.xls;*.xlsx;*.csv;*.txt;*.tab;*.asc )
   */
  public get OpenDialogFilterAllExtensions(): string { return this.getStringDef(this.key_OpenDialogFilterAllExtensions, "*.xls;*.xlsx;*.csv;*.txt;*.tab;*.asc"); }

  /**
   * OpenDialogFilterExcelExtensionsDisplay from f3Connector.csv (in EN: *.xls, *.xlsx )
   */
  public key_OpenDialogFilterExcelExtensionsDisplay = "OpenDialogFilterExcelExtensionsDisplay";
  /**
   * OpenDialogFilterExcelExtensionsDisplay from f3Connector.csv (in EN: *.xls, *.xlsx )
   */
  public get OpenDialogFilterExcelExtensionsDisplay(): string { return this.getStringDef(this.key_OpenDialogFilterExcelExtensionsDisplay, "*.xls, *.xlsx"); }

  /**
   * OpenDialogFilterCSVExtensionsDisplay from f3Connector.csv (in EN: *.csv, *.txt, *.tab, *.asc )
   */
  public key_OpenDialogFilterCSVExtensionsDisplay = "OpenDialogFilterCSVExtensionsDisplay";
  /**
   * OpenDialogFilterCSVExtensionsDisplay from f3Connector.csv (in EN: *.csv, *.txt, *.tab, *.asc )
   */
  public get OpenDialogFilterCSVExtensionsDisplay(): string { return this.getStringDef(this.key_OpenDialogFilterCSVExtensionsDisplay, "*.csv, *.txt, *.tab, *.asc"); }

  /**
   * OpenDialogFilterAllExtensionsDisplay from f3Connector.csv (in EN: *.xls, *.xlsx, *.csv, *.txt, *.tab, *.asc )
   */
  public key_OpenDialogFilterAllExtensionsDisplay = "OpenDialogFilterAllExtensionsDisplay";
  /**
   * OpenDialogFilterAllExtensionsDisplay from f3Connector.csv (in EN: *.xls, *.xlsx, *.csv, *.txt, *.tab, *.asc )
   */
  public get OpenDialogFilterAllExtensionsDisplay(): string { return this.getStringDef(this.key_OpenDialogFilterAllExtensionsDisplay, "*.xls, *.xlsx, *.csv, *.txt, *.tab, *.asc"); }

  /**
   * OpenDialogFilter from f3Connector.csv (in EN: All files (*.xls, *.xlsx, *.csv, *.txt, *.tab, *.asc)|*.xls;*.xlsx;*.csv;*.txt;*.tab;*.asc|Excel files (*.xls, *.xlsx)|*.xls;*.xlsx|CSV files (*.csv, *.txt, *.tab, *.asc)|*.csv;*.txt;*.tab;*.asc )
   */
  public key_OpenDialogFilter = "OpenDialogFilter";
  /**
   * OpenDialogFilter from f3Connector.csv (in EN: All files (*.xls, *.xlsx, *.csv, *.txt, *.tab, *.asc)|*.xls;*.xlsx;*.csv;*.txt;*.tab;*.asc|Excel files (*.xls, *.xlsx)|*.xls;*.xlsx|CSV files (*.csv, *.txt, *.tab, *.asc)|*.csv;*.txt;*.tab;*.asc )
   */
  public get OpenDialogFilter(): string { return this.getStringDef(this.key_OpenDialogFilter, "All files (*.xls, *.xlsx, *.csv, *.txt, *.tab, *.asc)|*.xls;*.xlsx;*.csv;*.txt;*.tab;*.asc|Excel files (*.xls, *.xlsx)|*.xls;*.xlsx|CSV files (*.csv, *.txt, *.tab, *.asc)|*.csv;*.txt;*.tab;*.asc"); }

  /**
   * OpenDialogDefaultExtension from f3Connector.csv (in EN: xls )
   */
  public key_OpenDialogDefaultExtension = "OpenDialogDefaultExtension";
  /**
   * OpenDialogDefaultExtension from f3Connector.csv (in EN: xls )
   */
  public get OpenDialogDefaultExtension(): string { return this.getStringDef(this.key_OpenDialogDefaultExtension, "xls"); }

  /**
   * SaveDialogFilterText from f3Connector.csv (in EN: Text files (*.txt)|*.txt )
   */
  public key_SaveDialogFilterText = "SaveDialogFilterText";
  /**
   * SaveDialogFilterText from f3Connector.csv (in EN: Text files (*.txt)|*.txt )
   */
  public get SaveDialogFilterText(): string { return this.getStringDef(this.key_SaveDialogFilterText, "Text files (*.txt)|*.txt"); }

  /**
   * SaveDialogDefaultExtension from f3Connector.csv (in EN: txt )
   */
  public key_SaveDialogDefaultExtension = "SaveDialogDefaultExtension";
  /**
   * SaveDialogDefaultExtension from f3Connector.csv (in EN: txt )
   */
  public get SaveDialogDefaultExtension(): string { return this.getStringDef(this.key_SaveDialogDefaultExtension, "txt"); }

  /**
   * OpenDialogSchemaDefaultExtension from f3Connector.csv (in EN: .xsd )
   */
  public key_OpenDialogSchemaDefaultExtension = "OpenDialogSchemaDefaultExtension";
  /**
   * OpenDialogSchemaDefaultExtension from f3Connector.csv (in EN: .xsd )
   */
  public get OpenDialogSchemaDefaultExtension(): string { return this.getStringDef(this.key_OpenDialogSchemaDefaultExtension, ".xsd"); }

  /**
   * MainFormCaption from f3Connector.csv (in EN: Imagine Connector ({0}) )
   */
  public key_MainFormCaption = "MainFormCaption";
  /**
   * MainFormCaption from f3Connector.csv (in EN: Imagine Connector ({0}) )
   */
  public MainFormCaption(val1): string { return this.getStringDef("MainFormCaption", "Imagine Connector ({0})").replace("{0}", val1); }

  /**
   * MainMenuPerformDataEnrichment from f3Connector.csv (in EN: Perform Data Enrichment )
   */
  public key_MainMenuPerformDataEnrichment = "MainMenuPerformDataEnrichment";
  /**
   * MainMenuPerformDataEnrichment from f3Connector.csv (in EN: Perform Data Enrichment )
   */
  public get MainMenuPerformDataEnrichment(): string { return this.getStringDef(this.key_MainMenuPerformDataEnrichment, "Perform Data Enrichment"); }

  /**
   * MainMenuPerformDataImport from f3Connector.csv (in EN: Perform Data Import )
   */
  public key_MainMenuPerformDataImport = "MainMenuPerformDataImport";
  /**
   * MainMenuPerformDataImport from f3Connector.csv (in EN: Perform Data Import )
   */
  public get MainMenuPerformDataImport(): string { return this.getStringDef(this.key_MainMenuPerformDataImport, "Perform Data Import"); }

  /**
   * MainActionsGroup from f3Connector.csv (in EN:  Steps  )
   */
  public key_MainActionsGroup = "MainActionsGroup";
  /**
   * MainActionsGroup from f3Connector.csv (in EN:  Steps  )
   */
  public get MainActionsGroup(): string { return this.getStringDef(this.key_MainActionsGroup, " Steps "); }

  /**
   * MainDataSet from f3Connector.csv (in EN: Target dataset )
   */
  public key_MainDataSet = "MainDataSet";
  /**
   * MainDataSet from f3Connector.csv (in EN: Target dataset )
   */
  public get MainDataSet(): string { return this.getStringDef(this.key_MainDataSet, "Target dataset"); }

  /**
   * MainExogs from f3Connector.csv (in EN: Source file )
   */
  public key_MainExogs = "MainExogs";
  /**
   * MainExogs from f3Connector.csv (in EN: Source file )
   */
  public get MainExogs(): string { return this.getStringDef(this.key_MainExogs, "Source file"); }

  /**
   * MainRun from f3Connector.csv (in EN: Import )
   */
  public key_MainRun = "MainRun";
  /**
   * MainRun from f3Connector.csv (in EN: Import )
   */
  public get MainRun(): string { return this.getStringDef(this.key_MainRun, "Import"); }

  /**
   * MainDataSetDescription from f3Connector.csv (in EN: Select a new/existing dataset )
   */
  public key_MainDataSetDescription = "MainDataSetDescription";
  /**
   * MainDataSetDescription from f3Connector.csv (in EN: Select a new/existing dataset )
   */
  public get MainDataSetDescription(): string { return this.getStringDef(this.key_MainDataSetDescription, "Select a new/existing dataset"); }

  /**
   * MainDataSourceCaption from f3Connector.csv (in EN: Data source )
   */
  public key_MainDataSourceCaption = "MainDataSourceCaption";
  /**
   * MainDataSourceCaption from f3Connector.csv (in EN: Data source )
   */
  public get MainDataSourceCaption(): string { return this.getStringDef(this.key_MainDataSourceCaption, "Data source"); }

  /**
   * MainDataSourceDescription from f3Connector.csv (in EN: Select data source )
   */
  public key_MainDataSourceDescription = "MainDataSourceDescription";
  /**
   * MainDataSourceDescription from f3Connector.csv (in EN: Select data source )
   */
  public get MainDataSourceDescription(): string { return this.getStringDef(this.key_MainDataSourceDescription, "Select data source"); }

  /**
   * MainDataSourceUseCaption from f3Connector.csv (in EN: Source file )
   */
  public key_MainDataSourceUseCaption = "MainDataSourceUseCaption";
  /**
   * MainDataSourceUseCaption from f3Connector.csv (in EN: Source file )
   */
  public get MainDataSourceUseCaption(): string { return this.getStringDef(this.key_MainDataSourceUseCaption, "Source file"); }

  /**
   * MainDataSourceUseDescription from f3Connector.csv (in EN: Select the data with employee data )
   */
  public key_MainDataSourceUseDescription = "MainDataSourceUseDescription";
  /**
   * MainDataSourceUseDescription from f3Connector.csv (in EN: Select the data with employee data )
   */
  public get MainDataSourceUseDescription(): string { return this.getStringDef(this.key_MainDataSourceUseDescription, "Select the data with employee data"); }

  /**
   * MainRunDescription from f3Connector.csv (in EN: Start import )
   */
  public key_MainRunDescription = "MainRunDescription";
  /**
   * MainRunDescription from f3Connector.csv (in EN: Start import )
   */
  public get MainRunDescription(): string { return this.getStringDef(this.key_MainRunDescription, "Start import"); }

  /**
   * MainNewFileReadOnly from f3Connector.csv (in EN: New file bestand (read only) )
   */
  public key_MainNewFileReadOnly = "MainNewFileReadOnly";
  /**
   * MainNewFileReadOnly from f3Connector.csv (in EN: New file bestand (read only) )
   */
  public get MainNewFileReadOnly(): string { return this.getStringDef(this.key_MainNewFileReadOnly, "New file bestand (read only)"); }

  /**
   * MainNewFile from f3Connector.csv (in EN: New file )
   */
  public key_MainNewFile = "MainNewFile";
  /**
   * MainNewFile from f3Connector.csv (in EN: New file )
   */
  public get MainNewFile(): string { return this.getStringDef(this.key_MainNewFile, "New file"); }

  /**
   * MainReadOnly from f3Connector.csv (in EN: (read only) )
   */
  public key_MainReadOnly = "MainReadOnly";
  /**
   * MainReadOnly from f3Connector.csv (in EN: (read only) )
   */
  public get MainReadOnly(): string { return this.getStringDef(this.key_MainReadOnly, "(read only)"); }

  /**
   * MainNoActiveFile from f3Connector.csv (in EN: No active file )
   */
  public key_MainNoActiveFile = "MainNoActiveFile";
  /**
   * MainNoActiveFile from f3Connector.csv (in EN: No active file )
   */
  public get MainNoActiveFile(): string { return this.getStringDef(this.key_MainNoActiveFile, "No active file"); }

  /**
   * MainSaveQuery from f3Connector.csv (in EN: Do you want to save the current import settings? )
   */
  public key_MainSaveQuery = "MainSaveQuery";
  /**
   * MainSaveQuery from f3Connector.csv (in EN: Do you want to save the current import settings? )
   */
  public get MainSaveQuery(): string { return this.getStringDef(this.key_MainSaveQuery, "Do you want to save the current import settings?"); }

  /**
   * MainSaveQueryCaption from f3Connector.csv (in EN: Save )
   */
  public key_MainSaveQueryCaption = "MainSaveQueryCaption";
  /**
   * MainSaveQueryCaption from f3Connector.csv (in EN: Save )
   */
  public get MainSaveQueryCaption(): string { return this.getStringDef(this.key_MainSaveQueryCaption, "Save"); }

  /**
   * MainRecentProjects from f3Connector.csv (in EN: Recent projects )
   */
  public key_MainRecentProjects = "MainRecentProjects";
  /**
   * MainRecentProjects from f3Connector.csv (in EN: Recent projects )
   */
  public get MainRecentProjects(): string { return this.getStringDef(this.key_MainRecentProjects, "Recent projects"); }

  /**
   * MainMenuOptions from f3Connector.csv (in EN: Settings )
   */
  public key_MainMenuOptions = "MainMenuOptions";
  /**
   * MainMenuOptions from f3Connector.csv (in EN: Settings )
   */
  public get MainMenuOptions(): string { return this.getStringDef(this.key_MainMenuOptions, "Settings"); }

  /**
   * MainScripts from f3Connector.csv (in EN: Scripts )
   */
  public key_MainScripts = "MainScripts";
  /**
   * MainScripts from f3Connector.csv (in EN: Scripts )
   */
  public get MainScripts(): string { return this.getStringDef(this.key_MainScripts, "Scripts"); }

  /**
   * MainScriptsDescriptions from f3Connector.csv (in EN: Defines specific scripts )
   */
  public key_MainScriptsDescriptions = "MainScriptsDescriptions";
  /**
   * MainScriptsDescriptions from f3Connector.csv (in EN: Defines specific scripts )
   */
  public get MainScriptsDescriptions(): string { return this.getStringDef(this.key_MainScriptsDescriptions, "Defines specific scripts"); }

  /**
   * MainFileLocationModel from f3Connector.csv (in EN: From model {0} )
   */
  public key_MainFileLocationModel = "MainFileLocationModel";
  /**
   * MainFileLocationModel from f3Connector.csv (in EN: From model {0} )
   */
  public MainFileLocationModel(val1): string { return this.getStringDef("MainFileLocationModel", "From model {0}").replace("{0}", val1); }

  /**
   * DataSetCaption from f3Connector.csv (in EN: Target dataset )
   */
  public key_DataSetCaption = "DataSetCaption";
  /**
   * DataSetCaption from f3Connector.csv (in EN: Target dataset )
   */
  public get DataSetCaption(): string { return this.getStringDef(this.key_DataSetCaption, "Target dataset"); }

  /**
   * DataSetExisting from f3Connector.csv (in EN: To an existing dataset: )
   */
  public key_DataSetExisting = "DataSetExisting";
  /**
   * DataSetExisting from f3Connector.csv (in EN: To an existing dataset: )
   */
  public get DataSetExisting(): string { return this.getStringDef(this.key_DataSetExisting, "To an existing dataset:"); }

  /**
   * DataSetEmptyExisting from f3Connector.csv (in EN: Clear current dataset upon importing )
   */
  public key_DataSetEmptyExisting = "DataSetEmptyExisting";
  /**
   * DataSetEmptyExisting from f3Connector.csv (in EN: Clear current dataset upon importing )
   */
  public get DataSetEmptyExisting(): string { return this.getStringDef(this.key_DataSetEmptyExisting, "Clear current dataset upon importing"); }

  /**
   * DataSetNew from f3Connector.csv (in EN: To a new dataset: )
   */
  public key_DataSetNew = "DataSetNew";
  /**
   * DataSetNew from f3Connector.csv (in EN: To a new dataset: )
   */
  public get DataSetNew(): string { return this.getStringDef(this.key_DataSetNew, "To a new dataset:"); }

  /**
   * DataSetBasedOn from f3Connector.csv (in EN: based on )
   */
  public key_DataSetBasedOn = "DataSetBasedOn";
  /**
   * DataSetBasedOn from f3Connector.csv (in EN: based on )
   */
  public get DataSetBasedOn(): string { return this.getStringDef(this.key_DataSetBasedOn, "based on"); }

  /**
   * DataSetGroup from f3Connector.csv (in EN:  To which dataset must the data be saved?  )
   */
  public key_DataSetGroup = "DataSetGroup";
  /**
   * DataSetGroup from f3Connector.csv (in EN:  To which dataset must the data be saved?  )
   */
  public get DataSetGroup(): string { return this.getStringDef(this.key_DataSetGroup, " To which dataset must the data be saved? "); }

  /**
   * DataSetNewName from f3Connector.csv (in EN: New name: )
   */
  public key_DataSetNewName = "DataSetNewName";
  /**
   * DataSetNewName from f3Connector.csv (in EN: New name: )
   */
  public get DataSetNewName(): string { return this.getStringDef(this.key_DataSetNewName, "New name:"); }

  /**
   * DataSetTabPage from f3Connector.csv (in EN: Dataset selection )
   */
  public key_DataSetTabPage = "DataSetTabPage";
  /**
   * DataSetTabPage from f3Connector.csv (in EN: Dataset selection )
   */
  public get DataSetTabPage(): string { return this.getStringDef(this.key_DataSetTabPage, "Dataset selection"); }

  /**
   * VariantTabPage from f3Connector.csv (in EN: Variant selection )
   */
  public key_VariantTabPage = "VariantTabPage";
  /**
   * VariantTabPage from f3Connector.csv (in EN: Variant selection )
   */
  public get VariantTabPage(): string { return this.getStringDef(this.key_VariantTabPage, "Variant selection"); }

  /**
   * ComputeVariantSelect from f3Connector.csv (in EN: Select a variant to compute )
   */
  public key_ComputeVariantSelect = "ComputeVariantSelect";
  /**
   * ComputeVariantSelect from f3Connector.csv (in EN: Select a variant to compute )
   */
  public get ComputeVariantSelect(): string { return this.getStringDef(this.key_ComputeVariantSelect, "Select a variant to compute"); }

  /**
   * ComputeVariantCheck from f3Connector.csv (in EN: Compute variant )
   */
  public key_ComputeVariantCheck = "ComputeVariantCheck";
  /**
   * ComputeVariantCheck from f3Connector.csv (in EN: Compute variant )
   */
  public get ComputeVariantCheck(): string { return this.getStringDef(this.key_ComputeVariantCheck, "Compute variant"); }

  /**
   * EmploymentTabPage from f3Connector.csv (in EN: Employment )
   */
  public key_EmploymentTabPage = "EmploymentTabPage";
  /**
   * EmploymentTabPage from f3Connector.csv (in EN: Employment )
   */
  public get EmploymentTabPage(): string { return this.getStringDef(this.key_EmploymentTabPage, "Employment"); }

  /**
   * EmploymentExplanationPeriods from f3Connector.csv (in EN: In case employees didn't work all periods in the current year, you can take into account the start and end of employement below. )
   */
  public key_EmploymentExplanationPeriods = "EmploymentExplanationPeriods";
  /**
   * EmploymentExplanationPeriods from f3Connector.csv (in EN: In case employees didn't work all periods in the current year, you can take into account the start and end of employement below. )
   */
  public get EmploymentExplanationPeriods(): string { return this.getStringDef(this.key_EmploymentExplanationPeriods, "In case employees didn't work all periods in the current year, you can take into account the start and end of employement below."); }

  /**
   * EmploymentExplanationMonths from f3Connector.csv (in EN: In case employees didn't work all months in the current year, you can take into account the start and end of employement below. )
   */
  public key_EmploymentExplanationMonths = "EmploymentExplanationMonths";
  /**
   * EmploymentExplanationMonths from f3Connector.csv (in EN: In case employees didn't work all months in the current year, you can take into account the start and end of employement below. )
   */
  public get EmploymentExplanationMonths(): string { return this.getStringDef(this.key_EmploymentExplanationMonths, "In case employees didn't work all months in the current year, you can take into account the start and end of employement below."); }

  /**
   * YieldToDateTabPage from f3Connector.csv (in EN:  Yield to date  )
   */
  public key_YieldToDateTabPage = "YieldToDateTabPage";
  /**
   * YieldToDateTabPage from f3Connector.csv (in EN:  Yield to date  )
   */
  public get YieldToDateTabPage(): string { return this.getStringDef(this.key_YieldToDateTabPage, " Yield to date "); }

  /**
   * YieldToDateExplanation from f3Connector.csv (in EN: Some values might be cumulated to a certain kind of date. Please specify this date below: )
   */
  public key_YieldToDateExplanation = "YieldToDateExplanation";
  /**
   * YieldToDateExplanation from f3Connector.csv (in EN: Some values might be cumulated to a certain kind of date. Please specify this date below: )
   */
  public get YieldToDateExplanation(): string { return this.getStringDef(this.key_YieldToDateExplanation, "Some values might be cumulated to a certain kind of date. Please specify this date below:"); }

  /**
   * YieldToDateUse from f3Connector.csv (in EN: Use custom yield date )
   */
  public key_YieldToDateUse = "YieldToDateUse";
  /**
   * YieldToDateUse from f3Connector.csv (in EN: Use custom yield date )
   */
  public get YieldToDateUse(): string { return this.getStringDef(this.key_YieldToDateUse, "Use custom yield date"); }

  /**
   * StartToDateExplanation from f3Connector.csv (in EN: Some values might be dependend on a start period. Please specify this date below: )
   */
  public key_StartToDateExplanation = "StartToDateExplanation";
  /**
   * StartToDateExplanation from f3Connector.csv (in EN: Some values might be dependend on a start period. Please specify this date below: )
   */
  public get StartToDateExplanation(): string { return this.getStringDef(this.key_StartToDateExplanation, "Some values might be dependend on a start period. Please specify this date below:"); }

  /**
   * StartToDateUse from f3Connector.csv (in EN: Use custom start date )
   */
  public key_StartToDateUse = "StartToDateUse";
  /**
   * StartToDateUse from f3Connector.csv (in EN: Use custom start date )
   */
  public get StartToDateUse(): string { return this.getStringDef(this.key_StartToDateUse, "Use custom start date"); }

  /**
   * EndDateToDateExplanation from f3Connector.csv (in EN: Some values might be dependend on a end period. Please specify this date below: )
   */
  public key_EndDateToDateExplanation = "EndDateToDateExplanation";
  /**
   * EndDateToDateExplanation from f3Connector.csv (in EN: Some values might be dependend on a end period. Please specify this date below: )
   */
  public get EndDateToDateExplanation(): string { return this.getStringDef(this.key_EndDateToDateExplanation, "Some values might be dependend on a end period. Please specify this date below:"); }

  /**
   * EndDateToDateUse from f3Connector.csv (in EN: Use custom end date )
   */
  public key_EndDateToDateUse = "EndDateToDateUse";
  /**
   * EndDateToDateUse from f3Connector.csv (in EN: Use custom end date )
   */
  public get EndDateToDateUse(): string { return this.getStringDef(this.key_EndDateToDateUse, "Use custom end date"); }

  /**
   * SettingsSelectStartDate from f3Connector.csv (in EN: Select exog 'start date employment' )
   */
  public key_SettingsSelectStartDate = "SettingsSelectStartDate";
  /**
   * SettingsSelectStartDate from f3Connector.csv (in EN: Select exog 'start date employment' )
   */
  public get SettingsSelectStartDate(): string { return this.getStringDef(this.key_SettingsSelectStartDate, "Select exog 'start date employment'"); }

  /**
   * SettingsSelectEndDate from f3Connector.csv (in EN: Select exog 'end date employment' )
   */
  public key_SettingsSelectEndDate = "SettingsSelectEndDate";
  /**
   * SettingsSelectEndDate from f3Connector.csv (in EN: Select exog 'end date employment' )
   */
  public get SettingsSelectEndDate(): string { return this.getStringDef(this.key_SettingsSelectEndDate, "Select exog 'end date employment'"); }

  /**
   * SettingsUseStartDate from f3Connector.csv (in EN: Use employment start date. )
   */
  public key_SettingsUseStartDate = "SettingsUseStartDate";
  /**
   * SettingsUseStartDate from f3Connector.csv (in EN: Use employment start date. )
   */
  public get SettingsUseStartDate(): string { return this.getStringDef(this.key_SettingsUseStartDate, "Use employment start date."); }

  /**
   * SettingsUseEndDate from f3Connector.csv (in EN: Use employment end date. )
   */
  public key_SettingsUseEndDate = "SettingsUseEndDate";
  /**
   * SettingsUseEndDate from f3Connector.csv (in EN: Use employment end date. )
   */
  public get SettingsUseEndDate(): string { return this.getStringDef(this.key_SettingsUseEndDate, "Use employment end date."); }

  /**
   * AdvancedTabPage from f3Connector.csv (in EN: Advanced )
   */
  public key_AdvancedTabPage = "AdvancedTabPage";
  /**
   * AdvancedTabPage from f3Connector.csv (in EN: Advanced )
   */
  public get AdvancedTabPage(): string { return this.getStringDef(this.key_AdvancedTabPage, "Advanced"); }

  /**
   * ValidateOnImport from f3Connector.csv (in EN: Validate whether the data complies to the datatypes of the exogenous variables. )
   */
  public key_ValidateOnImport = "ValidateOnImport";
  /**
   * ValidateOnImport from f3Connector.csv (in EN: Validate whether the data complies to the datatypes of the exogenous variables. )
   */
  public get ValidateOnImport(): string { return this.getStringDef(this.key_ValidateOnImport, "Validate whether the data complies to the datatypes of the exogenous variables."); }

  /**
   * OverrideExogenousDataTypes from f3Connector.csv (in EN: Change exogenous variable types )
   */
  public key_OverrideExogenousDataTypes = "OverrideExogenousDataTypes";
  /**
   * OverrideExogenousDataTypes from f3Connector.csv (in EN: Change exogenous variable types )
   */
  public get OverrideExogenousDataTypes(): string { return this.getStringDef(this.key_OverrideExogenousDataTypes, "Change exogenous variable types"); }

  /**
   * MutationsTabPage from f3Connector.csv (in EN: Mutations )
   */
  public key_MutationsTabPage = "MutationsTabPage";
  /**
   * MutationsTabPage from f3Connector.csv (in EN: Mutations )
   */
  public get MutationsTabPage(): string { return this.getStringDef(this.key_MutationsTabPage, "Mutations"); }

  /**
   * MutationsGroup from f3Connector.csv (in EN: Select starting period for mutations )
   */
  public key_MutationsGroup = "MutationsGroup";
  /**
   * MutationsGroup from f3Connector.csv (in EN: Select starting period for mutations )
   */
  public get MutationsGroup(): string { return this.getStringDef(this.key_MutationsGroup, "Select starting period for mutations"); }

  /**
   * MutationsStartDate from f3Connector.csv (in EN: Start date )
   */
  public key_MutationsStartDate = "MutationsStartDate";
  /**
   * MutationsStartDate from f3Connector.csv (in EN: Start date )
   */
  public get MutationsStartDate(): string { return this.getStringDef(this.key_MutationsStartDate, "Start date"); }

  /**
   * MutationsStartDateCurrent from f3Connector.csv (in EN: Use the date on which the import takes place )
   */
  public key_MutationsStartDateCurrent = "MutationsStartDateCurrent";
  /**
   * MutationsStartDateCurrent from f3Connector.csv (in EN: Use the date on which the import takes place )
   */
  public get MutationsStartDateCurrent(): string { return this.getStringDef(this.key_MutationsStartDateCurrent, "Use the date on which the import takes place"); }

  /**
   * MutationsStartDateSpecific from f3Connector.csv (in EN: Use a specific date: )
   */
  public key_MutationsStartDateSpecific = "MutationsStartDateSpecific";
  /**
   * MutationsStartDateSpecific from f3Connector.csv (in EN: Use a specific date: )
   */
  public get MutationsStartDateSpecific(): string { return this.getStringDef(this.key_MutationsStartDateSpecific, "Use a specific date:"); }

  /**
   * MutationsStartDateMapped from f3Connector.csv (in EN: Mapped through an imported file )
   */
  public key_MutationsStartDateMapped = "MutationsStartDateMapped";
  /**
   * MutationsStartDateMapped from f3Connector.csv (in EN: Mapped through an imported file )
   */
  public get MutationsStartDateMapped(): string { return this.getStringDef(this.key_MutationsStartDateMapped, "Mapped through an imported file"); }

  /**
   * MutationsStartDateNone from f3Connector.csv (in EN: No mutation date )
   */
  public key_MutationsStartDateNone = "MutationsStartDateNone";
  /**
   * MutationsStartDateNone from f3Connector.csv (in EN: No mutation date )
   */
  public get MutationsStartDateNone(): string { return this.getStringDef(this.key_MutationsStartDateNone, "No mutation date"); }

  /**
   * MutationsStartDateNotMapped from f3Connector.csv (in EN: A mapping for the starting period of mutations is missing. )
   */
  public key_MutationsStartDateNotMapped = "MutationsStartDateNotMapped";
  /**
   * MutationsStartDateNotMapped from f3Connector.csv (in EN: A mapping for the starting period of mutations is missing. )
   */
  public get MutationsStartDateNotMapped(): string { return this.getStringDef(this.key_MutationsStartDateNotMapped, "A mapping for the starting period of mutations is missing."); }

  /**
   * MutationsStartDateShouldNotBeMapped from f3Connector.csv (in EN: A mapping exists for the starting period of mutations but this mapping should be ignored. )
   */
  public key_MutationsStartDateShouldNotBeMapped = "MutationsStartDateShouldNotBeMapped";
  /**
   * MutationsStartDateShouldNotBeMapped from f3Connector.csv (in EN: A mapping exists for the starting period of mutations but this mapping should be ignored. )
   */
  public get MutationsStartDateShouldNotBeMapped(): string { return this.getStringDef(this.key_MutationsStartDateShouldNotBeMapped, "A mapping exists for the starting period of mutations but this mapping should be ignored."); }

  /**
   * MutationsStartDateContainsNullValues from f3Connector.csv (in EN: The starting period of mutations contains one or more null values. )
   */
  public key_MutationsStartDateContainsNullValues = "MutationsStartDateContainsNullValues";
  /**
   * MutationsStartDateContainsNullValues from f3Connector.csv (in EN: The starting period of mutations contains one or more null values. )
   */
  public get MutationsStartDateContainsNullValues(): string { return this.getStringDef(this.key_MutationsStartDateContainsNullValues, "The starting period of mutations contains one or more null values."); }

  /**
   * MutationReasonText from f3Connector.csv (in EN: Import {0} )
   */
  public key_MutationReasonText = "MutationReasonText";
  /**
   * MutationReasonText from f3Connector.csv (in EN: Import {0} )
   */
  public MutationReasonText(val1): string { return this.getStringDef("MutationReasonText", "Import {0}").replace("{0}", val1); }

  /**
   * MutationReasonTextEnrichtment from f3Connector.csv (in EN: Enrichtment {0} )
   */
  public key_MutationReasonTextEnrichtment = "MutationReasonTextEnrichtment";
  /**
   * MutationReasonTextEnrichtment from f3Connector.csv (in EN: Enrichtment {0} )
   */
  public MutationReasonTextEnrichtment(val1): string { return this.getStringDef("MutationReasonTextEnrichtment", "Enrichtment {0}").replace("{0}", val1); }

  /**
   * ExogsImportFiles from f3Connector.csv (in EN: Import files: )
   */
  public key_ExogsImportFiles = "ExogsImportFiles";
  /**
   * ExogsImportFiles from f3Connector.csv (in EN: Import files: )
   */
  public get ExogsImportFiles(): string { return this.getStringDef(this.key_ExogsImportFiles, "Import files:"); }

  /**
   * ExogsErrorRead from f3Connector.csv (in EN: Error reading {0}{1} )
   */
  public key_ExogsErrorRead = "ExogsErrorRead";
  /**
   * ExogsErrorRead from f3Connector.csv (in EN: Error reading {0}{1} )
   */
  public ExogsErrorRead(val1, val2): string { return this.getStringDef("ExogsErrorRead", "Error reading {0}{1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * ExogsFileName from f3Connector.csv (in EN: File )
   */
  public key_ExogsFileName = "ExogsFileName";
  /**
   * ExogsFileName from f3Connector.csv (in EN: File )
   */
  public get ExogsFileName(): string { return this.getStringDef(this.key_ExogsFileName, "File"); }

  /**
   * ExogsFileType from f3Connector.csv (in EN: Type )
   */
  public key_ExogsFileType = "ExogsFileType";
  /**
   * ExogsFileType from f3Connector.csv (in EN: Type )
   */
  public get ExogsFileType(): string { return this.getStringDef(this.key_ExogsFileType, "Type"); }

  /**
   * ExogsSheetName from f3Connector.csv (in EN: Worksheet (Excel)/Tabel (SQL) )
   */
  public key_ExogsSheetName = "ExogsSheetName";
  /**
   * ExogsSheetName from f3Connector.csv (in EN: Worksheet (Excel)/Tabel (SQL) )
   */
  public get ExogsSheetName(): string { return this.getStringDef(this.key_ExogsSheetName, "Worksheet (Excel)/Tabel (SQL)"); }

  /**
   * ExogsSheetNameSelect from f3Connector.csv (in EN: Select a table )
   */
  public key_ExogsSheetNameSelect = "ExogsSheetNameSelect";
  /**
   * ExogsSheetNameSelect from f3Connector.csv (in EN: Select a table )
   */
  public get ExogsSheetNameSelect(): string { return this.getStringDef(this.key_ExogsSheetNameSelect, "Select a table"); }

  /**
   * ExogsKeyFields from f3Connector.csv (in EN: Key )
   */
  public key_ExogsKeyFields = "ExogsKeyFields";
  /**
   * ExogsKeyFields from f3Connector.csv (in EN: Key )
   */
  public get ExogsKeyFields(): string { return this.getStringDef(this.key_ExogsKeyFields, "Key"); }

  /**
   * ExogsMapping from f3Connector.csv (in EN: Mappings )
   */
  public key_ExogsMapping = "ExogsMapping";
  /**
   * ExogsMapping from f3Connector.csv (in EN: Mappings )
   */
  public get ExogsMapping(): string { return this.getStringDef(this.key_ExogsMapping, "Mappings"); }

  /**
   * ExogsMappingKeys from f3Connector.csv (in EN: Key mappings )
   */
  public key_ExogsMappingKeys = "ExogsMappingKeys";
  /**
   * ExogsMappingKeys from f3Connector.csv (in EN: Key mappings )
   */
  public get ExogsMappingKeys(): string { return this.getStringDef(this.key_ExogsMappingKeys, "Key mappings"); }

  /**
   * DataUsageDataSource from f3Connector.csv (in EN: Source )
   */
  public key_DataUsageDataSource = "DataUsageDataSource";
  /**
   * DataUsageDataSource from f3Connector.csv (in EN: Source )
   */
  public get DataUsageDataSource(): string { return this.getStringDef(this.key_DataUsageDataSource, "Source"); }

  /**
   * ExogsDataSupplyName from f3Connector.csv (in EN: Name )
   */
  public key_ExogsDataSupplyName = "ExogsDataSupplyName";
  /**
   * ExogsDataSupplyName from f3Connector.csv (in EN: Name )
   */
  public get ExogsDataSupplyName(): string { return this.getStringDef(this.key_ExogsDataSupplyName, "Name"); }

  /**
   * ExogsDescription from f3Connector.csv (in EN: Description )
   */
  public key_ExogsDescription = "ExogsDescription";
  /**
   * ExogsDescription from f3Connector.csv (in EN: Description )
   */
  public get ExogsDescription(): string { return this.getStringDef(this.key_ExogsDescription, "Description"); }

  /**
   * ExogsPrimary from f3Connector.csv (in EN: Main )
   */
  public key_ExogsPrimary = "ExogsPrimary";
  /**
   * ExogsPrimary from f3Connector.csv (in EN: Main )
   */
  public get ExogsPrimary(): string { return this.getStringDef(this.key_ExogsPrimary, "Main"); }

  /**
   * ExogsDetectColumnTypeOnContent from f3Connector.csv (in EN: Detect types )
   */
  public key_ExogsDetectColumnTypeOnContent = "ExogsDetectColumnTypeOnContent";
  /**
   * ExogsDetectColumnTypeOnContent from f3Connector.csv (in EN: Detect types )
   */
  public get ExogsDetectColumnTypeOnContent(): string { return this.getStringDef(this.key_ExogsDetectColumnTypeOnContent, "Detect types"); }

  /**
   * ExogsTableType from f3Connector.csv (in EN: Data )
   */
  public key_ExogsTableType = "ExogsTableType";
  /**
   * ExogsTableType from f3Connector.csv (in EN: Data )
   */
  public get ExogsTableType(): string { return this.getStringDef(this.key_ExogsTableType, "Data"); }

  /**
   * ExogsColBased from f3Connector.csv (in EN: Columns )
   */
  public key_ExogsColBased = "ExogsColBased";
  /**
   * ExogsColBased from f3Connector.csv (in EN: Columns )
   */
  public get ExogsColBased(): string { return this.getStringDef(this.key_ExogsColBased, "Columns"); }

  /**
   * ExogsRowBased from f3Connector.csv (in EN: Rows )
   */
  public key_ExogsRowBased = "ExogsRowBased";
  /**
   * ExogsRowBased from f3Connector.csv (in EN: Rows )
   */
  public get ExogsRowBased(): string { return this.getStringDef(this.key_ExogsRowBased, "Rows"); }

  /**
   * ExogsComponent from f3Connector.csv (in EN: Component )
   */
  public key_ExogsComponent = "ExogsComponent";
  /**
   * ExogsComponent from f3Connector.csv (in EN: Component )
   */
  public get ExogsComponent(): string { return this.getStringDef(this.key_ExogsComponent, "Component"); }

  /**
   * FileSupplierTypeUnknown from f3Connector.csv (in EN: Unknown )
   */
  public key_FileSupplierTypeUnknown = "FileSupplierTypeUnknown";
  /**
   * FileSupplierTypeUnknown from f3Connector.csv (in EN: Unknown )
   */
  public get FileSupplierTypeUnknown(): string { return this.getStringDef(this.key_FileSupplierTypeUnknown, "Unknown"); }

  /**
   * FileSupplierTypeFolder from f3Connector.csv (in EN: Folder )
   */
  public key_FileSupplierTypeFolder = "FileSupplierTypeFolder";
  /**
   * FileSupplierTypeFolder from f3Connector.csv (in EN: Folder )
   */
  public get FileSupplierTypeFolder(): string { return this.getStringDef(this.key_FileSupplierTypeFolder, "Folder"); }

  /**
   * FileSupplierTypeSftp from f3Connector.csv (in EN: SFTP )
   */
  public key_FileSupplierTypeSftp = "FileSupplierTypeSftp";
  /**
   * FileSupplierTypeSftp from f3Connector.csv (in EN: SFTP )
   */
  public get FileSupplierTypeSftp(): string { return this.getStringDef(this.key_FileSupplierTypeSftp, "SFTP"); }

  /**
   * FileSupplierTypeVismaRaet from f3Connector.csv (in EN: Visma Raet File API )
   */
  public key_FileSupplierTypeVismaRaet = "FileSupplierTypeVismaRaet";
  /**
   * FileSupplierTypeVismaRaet from f3Connector.csv (in EN: Visma Raet File API )
   */
  public get FileSupplierTypeVismaRaet(): string { return this.getStringDef(this.key_FileSupplierTypeVismaRaet, "Visma Raet File API"); }

  /**
   * FileSupplierLocation from f3Connector.csv (in EN: Location )
   */
  public key_FileSupplierLocation = "FileSupplierLocation";
  /**
   * FileSupplierLocation from f3Connector.csv (in EN: Location )
   */
  public get FileSupplierLocation(): string { return this.getStringDef(this.key_FileSupplierLocation, "Location"); }

  /**
   * FileSupplierTypeName from f3Connector.csv (in EN: Data source )
   */
  public key_FileSupplierTypeName = "FileSupplierTypeName";
  /**
   * FileSupplierTypeName from f3Connector.csv (in EN: Data source )
   */
  public get FileSupplierTypeName(): string { return this.getStringDef(this.key_FileSupplierTypeName, "Data source"); }

  /**
   * DataConnectionSelectionHint from f3Connector.csv (in EN: Specify the data connection )
   */
  public key_DataConnectionSelectionHint = "DataConnectionSelectionHint";
  /**
   * DataConnectionSelectionHint from f3Connector.csv (in EN: Specify the data connection )
   */
  public get DataConnectionSelectionHint(): string { return this.getStringDef(this.key_DataConnectionSelectionHint, "Specify the data connection"); }

  /**
   * ExogsDatasetDefinitionKeys from f3Connector.csv (in EN: The keys in the dataset are: {0}. )
   */
  public key_ExogsDatasetDefinitionKeys = "ExogsDatasetDefinitionKeys";
  /**
   * ExogsDatasetDefinitionKeys from f3Connector.csv (in EN: The keys in the dataset are: {0}. )
   */
  public ExogsDatasetDefinitionKeys(val1): string { return this.getStringDef("ExogsDatasetDefinitionKeys", "The keys in the dataset are: {0}.").replace("{0}", val1); }

  /**
   * WarningReferenceCategoryDiffersBetweenMappingAndExogenousSpecification from f3Connector.csv (in EN: There is a difference between the reference of the exogenous {0} and the variable {1} for {2}. )
   */
  public key_WarningReferenceCategoryDiffersBetweenMappingAndExogenousSpecification = "WarningReferenceCategoryDiffersBetweenMappingAndExogenousSpecification";
  /**
   * WarningReferenceCategoryDiffersBetweenMappingAndExogenousSpecification from f3Connector.csv (in EN: There is a difference between the reference of the exogenous {0} and the variable {1} for {2}. )
   */
  public WarningReferenceCategoryDiffersBetweenMappingAndExogenousSpecification(val1, val2, val3): string { return this.getStringDef("WarningReferenceCategoryDiffersBetweenMappingAndExogenousSpecification", "There is a difference between the reference of the exogenous {0} and the variable {1} for {2}.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * WarnSourceKeyMultipleUpdateAssumption from f3Connector.csv (in EN: Import file {0} has key field {1} (which is not mapped to any destination key) and the field is used multiple times, we assume the mapping to {2} is leading. )
   */
  public key_WarnSourceKeyMultipleUpdateAssumption = "WarnSourceKeyMultipleUpdateAssumption";
  /**
   * WarnSourceKeyMultipleUpdateAssumption from f3Connector.csv (in EN: Import file {0} has key field {1} (which is not mapped to any destination key) and the field is used multiple times, we assume the mapping to {2} is leading. )
   */
  public WarnSourceKeyMultipleUpdateAssumption(val1, val2, val3): string { return this.getStringDef("WarnSourceKeyMultipleUpdateAssumption", "Import file {0} has key field {1} (which is not mapped to any destination key) and the field is used multiple times, we assume the mapping to {2} is leading.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunCaption from f3Connector.csv (in EN: Import )
   */
  public key_RunCaption = "RunCaption";
  /**
   * RunCaption from f3Connector.csv (in EN: Import )
   */
  public get RunCaption(): string { return this.getStringDef(this.key_RunCaption, "Import"); }

  /**
   * RunStart from f3Connector.csv (in EN: Import )
   */
  public key_RunStart = "RunStart";
  /**
   * RunStart from f3Connector.csv (in EN: Import )
   */
  public get RunStart(): string { return this.getStringDef(this.key_RunStart, "Import"); }

  /**
   * RunErrorMainProgress from f3Connector.csv (in EN: Error found during processing of the import. )
   */
  public key_RunErrorMainProgress = "RunErrorMainProgress";
  /**
   * RunErrorMainProgress from f3Connector.csv (in EN: Error found during processing of the import. )
   */
  public get RunErrorMainProgress(): string { return this.getStringDef(this.key_RunErrorMainProgress, "Error found during processing of the import."); }

  /**
   * RunErrorImport from f3Connector.csv (in EN: Error during import {0} )
   */
  public key_RunErrorImport = "RunErrorImport";
  /**
   * RunErrorImport from f3Connector.csv (in EN: Error during import {0} )
   */
  public RunErrorImport(val1): string { return this.getStringDef("RunErrorImport", "Error during import {0}").replace("{0}", val1); }

  /**
   * RunClose from f3Connector.csv (in EN: Close )
   */
  public key_RunClose = "RunClose";
  /**
   * RunClose from f3Connector.csv (in EN: Close )
   */
  public get RunClose(): string { return this.getStringDef(this.key_RunClose, "Close"); }

  /**
   * RunStep1 from f3Connector.csv (in EN: De volgende stappen worden uitgevoerd:  )
   */
  public key_RunStep1 = "RunStep1";
  /**
   * RunStep1 from f3Connector.csv (in EN: De volgende stappen worden uitgevoerd:  )
   */
  public get RunStep1(): string { return this.getStringDef(this.key_RunStep1, "De volgende stappen worden uitgevoerd: "); }

  /**
   * RunStep2 from f3Connector.csv (in EN: - reading and validation of source files )
   */
  public key_RunStep2 = "RunStep2";
  /**
   * RunStep2 from f3Connector.csv (in EN: - reading and validation of source files )
   */
  public get RunStep2(): string { return this.getStringDef(this.key_RunStep2, "- reading and validation of source files"); }

  /**
   * RunStep3 from f3Connector.csv (in EN: - place source files in temporary tables )
   */
  public key_RunStep3 = "RunStep3";
  /**
   * RunStep3 from f3Connector.csv (in EN: - place source files in temporary tables )
   */
  public get RunStep3(): string { return this.getStringDef(this.key_RunStep3, "- place source files in temporary tables"); }

  /**
   * RunStep4 from f3Connector.csv (in EN: - execute conversions )
   */
  public key_RunStep4 = "RunStep4";
  /**
   * RunStep4 from f3Connector.csv (in EN: - execute conversions )
   */
  public get RunStep4(): string { return this.getStringDef(this.key_RunStep4, "- execute conversions"); }

  /**
   * RunStep5 from f3Connector.csv (in EN: - fill targettabel )
   */
  public key_RunStep5 = "RunStep5";
  /**
   * RunStep5 from f3Connector.csv (in EN: - fill targettabel )
   */
  public get RunStep5(): string { return this.getStringDef(this.key_RunStep5, "- fill targettabel"); }

  /**
   * RunQueryFile from f3Connector.csv (in EN: Queries are saved into '{0}'. )
   */
  public key_RunQueryFile = "RunQueryFile";
  /**
   * RunQueryFile from f3Connector.csv (in EN: Queries are saved into '{0}'. )
   */
  public RunQueryFile(val1): string { return this.getStringDef("RunQueryFile", "Queries are saved into '{0}'.").replace("{0}", val1); }

  /**
   * RunQueryParameters from f3Connector.csv (in EN: Parameters: {0}  )
   */
  public key_RunQueryParameters = "RunQueryParameters";
  /**
   * RunQueryParameters from f3Connector.csv (in EN: Parameters: {0}  )
   */
  public RunQueryParameters(val1): string { return this.getStringDef("RunQueryParameters", "Parameters: {0} ").replace("{0}", val1); }

  /**
   * RunQueryConnectionName from f3Connector.csv (in EN: Connection: {0} )
   */
  public key_RunQueryConnectionName = "RunQueryConnectionName";
  /**
   * RunQueryConnectionName from f3Connector.csv (in EN: Connection: {0} )
   */
  public RunQueryConnectionName(val1): string { return this.getStringDef("RunQueryConnectionName", "Connection: {0}").replace("{0}", val1); }

  /**
   * RunExecuteScript from f3Connector.csv (in EN: Execute script: {0} )
   */
  public key_RunExecuteScript = "RunExecuteScript";
  /**
   * RunExecuteScript from f3Connector.csv (in EN: Execute script: {0} )
   */
  public RunExecuteScript(val1): string { return this.getStringDef("RunExecuteScript", "Execute script: {0}").replace("{0}", val1); }

  /**
   * RunReferenceFileText from f3Connector.csv (in EN: Category: {0}, Reference:  {1}-{2} )
   */
  public key_RunReferenceFileText = "RunReferenceFileText";
  /**
   * RunReferenceFileText from f3Connector.csv (in EN: Category: {0}, Reference:  {1}-{2} )
   */
  public RunReferenceFileText(val1, val2, val3): string { return this.getStringDef("RunReferenceFileText", "Category: {0}, Reference:  {1}-{2}").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunReferenceFile from f3Connector.csv (in EN: List with added references is saved into '{0}'. )
   */
  public key_RunReferenceFile = "RunReferenceFile";
  /**
   * RunReferenceFile from f3Connector.csv (in EN: List with added references is saved into '{0}'. )
   */
  public RunReferenceFile(val1): string { return this.getStringDef("RunReferenceFile", "List with added references is saved into '{0}'.").replace("{0}", val1); }

  /**
   * RunTemporaryVariableValuesStored from f3Connector.csv (in EN: The content of the script variables can be viewed via the table _TemporaryImportVariables. )
   */
  public key_RunTemporaryVariableValuesStored = "RunTemporaryVariableValuesStored";
  /**
   * RunTemporaryVariableValuesStored from f3Connector.csv (in EN: The content of the script variables can be viewed via the table _TemporaryImportVariables. )
   */
  public get RunTemporaryVariableValuesStored(): string { return this.getStringDef(this.key_RunTemporaryVariableValuesStored, "The content of the script variables can be viewed via the table _TemporaryImportVariables."); }

  /**
   * RunConfirmImportWithoutReadingAndValidation from f3Connector.csv (in EN: This option can only be used when the temporary tables of a previous import are still available.Do you want to continue with this option? )
   */
  public key_RunConfirmImportWithoutReadingAndValidation = "RunConfirmImportWithoutReadingAndValidation";
  /**
   * RunConfirmImportWithoutReadingAndValidation from f3Connector.csv (in EN: This option can only be used when the temporary tables of a previous import are still available.Do you want to continue with this option? )
   */
  public get RunConfirmImportWithoutReadingAndValidation(): string { return this.getStringDef(this.key_RunConfirmImportWithoutReadingAndValidation, "This option can only be used when the temporary tables of a previous import are still available.Do you want to continue with this option?"); }

  /**
   * RunStartWithoutReadingAndValidation from f3Connector.csv (in EN: Import (without reading data) )
   */
  public key_RunStartWithoutReadingAndValidation = "RunStartWithoutReadingAndValidation";
  /**
   * RunStartWithoutReadingAndValidation from f3Connector.csv (in EN: Import (without reading data) )
   */
  public get RunStartWithoutReadingAndValidation(): string { return this.getStringDef(this.key_RunStartWithoutReadingAndValidation, "Import (without reading data)"); }

  /**
   * RunPrepare from f3Connector.csv (in EN: Prepare... )
   */
  public key_RunPrepare = "RunPrepare";
  /**
   * RunPrepare from f3Connector.csv (in EN: Prepare... )
   */
  public get RunPrepare(): string { return this.getStringDef(this.key_RunPrepare, "Prepare..."); }

  /**
   * RunRowFormat from f3Connector.csv (in EN: {0} rows )
   */
  public key_RunRowFormat = "RunRowFormat";
  /**
   * RunRowFormat from f3Connector.csv (in EN: {0} rows )
   */
  public RunRowFormat(val1): string { return this.getStringDef("RunRowFormat", "{0} rows").replace("{0}", val1); }

  /**
   * RunMainProgressDetermineEmployment from f3Connector.csv (in EN: Determine employee employments. )
   */
  public key_RunMainProgressDetermineEmployment = "RunMainProgressDetermineEmployment";
  /**
   * RunMainProgressDetermineEmployment from f3Connector.csv (in EN: Determine employee employments. )
   */
  public get RunMainProgressDetermineEmployment(): string { return this.getStringDef(this.key_RunMainProgressDetermineEmployment, "Determine employee employments."); }

  /**
   * RunMainProgressDoAppendCurrentDataToImportData from f3Connector.csv (in EN: Extending {0} with current data from {1} for {2} columns. )
   */
  public key_RunMainProgressDoAppendCurrentDataToImportData = "RunMainProgressDoAppendCurrentDataToImportData";
  /**
   * RunMainProgressDoAppendCurrentDataToImportData from f3Connector.csv (in EN: Extending {0} with current data from {1} for {2} columns. )
   */
  public RunMainProgressDoAppendCurrentDataToImportData(val1, val2, val3): string { return this.getStringDef("RunMainProgressDoAppendCurrentDataToImportData", "Extending {0} with current data from {1} for {2} columns.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunMainProgressFinishOk from f3Connector.csv (in EN: Import was succesfull. )
   */
  public key_RunMainProgressFinishOk = "RunMainProgressFinishOk";
  /**
   * RunMainProgressFinishOk from f3Connector.csv (in EN: Import was succesfull. )
   */
  public get RunMainProgressFinishOk(): string { return this.getStringDef(this.key_RunMainProgressFinishOk, "Import was succesfull."); }

  /**
   * RunMainProgressFinishWithErrors from f3Connector.csv (in EN: An error occurred. )
   */
  public key_RunMainProgressFinishWithErrors = "RunMainProgressFinishWithErrors";
  /**
   * RunMainProgressFinishWithErrors from f3Connector.csv (in EN: An error occurred. )
   */
  public get RunMainProgressFinishWithErrors(): string { return this.getStringDef(this.key_RunMainProgressFinishWithErrors, "An error occurred."); }

  /**
   * RunMainProgressFinishWithSourcesWithoutData from f3Connector.csv (in EN: There are sources without contents. )
   */
  public key_RunMainProgressFinishWithSourcesWithoutData = "RunMainProgressFinishWithSourcesWithoutData";
  /**
   * RunMainProgressFinishWithSourcesWithoutData from f3Connector.csv (in EN: There are sources without contents. )
   */
  public get RunMainProgressFinishWithSourcesWithoutData(): string { return this.getStringDef(this.key_RunMainProgressFinishWithSourcesWithoutData, "There are sources without contents."); }

  /**
   * RunMainProgressFinishWithDuplicateKeyValues from f3Connector.csv (in EN: There are double key values in the imported table. )
   */
  public key_RunMainProgressFinishWithDuplicateKeyValues = "RunMainProgressFinishWithDuplicateKeyValues";
  /**
   * RunMainProgressFinishWithDuplicateKeyValues from f3Connector.csv (in EN: There are double key values in the imported table. )
   */
  public get RunMainProgressFinishWithDuplicateKeyValues(): string { return this.getStringDef(this.key_RunMainProgressFinishWithDuplicateKeyValues, "There are double key values in the imported table."); }

  /**
   * SelectExogsCaption from f3Connector.csv (in EN: Select exogenous variable )
   */
  public key_SelectExogsCaption = "SelectExogsCaption";
  /**
   * SelectExogsCaption from f3Connector.csv (in EN: Select exogenous variable )
   */
  public get SelectExogsCaption(): string { return this.getStringDef(this.key_SelectExogsCaption, "Select exogenous variable"); }

  /**
   * SelectExogsEmploymentMissingStartData from f3Connector.csv (in EN: The exogenous variable 'Start date employement' has not been found. Select the variable from the list below. )
   */
  public key_SelectExogsEmploymentMissingStartData = "SelectExogsEmploymentMissingStartData";
  /**
   * SelectExogsEmploymentMissingStartData from f3Connector.csv (in EN: The exogenous variable 'Start date employement' has not been found. Select the variable from the list below. )
   */
  public get SelectExogsEmploymentMissingStartData(): string { return this.getStringDef(this.key_SelectExogsEmploymentMissingStartData, "The exogenous variable 'Start date employement' has not been found. Select the variable from the list below."); }

  /**
   * SelectExogsEmploymentMissingEndData from f3Connector.csv (in EN: The exogenous variable 'End date employement' has not been found. Select the variable from the list below. )
   */
  public key_SelectExogsEmploymentMissingEndData = "SelectExogsEmploymentMissingEndData";
  /**
   * SelectExogsEmploymentMissingEndData from f3Connector.csv (in EN: The exogenous variable 'End date employement' has not been found. Select the variable from the list below. )
   */
  public get SelectExogsEmploymentMissingEndData(): string { return this.getStringDef(this.key_SelectExogsEmploymentMissingEndData, "The exogenous variable 'End date employement' has not been found. Select the variable from the list below."); }

  /**
   * SelectExogEmploymentStartData from f3Connector.csv (in EN: Select the exogenous variable 'Start date employement'. )
   */
  public key_SelectExogEmploymentStartData = "SelectExogEmploymentStartData";
  /**
   * SelectExogEmploymentStartData from f3Connector.csv (in EN: Select the exogenous variable 'Start date employement'. )
   */
  public get SelectExogEmploymentStartData(): string { return this.getStringDef(this.key_SelectExogEmploymentStartData, "Select the exogenous variable 'Start date employement'."); }

  /**
   * SelectExogEmploymentEndData from f3Connector.csv (in EN: Select the exogenous variable 'End date employement'. )
   */
  public key_SelectExogEmploymentEndData = "SelectExogEmploymentEndData";
  /**
   * SelectExogEmploymentEndData from f3Connector.csv (in EN: Select the exogenous variable 'End date employement'. )
   */
  public get SelectExogEmploymentEndData(): string { return this.getStringDef(this.key_SelectExogEmploymentEndData, "Select the exogenous variable 'End date employement'."); }

  /**
   * SelectExogMutationImportFilteringEnable from f3Connector.csv (in EN: Select fields that are exclude during equality comparison )
   */
  public key_SelectExogMutationImportFilteringEnable = "SelectExogMutationImportFilteringEnable";
  /**
   * SelectExogMutationImportFilteringEnable from f3Connector.csv (in EN: Select fields that are exclude during equality comparison )
   */
  public get SelectExogMutationImportFilteringEnable(): string { return this.getStringDef(this.key_SelectExogMutationImportFilteringEnable, "Select fields that are exclude during equality comparison"); }

  /**
   * SelectExogMutationImportFilteringExogs from f3Connector.csv (in EN: Select the exogenous that are exclude during equality comparison )
   */
  public key_SelectExogMutationImportFilteringExogs = "SelectExogMutationImportFilteringExogs";
  /**
   * SelectExogMutationImportFilteringExogs from f3Connector.csv (in EN: Select the exogenous that are exclude during equality comparison )
   */
  public get SelectExogMutationImportFilteringExogs(): string { return this.getStringDef(this.key_SelectExogMutationImportFilteringExogs, "Select the exogenous that are exclude during equality comparison"); }

  /**
   * SelectExogMutationImportFilteringExogsBtn from f3Connector.csv (in EN: Select exogeneous )
   */
  public key_SelectExogMutationImportFilteringExogsBtn = "SelectExogMutationImportFilteringExogsBtn";
  /**
   * SelectExogMutationImportFilteringExogsBtn from f3Connector.csv (in EN: Select exogeneous )
   */
  public get SelectExogMutationImportFilteringExogsBtn(): string { return this.getStringDef(this.key_SelectExogMutationImportFilteringExogsBtn, "Select exogeneous"); }

  /**
   * SelectExogMutationImportFilteringExogCategories from f3Connector.csv (in EN: Select the exogenous categories that are exclude during equality comparison )
   */
  public key_SelectExogMutationImportFilteringExogCategories = "SelectExogMutationImportFilteringExogCategories";
  /**
   * SelectExogMutationImportFilteringExogCategories from f3Connector.csv (in EN: Select the exogenous categories that are exclude during equality comparison )
   */
  public get SelectExogMutationImportFilteringExogCategories(): string { return this.getStringDef(this.key_SelectExogMutationImportFilteringExogCategories, "Select the exogenous categories that are exclude during equality comparison"); }

  /**
   * SelectExogMutationImportFilteringExogCategoriesBtn from f3Connector.csv (in EN: Select categories )
   */
  public key_SelectExogMutationImportFilteringExogCategoriesBtn = "SelectExogMutationImportFilteringExogCategoriesBtn";
  /**
   * SelectExogMutationImportFilteringExogCategoriesBtn from f3Connector.csv (in EN: Select categories )
   */
  public get SelectExogMutationImportFilteringExogCategoriesBtn(): string { return this.getStringDef(this.key_SelectExogMutationImportFilteringExogCategoriesBtn, "Select categories"); }

  /**
   * UseStartDateAsTemporaryKeyDuringImport from f3Connector.csv (in EN: Treat start date as key during the import )
   */
  public key_UseStartDateAsTemporaryKeyDuringImport = "UseStartDateAsTemporaryKeyDuringImport";
  /**
   * UseStartDateAsTemporaryKeyDuringImport from f3Connector.csv (in EN: Treat start date as key during the import )
   */
  public get UseStartDateAsTemporaryKeyDuringImport(): string { return this.getStringDef(this.key_UseStartDateAsTemporaryKeyDuringImport, "Treat start date as key during the import"); }

  /**
   * PreviewCaption from f3Connector.csv (in EN: Example )
   */
  public key_PreviewCaption = "PreviewCaption";
  /**
   * PreviewCaption from f3Connector.csv (in EN: Example )
   */
  public get PreviewCaption(): string { return this.getStringDef(this.key_PreviewCaption, "Example"); }

  /**
   * PreviewNumberOfRecords from f3Connector.csv (in EN: {0} records in source file )
   */
  public key_PreviewNumberOfRecords = "PreviewNumberOfRecords";
  /**
   * PreviewNumberOfRecords from f3Connector.csv (in EN: {0} records in source file )
   */
  public PreviewNumberOfRecords(val1): string { return this.getStringDef("PreviewNumberOfRecords", "{0} records in source file").replace("{0}", val1); }

  /**
   * PreviewShowNumbersOfRecords from f3Connector.csv (in EN: Number of records to show:  )
   */
  public key_PreviewShowNumbersOfRecords = "PreviewShowNumbersOfRecords";
  /**
   * PreviewShowNumbersOfRecords from f3Connector.csv (in EN: Number of records to show:  )
   */
  public get PreviewShowNumbersOfRecords(): string { return this.getStringDef(this.key_PreviewShowNumbersOfRecords, "Number of records to show: "); }

  /**
   * PreviewRefresh from f3Connector.csv (in EN: Example )
   */
  public key_PreviewRefresh = "PreviewRefresh";
  /**
   * PreviewRefresh from f3Connector.csv (in EN: Example )
   */
  public get PreviewRefresh(): string { return this.getStringDef(this.key_PreviewRefresh, "Example"); }

  /**
   * PreviewUnknownNumberOfRows from f3Connector.csv (in EN: Unknown number of rows )
   */
  public key_PreviewUnknownNumberOfRows = "PreviewUnknownNumberOfRows";
  /**
   * PreviewUnknownNumberOfRows from f3Connector.csv (in EN: Unknown number of rows )
   */
  public get PreviewUnknownNumberOfRows(): string { return this.getStringDef(this.key_PreviewUnknownNumberOfRows, "Unknown number of rows"); }

  /**
   * PreviewDataFilteredToMatchAuthorization from f3Connector.csv (in EN: Current visible data has been filtered to match your authorization )
   */
  public key_PreviewDataFilteredToMatchAuthorization = "PreviewDataFilteredToMatchAuthorization";
  /**
   * PreviewDataFilteredToMatchAuthorization from f3Connector.csv (in EN: Current visible data has been filtered to match your authorization )
   */
  public get PreviewDataFilteredToMatchAuthorization(): string { return this.getStringDef(this.key_PreviewDataFilteredToMatchAuthorization, "Current visible data has been filtered to match your authorization"); }

  /**
   * SettingsCaption from f3Connector.csv (in EN: Settings )
   */
  public key_SettingsCaption = "SettingsCaption";
  /**
   * SettingsCaption from f3Connector.csv (in EN: Settings )
   */
  public get SettingsCaption(): string { return this.getStringDef(this.key_SettingsCaption, "Settings"); }

  /**
   * SettingsUseShortNames from f3Connector.csv (in EN: Show short names )
   */
  public key_SettingsUseShortNames = "SettingsUseShortNames";
  /**
   * SettingsUseShortNames from f3Connector.csv (in EN: Show short names )
   */
  public get SettingsUseShortNames(): string { return this.getStringDef(this.key_SettingsUseShortNames, "Show short names"); }

  /**
   * SettingsStoreExecutedQueries from f3Connector.csv (in EN: Save executes queries )
   */
  public key_SettingsStoreExecutedQueries = "SettingsStoreExecutedQueries";
  /**
   * SettingsStoreExecutedQueries from f3Connector.csv (in EN: Save executes queries )
   */
  public get SettingsStoreExecutedQueries(): string { return this.getStringDef(this.key_SettingsStoreExecutedQueries, "Save executes queries"); }

  /**
   * SettingsKeepTemporaryTables from f3Connector.csv (in EN: Don't remove temporary import tables. )
   */
  public key_SettingsKeepTemporaryTables = "SettingsKeepTemporaryTables";
  /**
   * SettingsKeepTemporaryTables from f3Connector.csv (in EN: Don't remove temporary import tables. )
   */
  public get SettingsKeepTemporaryTables(): string { return this.getStringDef(this.key_SettingsKeepTemporaryTables, "Don't remove temporary import tables."); }

  /**
   * SettingsEnableMultipleUserImport from f3Connector.csv (in EN: Assign unique names to temporary tables (for running multiple imports at the same time) )
   */
  public key_SettingsEnableMultipleUserImport = "SettingsEnableMultipleUserImport";
  /**
   * SettingsEnableMultipleUserImport from f3Connector.csv (in EN: Assign unique names to temporary tables (for running multiple imports at the same time) )
   */
  public get SettingsEnableMultipleUserImport(): string { return this.getStringDef(this.key_SettingsEnableMultipleUserImport, "Assign unique names to temporary tables (for running multiple imports at the same time)"); }

  /**
   * SettingsStoreAddedReferenceToFile from f3Connector.csv (in EN: Store added references in a file. )
   */
  public key_SettingsStoreAddedReferenceToFile = "SettingsStoreAddedReferenceToFile";
  /**
   * SettingsStoreAddedReferenceToFile from f3Connector.csv (in EN: Store added references in a file. )
   */
  public get SettingsStoreAddedReferenceToFile(): string { return this.getStringDef(this.key_SettingsStoreAddedReferenceToFile, "Store added references in a file."); }

  /**
   * MappingsCaption from f3Connector.csv (in EN: Mappings )
   */
  public key_MappingsCaption = "MappingsCaption";
  /**
   * MappingsCaption from f3Connector.csv (in EN: Mappings )
   */
  public get MappingsCaption(): string { return this.getStringDef(this.key_MappingsCaption, "Mappings"); }

  /**
   * MappingsSource from f3Connector.csv (in EN: Source )
   */
  public key_MappingsSource = "MappingsSource";
  /**
   * MappingsSource from f3Connector.csv (in EN: Source )
   */
  public get MappingsSource(): string { return this.getStringDef(this.key_MappingsSource, "Source"); }

  /**
   * DataSourceConfigureCsvCaption from f3Connector.csv (in EN: CSV schema specification )
   */
  public key_DataSourceConfigureCsvCaption = "DataSourceConfigureCsvCaption";
  /**
   * DataSourceConfigureCsvCaption from f3Connector.csv (in EN: CSV schema specification )
   */
  public get DataSourceConfigureCsvCaption(): string { return this.getStringDef(this.key_DataSourceConfigureCsvCaption, "CSV schema specification"); }

  /**
   * DataSourceConfigureCsvFiles from f3Connector.csv (in EN: Files )
   */
  public key_DataSourceConfigureCsvFiles = "DataSourceConfigureCsvFiles";
  /**
   * DataSourceConfigureCsvFiles from f3Connector.csv (in EN: Files )
   */
  public get DataSourceConfigureCsvFiles(): string { return this.getStringDef(this.key_DataSourceConfigureCsvFiles, "Files"); }

  /**
   * MappingsDestination from f3Connector.csv (in EN: Target )
   */
  public key_MappingsDestination = "MappingsDestination";
  /**
   * MappingsDestination from f3Connector.csv (in EN: Target )
   */
  public get MappingsDestination(): string { return this.getStringDef(this.key_MappingsDestination, "Target"); }

  /**
   * MappingsNoChange from f3Connector.csv (in EN: {ignore} )
   */
  public key_MappingsNoChange = "MappingsNoChange";
  /**
   * MappingsNoChange from f3Connector.csv (in EN: {ignore} )
   */
  public get MappingsNoChange(): string { return this.getStringDef(this.key_MappingsNoChange, "<ignore>"); }

  /**
   * MappingsFileName from f3Connector.csv (in EN: Mappings for '{0}'   )
   */
  public key_MappingsFileName = "MappingsFileName";
  /**
   * MappingsFileName from f3Connector.csv (in EN: Mappings for '{0}'   )
   */
  public MappingsFileName(val1): string { return this.getStringDef("MappingsFileName", "Mappings for '{0}'  ").replace("{0}", val1); }

  /**
   * MappingsComponent from f3Connector.csv (in EN: Component )
   */
  public key_MappingsComponent = "MappingsComponent";
  /**
   * MappingsComponent from f3Connector.csv (in EN: Component )
   */
  public get MappingsComponent(): string { return this.getStringDef(this.key_MappingsComponent, "Component"); }

  /**
   * MappingsExog from f3Connector.csv (in EN: Exogenous variables )
   */
  public key_MappingsExog = "MappingsExog";
  /**
   * MappingsExog from f3Connector.csv (in EN: Exogenous variables )
   */
  public get MappingsExog(): string { return this.getStringDef(this.key_MappingsExog, "Exogenous variables"); }

  /**
   * MappingsValue from f3Connector.csv (in EN: Fieldvalue )
   */
  public key_MappingsValue = "MappingsValue";
  /**
   * MappingsValue from f3Connector.csv (in EN: Fieldvalue )
   */
  public get MappingsValue(): string { return this.getStringDef(this.key_MappingsValue, "Fieldvalue"); }

  /**
   * MappingsTransformation from f3Connector.csv (in EN: Transformation )
   */
  public key_MappingsTransformation = "MappingsTransformation";
  /**
   * MappingsTransformation from f3Connector.csv (in EN: Transformation )
   */
  public get MappingsTransformation(): string { return this.getStringDef(this.key_MappingsTransformation, "Transformation"); }

  /**
   * MappingsPeriod from f3Connector.csv (in EN: Period )
   */
  public key_MappingsPeriod = "MappingsPeriod";
  /**
   * MappingsPeriod from f3Connector.csv (in EN: Period )
   */
  public get MappingsPeriod(): string { return this.getStringDef(this.key_MappingsPeriod, "Period"); }

  /**
   * MappingsMapToImportTableKeyField from f3Connector.csv (in EN: Key Field )
   */
  public key_MappingsMapToImportTableKeyField = "MappingsMapToImportTableKeyField";
  /**
   * MappingsMapToImportTableKeyField from f3Connector.csv (in EN: Key Field )
   */
  public get MappingsMapToImportTableKeyField(): string { return this.getStringDef(this.key_MappingsMapToImportTableKeyField, "Key Field"); }

  /**
   * MappingsNoKeyFieldChosen from f3Connector.csv (in EN: Key Field {0} is not mapped )
   */
  public key_MappingsNoKeyFieldChosen = "MappingsNoKeyFieldChosen";
  /**
   * MappingsNoKeyFieldChosen from f3Connector.csv (in EN: Key Field {0} is not mapped )
   */
  public MappingsNoKeyFieldChosen(val1): string { return this.getStringDef("MappingsNoKeyFieldChosen", "Key Field {0} is not mapped").replace("{0}", val1); }

  /**
   * MappingsNoKeyFieldChosenTitle from f3Connector.csv (in EN: Incomplete mapping )
   */
  public key_MappingsNoKeyFieldChosenTitle = "MappingsNoKeyFieldChosenTitle";
  /**
   * MappingsNoKeyFieldChosenTitle from f3Connector.csv (in EN: Incomplete mapping )
   */
  public get MappingsNoKeyFieldChosenTitle(): string { return this.getStringDef(this.key_MappingsNoKeyFieldChosenTitle, "Incomplete mapping"); }

  /**
   * MappingsPeriodNoChange from f3Connector.csv (in EN: No change )
   */
  public key_MappingsPeriodNoChange = "MappingsPeriodNoChange";
  /**
   * MappingsPeriodNoChange from f3Connector.csv (in EN: No change )
   */
  public get MappingsPeriodNoChange(): string { return this.getStringDef(this.key_MappingsPeriodNoChange, "No change"); }

  /**
   * MappingsPeriodCurrentPeriod from f3Connector.csv (in EN: Current month )
   */
  public key_MappingsPeriodCurrentPeriod = "MappingsPeriodCurrentPeriod";
  /**
   * MappingsPeriodCurrentPeriod from f3Connector.csv (in EN: Current month )
   */
  public get MappingsPeriodCurrentPeriod(): string { return this.getStringDef(this.key_MappingsPeriodCurrentPeriod, "Current month"); }

  /**
   * MappingsPeriodYear from f3Connector.csv (in EN: Year )
   */
  public key_MappingsPeriodYear = "MappingsPeriodYear";
  /**
   * MappingsPeriodYear from f3Connector.csv (in EN: Year )
   */
  public get MappingsPeriodYear(): string { return this.getStringDef(this.key_MappingsPeriodYear, "Year"); }

  /**
   * MappingsPeriodYieldDate from f3Connector.csv (in EN: Yield to date )
   */
  public key_MappingsPeriodYieldDate = "MappingsPeriodYieldDate";
  /**
   * MappingsPeriodYieldDate from f3Connector.csv (in EN: Yield to date )
   */
  public get MappingsPeriodYieldDate(): string { return this.getStringDef(this.key_MappingsPeriodYieldDate, "Yield to date"); }

  /**
   * MappingsRefCat from f3Connector.csv (in EN: Reference )
   */
  public key_MappingsRefCat = "MappingsRefCat";
  /**
   * MappingsRefCat from f3Connector.csv (in EN: Reference )
   */
  public get MappingsRefCat(): string { return this.getStringDef(this.key_MappingsRefCat, "Reference"); }

  /**
   * MappingsDuplicateMapping from f3Connector.csv (in EN: Duplicate mapping )
   */
  public key_MappingsDuplicateMapping = "MappingsDuplicateMapping";
  /**
   * MappingsDuplicateMapping from f3Connector.csv (in EN: Duplicate mapping )
   */
  public get MappingsDuplicateMapping(): string { return this.getStringDef(this.key_MappingsDuplicateMapping, "Duplicate mapping"); }

  /**
   * MappingsRemoveMapping from f3Connector.csv (in EN: Remove mapping )
   */
  public key_MappingsRemoveMapping = "MappingsRemoveMapping";
  /**
   * MappingsRemoveMapping from f3Connector.csv (in EN: Remove mapping )
   */
  public get MappingsRemoveMapping(): string { return this.getStringDef(this.key_MappingsRemoveMapping, "Remove mapping"); }

  /**
   * MappingsNewExogs from f3Connector.csv (in EN: New exogenous variables )
   */
  public key_MappingsNewExogs = "MappingsNewExogs";
  /**
   * MappingsNewExogs from f3Connector.csv (in EN: New exogenous variables )
   */
  public get MappingsNewExogs(): string { return this.getStringDef(this.key_MappingsNewExogs, "New exogenous variables"); }

  /**
   * MappingsNewCategories from f3Connector.csv (in EN: New reference category )
   */
  public key_MappingsNewCategories = "MappingsNewCategories";
  /**
   * MappingsNewCategories from f3Connector.csv (in EN: New reference category )
   */
  public get MappingsNewCategories(): string { return this.getStringDef(this.key_MappingsNewCategories, "New reference category"); }

  /**
   * MappingsNewCategoriesStillUsed from f3Connector.csv (in EN: New reference category is still used for {0} )
   */
  public key_MappingsNewCategoriesStillUsed = "MappingsNewCategoriesStillUsed";
  /**
   * MappingsNewCategoriesStillUsed from f3Connector.csv (in EN: New reference category is still used for {0} )
   */
  public MappingsNewCategoriesStillUsed(val1): string { return this.getStringDef("MappingsNewCategoriesStillUsed", "New reference category is still used for {0}").replace("{0}", val1); }

  /**
   * MappingsUpdateValueType from f3Connector.csv (in EN: Add )
   */
  public key_MappingsUpdateValueType = "MappingsUpdateValueType";
  /**
   * MappingsUpdateValueType from f3Connector.csv (in EN: Add )
   */
  public get MappingsUpdateValueType(): string { return this.getStringDef(this.key_MappingsUpdateValueType, "Add"); }

  /**
   * MappingsValidateMapping from f3Connector.csv (in EN: Validate Mapping )
   */
  public key_MappingsValidateMapping = "MappingsValidateMapping";
  /**
   * MappingsValidateMapping from f3Connector.csv (in EN: Validate Mapping )
   */
  public get MappingsValidateMapping(): string { return this.getStringDef(this.key_MappingsValidateMapping, "Validate Mapping"); }

  /**
   * MappingsValidationError from f3Connector.csv (in EN: Validation error )
   */
  public key_MappingsValidationError = "MappingsValidationError";
  /**
   * MappingsValidationError from f3Connector.csv (in EN: Validation error )
   */
  public get MappingsValidationError(): string { return this.getStringDef(this.key_MappingsValidationError, "Validation error"); }

  /**
   * MappingsErrorSynchronize from f3Connector.csv (in EN: Error loading components{0} )
   */
  public key_MappingsErrorSynchronize = "MappingsErrorSynchronize";
  /**
   * MappingsErrorSynchronize from f3Connector.csv (in EN: Error loading components{0} )
   */
  public MappingsErrorSynchronize(val1): string { return this.getStringDef("MappingsErrorSynchronize", "Error loading components{0}").replace("{0}", val1); }

  /**
   * MappingsComponentSource from f3Connector.csv (in EN: Component source:  )
   */
  public key_MappingsComponentSource = "MappingsComponentSource";
  /**
   * MappingsComponentSource from f3Connector.csv (in EN: Component source:  )
   */
  public get MappingsComponentSource(): string { return this.getStringDef(this.key_MappingsComponentSource, "Component source: "); }

  /**
   * MappingsComponentSourceDatabase from f3Connector.csv (in EN: Predefined in the database )
   */
  public key_MappingsComponentSourceDatabase = "MappingsComponentSourceDatabase";
  /**
   * MappingsComponentSourceDatabase from f3Connector.csv (in EN: Predefined in the database )
   */
  public get MappingsComponentSourceDatabase(): string { return this.getStringDef(this.key_MappingsComponentSourceDatabase, "Predefined in the database"); }

  /**
   * MappingsComponentSourceImportFile from f3Connector.csv (in EN: Import file )
   */
  public key_MappingsComponentSourceImportFile = "MappingsComponentSourceImportFile";
  /**
   * MappingsComponentSourceImportFile from f3Connector.csv (in EN: Import file )
   */
  public get MappingsComponentSourceImportFile(): string { return this.getStringDef(this.key_MappingsComponentSourceImportFile, "Import file"); }

  /**
   * MappingsComponentSourceDescrField from f3Connector.csv (in EN: Column containing description: )
   */
  public key_MappingsComponentSourceDescrField = "MappingsComponentSourceDescrField";
  /**
   * MappingsComponentSourceDescrField from f3Connector.csv (in EN: Column containing description: )
   */
  public get MappingsComponentSourceDescrField(): string { return this.getStringDef(this.key_MappingsComponentSourceDescrField, "Column containing description:"); }

  /**
   * MappingsComponentSourceDescrFieldNone from f3Connector.csv (in EN: {none} )
   */
  public key_MappingsComponentSourceDescrFieldNone = "MappingsComponentSourceDescrFieldNone";
  /**
   * MappingsComponentSourceDescrFieldNone from f3Connector.csv (in EN: {none} )
   */
  public get MappingsComponentSourceDescrFieldNone(): string { return this.getStringDef(this.key_MappingsComponentSourceDescrFieldNone, "<none>"); }

  /**
   * MappingsComponentSourceDescrDuplicate from f3Connector.csv (in EN: The file contains multiple descriptions for the same number. )
   */
  public key_MappingsComponentSourceDescrDuplicate = "MappingsComponentSourceDescrDuplicate";
  /**
   * MappingsComponentSourceDescrDuplicate from f3Connector.csv (in EN: The file contains multiple descriptions for the same number. )
   */
  public get MappingsComponentSourceDescrDuplicate(): string { return this.getStringDef(this.key_MappingsComponentSourceDescrDuplicate, "The file contains multiple descriptions for the same number."); }

  /**
   * MappingsRefresh from f3Connector.csv (in EN: Refresh components )
   */
  public key_MappingsRefresh = "MappingsRefresh";
  /**
   * MappingsRefresh from f3Connector.csv (in EN: Refresh components )
   */
  public get MappingsRefresh(): string { return this.getStringDef(this.key_MappingsRefresh, "Refresh components"); }

  /**
   * MappingsNoMappings from f3Connector.csv (in EN: Choose component source below and click on 'Refresh components'. )
   */
  public key_MappingsNoMappings = "MappingsNoMappings";
  /**
   * MappingsNoMappings from f3Connector.csv (in EN: Choose component source below and click on 'Refresh components'. )
   */
  public get MappingsNoMappings(): string { return this.getStringDef(this.key_MappingsNoMappings, "Choose component source below and click on 'Refresh components'."); }

  /**
   * TransformationEditorCaption from f3Connector.csv (in EN: Transformation )
   */
  public key_TransformationEditorCaption = "TransformationEditorCaption";
  /**
   * TransformationEditorCaption from f3Connector.csv (in EN: Transformation )
   */
  public get TransformationEditorCaption(): string { return this.getStringDef(this.key_TransformationEditorCaption, "Transformation"); }

  /**
   * TransformationEditorSelectDefault from f3Connector.csv (in EN: Default transformations )
   */
  public key_TransformationEditorSelectDefault = "TransformationEditorSelectDefault";
  /**
   * TransformationEditorSelectDefault from f3Connector.csv (in EN: Default transformations )
   */
  public get TransformationEditorSelectDefault(): string { return this.getStringDef(this.key_TransformationEditorSelectDefault, "Default transformations"); }

  /**
   * NewExogsCaption from f3Connector.csv (in EN: New exogenous variables )
   */
  public key_NewExogsCaption = "NewExogsCaption";
  /**
   * NewExogsCaption from f3Connector.csv (in EN: New exogenous variables )
   */
  public get NewExogsCaption(): string { return this.getStringDef(this.key_NewExogsCaption, "New exogenous variables"); }

  /**
   * NewExogsLongName from f3Connector.csv (in EN: Long name )
   */
  public key_NewExogsLongName = "NewExogsLongName";
  /**
   * NewExogsLongName from f3Connector.csv (in EN: Long name )
   */
  public get NewExogsLongName(): string { return this.getStringDef(this.key_NewExogsLongName, "Long name"); }

  /**
   * NewExogsShortName from f3Connector.csv (in EN: Short name )
   */
  public key_NewExogsShortName = "NewExogsShortName";
  /**
   * NewExogsShortName from f3Connector.csv (in EN: Short name )
   */
  public get NewExogsShortName(): string { return this.getStringDef(this.key_NewExogsShortName, "Short name"); }

  /**
   * NewExogsDataType from f3Connector.csv (in EN: Data type )
   */
  public key_NewExogsDataType = "NewExogsDataType";
  /**
   * NewExogsDataType from f3Connector.csv (in EN: Data type )
   */
  public get NewExogsDataType(): string { return this.getStringDef(this.key_NewExogsDataType, "Data type"); }

  /**
   * NewExogsInvalid from f3Connector.csv (in EN: New exogenous variables may not contain empty values. )
   */
  public key_NewExogsInvalid = "NewExogsInvalid";
  /**
   * NewExogsInvalid from f3Connector.csv (in EN: New exogenous variables may not contain empty values. )
   */
  public get NewExogsInvalid(): string { return this.getStringDef(this.key_NewExogsInvalid, "New exogenous variables may not contain empty values."); }

  /**
   * NewExogsNonUniqueMultiple from f3Connector.csv (in EN: The following exogenous variables have no unique short name:'{0}'. )
   */
  public key_NewExogsNonUniqueMultiple = "NewExogsNonUniqueMultiple";
  /**
   * NewExogsNonUniqueMultiple from f3Connector.csv (in EN: The following exogenous variables have no unique short name:'{0}'. )
   */
  public NewExogsNonUniqueMultiple(val1): string { return this.getStringDef("NewExogsNonUniqueMultiple", "The following exogenous variables have no unique short name:'{0}'.").replace("{0}", val1); }

  /**
   * NewExogsNonUnique from f3Connector.csv (in EN: The following exogenous variable has no unique short name:'{0}'. )
   */
  public key_NewExogsNonUnique = "NewExogsNonUnique";
  /**
   * NewExogsNonUnique from f3Connector.csv (in EN: The following exogenous variable has no unique short name:'{0}'. )
   */
  public NewExogsNonUnique(val1): string { return this.getStringDef("NewExogsNonUnique", "The following exogenous variable has no unique short name:'{0}'.").replace("{0}", val1); }

  /**
   * RunImportPressStart from f3Connector.csv (in EN: Press 'Import'. )
   */
  public key_RunImportPressStart = "RunImportPressStart";
  /**
   * RunImportPressStart from f3Connector.csv (in EN: Press 'Import'. )
   */
  public get RunImportPressStart(): string { return this.getStringDef(this.key_RunImportPressStart, "Press 'Import'."); }

  /**
   * RunImportNotTranslated from f3Connector.csv (in EN: Import step is not translated: {0} )
   */
  public key_RunImportNotTranslated = "RunImportNotTranslated";
  /**
   * RunImportNotTranslated from f3Connector.csv (in EN: Import step is not translated: {0} )
   */
  public RunImportNotTranslated(val1): string { return this.getStringDef("RunImportNotTranslated", "Import step is not translated: {0}").replace("{0}", val1); }

  /**
   * RunImportGlobalStart from f3Connector.csv (in EN: Start import ({0}) )
   */
  public key_RunImportGlobalStart = "RunImportGlobalStart";
  /**
   * RunImportGlobalStart from f3Connector.csv (in EN: Start import ({0}) )
   */
  public RunImportGlobalStart(val1): string { return this.getStringDef("RunImportGlobalStart", "Start import ({0})").replace("{0}", val1); }

  /**
   * RunImportGlobalFinish from f3Connector.csv (in EN: Import finished ({0}) )
   */
  public key_RunImportGlobalFinish = "RunImportGlobalFinish";
  /**
   * RunImportGlobalFinish from f3Connector.csv (in EN: Import finished ({0}) )
   */
  public RunImportGlobalFinish(val1): string { return this.getStringDef("RunImportGlobalFinish", "Import finished ({0})").replace("{0}", val1); }

  /**
   * RunImportGlobalFinishErrorReference from f3Connector.csv (in EN: Error has occured, for more information see messages above. )
   */
  public key_RunImportGlobalFinishErrorReference = "RunImportGlobalFinishErrorReference";
  /**
   * RunImportGlobalFinishErrorReference from f3Connector.csv (in EN: Error has occured, for more information see messages above. )
   */
  public get RunImportGlobalFinishErrorReference(): string { return this.getStringDef(this.key_RunImportGlobalFinishErrorReference, "Error has occured, for more information see messages above."); }

  /**
   * RunImportGlobalGroupStart from f3Connector.csv (in EN: ============================================================ )
   */
  public key_RunImportGlobalGroupStart = "RunImportGlobalGroupStart";
  /**
   * RunImportGlobalGroupStart from f3Connector.csv (in EN: ============================================================ )
   */
  public get RunImportGlobalGroupStart(): string { return this.getStringDef(this.key_RunImportGlobalGroupStart, "============================================================"); }

  /**
   * RunImportGlobalOpeningConnectionWithDatabase from f3Connector.csv (in EN: Connect with database '{0}'. )
   */
  public key_RunImportGlobalOpeningConnectionWithDatabase = "RunImportGlobalOpeningConnectionWithDatabase";
  /**
   * RunImportGlobalOpeningConnectionWithDatabase from f3Connector.csv (in EN: Connect with database '{0}'. )
   */
  public RunImportGlobalOpeningConnectionWithDatabase(val1): string { return this.getStringDef("RunImportGlobalOpeningConnectionWithDatabase", "Connect with database '{0}'.").replace("{0}", val1); }

  /**
   * RunImportGlobalExtendingReferentialCategories from f3Connector.csv (in EN: Extending referential categories: )
   */
  public key_RunImportGlobalExtendingReferentialCategories = "RunImportGlobalExtendingReferentialCategories";
  /**
   * RunImportGlobalExtendingReferentialCategories from f3Connector.csv (in EN: Extending referential categories: )
   */
  public get RunImportGlobalExtendingReferentialCategories(): string { return this.getStringDef(this.key_RunImportGlobalExtendingReferentialCategories, "Extending referential categories:"); }

  /**
   * RunImportGlobalDroppingTemporaryTable from f3Connector.csv (in EN: Remove temporary tables: {0} )
   */
  public key_RunImportGlobalDroppingTemporaryTable = "RunImportGlobalDroppingTemporaryTable";
  /**
   * RunImportGlobalDroppingTemporaryTable from f3Connector.csv (in EN: Remove temporary tables: {0} )
   */
  public RunImportGlobalDroppingTemporaryTable(val1): string { return this.getStringDef("RunImportGlobalDroppingTemporaryTable", "Remove temporary tables: {0}").replace("{0}", val1); }

  /**
   * RunImportGlobalDividingByPeriods from f3Connector.csv (in EN: Divide by number of periods )
   */
  public key_RunImportGlobalDividingByPeriods = "RunImportGlobalDividingByPeriods";
  /**
   * RunImportGlobalDividingByPeriods from f3Connector.csv (in EN: Divide by number of periods )
   */
  public get RunImportGlobalDividingByPeriods(): string { return this.getStringDef(this.key_RunImportGlobalDividingByPeriods, "Divide by number of periods"); }

  /**
   * RunImportReadingSourceStepsStart from f3Connector.csv (in EN: Reading '{0}'. )
   */
  public key_RunImportReadingSourceStepsStart = "RunImportReadingSourceStepsStart";
  /**
   * RunImportReadingSourceStepsStart from f3Connector.csv (in EN: Reading '{0}'. )
   */
  public RunImportReadingSourceStepsStart(val1): string { return this.getStringDef("RunImportReadingSourceStepsStart", "Reading '{0}'.").replace("{0}", val1); }

  /**
   * RunImportApendDataTransformationForDefintion from f3Connector.csv (in EN: Appending tranformations for {0}. )
   */
  public key_RunImportApendDataTransformationForDefintion = "RunImportApendDataTransformationForDefintion";
  /**
   * RunImportApendDataTransformationForDefintion from f3Connector.csv (in EN: Appending tranformations for {0}. )
   */
  public RunImportApendDataTransformationForDefintion(val1): string { return this.getStringDef("RunImportApendDataTransformationForDefintion", "Appending tranformations for {0}.").replace("{0}", val1); }

  /**
   * RunImportApendDataTransformationOf from f3Connector.csv (in EN: * Transformation for {0} to {1} using {2} is being applied. )
   */
  public key_RunImportApendDataTransformationOf = "RunImportApendDataTransformationOf";
  /**
   * RunImportApendDataTransformationOf from f3Connector.csv (in EN: * Transformation for {0} to {1} using {2} is being applied. )
   */
  public RunImportApendDataTransformationOf(val1, val2, val3): string { return this.getStringDef("RunImportApendDataTransformationOf", "* Transformation for {0} to {1} using {2} is being applied.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportReadingSourceStepsFinish from f3Connector.csv (in EN: Finished reading '{0}'. )
   */
  public key_RunImportReadingSourceStepsFinish = "RunImportReadingSourceStepsFinish";
  /**
   * RunImportReadingSourceStepsFinish from f3Connector.csv (in EN: Finished reading '{0}'. )
   */
  public RunImportReadingSourceStepsFinish(val1): string { return this.getStringDef("RunImportReadingSourceStepsFinish", "Finished reading '{0}'.").replace("{0}", val1); }

  /**
   * RunImportMoveStagingUsingFilter from f3Connector.csv (in EN: {0} is being move to staging table with filter {1} )
   */
  public key_RunImportMoveStagingUsingFilter = "RunImportMoveStagingUsingFilter";
  /**
   * RunImportMoveStagingUsingFilter from f3Connector.csv (in EN: {0} is being move to staging table with filter {1} )
   */
  public RunImportMoveStagingUsingFilter(val1, val2): string { return this.getStringDef("RunImportMoveStagingUsingFilter", "{0} is being move to staging table with filter {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportGlobalPreparingDataCopy from f3Connector.csv (in EN: Preparing for copying data to temporary targettabel . )
   */
  public key_RunImportGlobalPreparingDataCopy = "RunImportGlobalPreparingDataCopy";
  /**
   * RunImportGlobalPreparingDataCopy from f3Connector.csv (in EN: Preparing for copying data to temporary targettabel . )
   */
  public get RunImportGlobalPreparingDataCopy(): string { return this.getStringDef(this.key_RunImportGlobalPreparingDataCopy, "Preparing for copying data to temporary targettabel ."); }

  /**
   * RunImportReadingSourceStepsRecordsRetrieved from f3Connector.csv (in EN: --} {0} records retrieved. )
   */
  public key_RunImportReadingSourceStepsRecordsRetrieved = "RunImportReadingSourceStepsRecordsRetrieved";
  /**
   * RunImportReadingSourceStepsRecordsRetrieved from f3Connector.csv (in EN: --} {0} records retrieved. )
   */
  public RunImportReadingSourceStepsRecordsRetrieved(val1): string { return this.getStringDef("RunImportReadingSourceStepsRecordsRetrieved", "--> {0} records retrieved.").replace("{0}", val1); }

  /**
   * RunImportReadingSourceContentsIntoDatabaseStart from f3Connector.csv (in EN: Start reading data. )
   */
  public key_RunImportReadingSourceContentsIntoDatabaseStart = "RunImportReadingSourceContentsIntoDatabaseStart";
  /**
   * RunImportReadingSourceContentsIntoDatabaseStart from f3Connector.csv (in EN: Start reading data. )
   */
  public get RunImportReadingSourceContentsIntoDatabaseStart(): string { return this.getStringDef(this.key_RunImportReadingSourceContentsIntoDatabaseStart, "Start reading data."); }

  /**
   * RunImportReadingSourceContentsIntoDatabaseEnd from f3Connector.csv (in EN: --} {0} records retrieved. )
   */
  public key_RunImportReadingSourceContentsIntoDatabaseEnd = "RunImportReadingSourceContentsIntoDatabaseEnd";
  /**
   * RunImportReadingSourceContentsIntoDatabaseEnd from f3Connector.csv (in EN: --} {0} records retrieved. )
   */
  public RunImportReadingSourceContentsIntoDatabaseEnd(val1): string { return this.getStringDef("RunImportReadingSourceContentsIntoDatabaseEnd", "--> {0} records retrieved.").replace("{0}", val1); }

  /**
   * RunImportReadingSourceStepsRecordsStoredIn from f3Connector.csv (in EN: --} saved into {0}. )
   */
  public key_RunImportReadingSourceStepsRecordsStoredIn = "RunImportReadingSourceStepsRecordsStoredIn";
  /**
   * RunImportReadingSourceStepsRecordsStoredIn from f3Connector.csv (in EN: --} saved into {0}. )
   */
  public RunImportReadingSourceStepsRecordsStoredIn(val1): string { return this.getStringDef("RunImportReadingSourceStepsRecordsStoredIn", "--> saved into {0}.").replace("{0}", val1); }

  /**
   * RunImportReadingSourceStepsReadError from f3Connector.csv (in EN: --} No data can be retrieved from the source {0}! )
   */
  public key_RunImportReadingSourceStepsReadError = "RunImportReadingSourceStepsReadError";
  /**
   * RunImportReadingSourceStepsReadError from f3Connector.csv (in EN: --} No data can be retrieved from the source {0}! )
   */
  public RunImportReadingSourceStepsReadError(val1): string { return this.getStringDef("RunImportReadingSourceStepsReadError", "--> No data can be retrieved from the source {0}!").replace("{0}", val1); }

  /**
   * RunImportReadingSourceStepsValidation from f3Connector.csv (in EN: --} Validating contents of {0}. )
   */
  public key_RunImportReadingSourceStepsValidation = "RunImportReadingSourceStepsValidation";
  /**
   * RunImportReadingSourceStepsValidation from f3Connector.csv (in EN: --} Validating contents of {0}. )
   */
  public RunImportReadingSourceStepsValidation(val1): string { return this.getStringDef("RunImportReadingSourceStepsValidation", "--> Validating contents of {0}.").replace("{0}", val1); }

  /**
   * RunImportReadingSourceUsingFilter from f3Connector.csv (in EN: {0} is being read with filter {1} )
   */
  public key_RunImportReadingSourceUsingFilter = "RunImportReadingSourceUsingFilter";
  /**
   * RunImportReadingSourceUsingFilter from f3Connector.csv (in EN: {0} is being read with filter {1} )
   */
  public RunImportReadingSourceUsingFilter(val1, val2): string { return this.getStringDef("RunImportReadingSourceUsingFilter", "{0} is being read with filter {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportReadingSourceStepsReading from f3Connector.csv (in EN: --} Importing contents of {0}. )
   */
  public key_RunImportReadingSourceStepsReading = "RunImportReadingSourceStepsReading";
  /**
   * RunImportReadingSourceStepsReading from f3Connector.csv (in EN: --} Importing contents of {0}. )
   */
  public RunImportReadingSourceStepsReading(val1): string { return this.getStringDef("RunImportReadingSourceStepsReading", "--> Importing contents of {0}.").replace("{0}", val1); }

  /**
   * RunImportReferenceCategoryExtended from f3Connector.csv (in EN: Referencecategory '{0}' is added with {1} new reference(s). )
   */
  public key_RunImportReferenceCategoryExtended = "RunImportReferenceCategoryExtended";
  /**
   * RunImportReferenceCategoryExtended from f3Connector.csv (in EN: Referencecategory '{0}' is added with {1} new reference(s). )
   */
  public RunImportReferenceCategoryExtended(val1, val2): string { return this.getStringDef("RunImportReferenceCategoryExtended", "Referencecategory '{0}' is added with {1} new reference(s).").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportReferenceCategoryExtendedCompensation from f3Connector.csv (in EN: Referencecategory '{0}' is cleaned from {1} newly added reference(s). )
   */
  public key_RunImportReferenceCategoryExtendedCompensation = "RunImportReferenceCategoryExtendedCompensation";
  /**
   * RunImportReferenceCategoryExtendedCompensation from f3Connector.csv (in EN: Referencecategory '{0}' is cleaned from {1} newly added reference(s). )
   */
  public RunImportReferenceCategoryExtendedCompensation(val1, val2): string { return this.getStringDef("RunImportReferenceCategoryExtendedCompensation", "Referencecategory '{0}' is cleaned from {1} newly added reference(s).").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportReferenceCategoryExtendedWithTransformation from f3Connector.csv (in EN: ** via {0} )
   */
  public key_RunImportReferenceCategoryExtendedWithTransformation = "RunImportReferenceCategoryExtendedWithTransformation";
  /**
   * RunImportReferenceCategoryExtendedWithTransformation from f3Connector.csv (in EN: ** via {0} )
   */
  public RunImportReferenceCategoryExtendedWithTransformation(val1): string { return this.getStringDef("RunImportReferenceCategoryExtendedWithTransformation", "** via {0}").replace("{0}", val1); }

  /**
   * RunImportAddedDataSet from f3Connector.csv (in EN: Dataset added with short name '{0}'. )
   */
  public key_RunImportAddedDataSet = "RunImportAddedDataSet";
  /**
   * RunImportAddedDataSet from f3Connector.csv (in EN: Dataset added with short name '{0}'. )
   */
  public RunImportAddedDataSet(val1): string { return this.getStringDef("RunImportAddedDataSet", "Dataset added with short name '{0}'.").replace("{0}", val1); }

  /**
   * RunImportAddedExogs from f3Connector.csv (in EN: {0} exogenous variables created for dataset '{1}'. )
   */
  public key_RunImportAddedExogs = "RunImportAddedExogs";
  /**
   * RunImportAddedExogs from f3Connector.csv (in EN: {0} exogenous variables created for dataset '{1}'. )
   */
  public RunImportAddedExogs(val1, val2): string { return this.getStringDef("RunImportAddedExogs", "{0} exogenous variables created for dataset '{1}'.").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportExogenousDataTypeExpanded from f3Connector.csv (in EN: Exogenenous {0} data type has been extended from {1} to {2}. )
   */
  public key_RunImportExogenousDataTypeExpanded = "RunImportExogenousDataTypeExpanded";
  /**
   * RunImportExogenousDataTypeExpanded from f3Connector.csv (in EN: Exogenenous {0} data type has been extended from {1} to {2}. )
   */
  public RunImportExogenousDataTypeExpanded(val1, val2, val3): string { return this.getStringDef("RunImportExogenousDataTypeExpanded", "Exogenenous {0} data type has been extended from {1} to {2}.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportExogenousDataTypeExpandedAllowedOption from f3Connector.csv (in EN: Allow for extending exogenous data types )
   */
  public key_RunImportExogenousDataTypeExpandedAllowedOption = "RunImportExogenousDataTypeExpandedAllowedOption";
  /**
   * RunImportExogenousDataTypeExpandedAllowedOption from f3Connector.csv (in EN: Allow for extending exogenous data types )
   */
  public get RunImportExogenousDataTypeExpandedAllowedOption(): string { return this.getStringDef(this.key_RunImportExogenousDataTypeExpandedAllowedOption, "Allow for extending exogenous data types"); }

  /**
   * RunImportAllowSettingSystemFilledFields from f3Connector.csv (in EN: Allow supplying custom values for system supplied exogenous )
   */
  public key_RunImportAllowSettingSystemFilledFields = "RunImportAllowSettingSystemFilledFields";
  /**
   * RunImportAllowSettingSystemFilledFields from f3Connector.csv (in EN: Allow supplying custom values for system supplied exogenous )
   */
  public get RunImportAllowSettingSystemFilledFields(): string { return this.getStringDef(this.key_RunImportAllowSettingSystemFilledFields, "Allow supplying custom values for system supplied exogenous"); }

  /**
   * RunImportExogenousDataTypeAdjustAllowedOption from f3Connector.csv (in EN: Allow for adjusting exogenous data types (also to text) )
   */
  public key_RunImportExogenousDataTypeAdjustAllowedOption = "RunImportExogenousDataTypeAdjustAllowedOption";
  /**
   * RunImportExogenousDataTypeAdjustAllowedOption from f3Connector.csv (in EN: Allow for adjusting exogenous data types (also to text) )
   */
  public get RunImportExogenousDataTypeAdjustAllowedOption(): string { return this.getStringDef(this.key_RunImportExogenousDataTypeAdjustAllowedOption, "Allow for adjusting exogenous data types (also to text)"); }

  /**
   * RunImportExogenousDataTypeExpandedNotAllowed from f3Connector.csv (in EN: The import file contains values that are not compatible with the target column in the dataset because of improved validation in version 7.3. Contact Pointlogic HR to adjust the according data validation rule for version 7.3. Exogenenous {0} data is not allowed to be changed but requires a change from {1} to {2} (this can be enabled in the options). )
   */
  public key_RunImportExogenousDataTypeExpandedNotAllowed = "RunImportExogenousDataTypeExpandedNotAllowed";
  /**
   * RunImportExogenousDataTypeExpandedNotAllowed from f3Connector.csv (in EN: The import file contains values that are not compatible with the target column in the dataset because of improved validation in version 7.3. Contact Pointlogic HR to adjust the according data validation rule for version 7.3. Exogenenous {0} data is not allowed to be changed but requires a change from {1} to {2} (this can be enabled in the options). )
   */
  public RunImportExogenousDataTypeExpandedNotAllowed(val1, val2, val3): string { return this.getStringDef("RunImportExogenousDataTypeExpandedNotAllowed", "The import file contains values that are not compatible with the target column in the dataset because of improved validation in version 7.3. Contact Pointlogic HR to adjust the according data validation rule for version 7.3. Exogenenous {0} data is not allowed to be changed but requires a change from {1} to {2} (this can be enabled in the options).").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportExogenousDataTypeMisMatchButAllowed from f3Connector.csv (in EN: Exogeneous {0} has type {1} (reference type {2}) but the import definition has {3} (reference type {4} import file: {5}). )
   */
  public key_RunImportExogenousDataTypeMisMatchButAllowed = "RunImportExogenousDataTypeMisMatchButAllowed";
  /**
   * RunImportExogenousDataTypeMisMatchButAllowed from f3Connector.csv (in EN: Exogeneous {0} has type {1} (reference type {2}) but the import definition has {3} (reference type {4} import file: {5}). )
   */
  public RunImportExogenousDataTypeMisMatchButAllowed(values): string {
            let text = this.getStringDef("RunImportExogenousDataTypeMisMatchButAllowed", "Exogeneous {0} has type {1} (reference type {2}) but the import definition has {3} (reference type {4} import file: {5}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportExogenousDataTypeImportIsReferenceButExogNot from f3Connector.csv (in EN: Exogeneous {0} has type {1} but the import definition has {2} with reference type {3}. )
   */
  public key_RunImportExogenousDataTypeImportIsReferenceButExogNot = "RunImportExogenousDataTypeImportIsReferenceButExogNot";
  /**
   * RunImportExogenousDataTypeImportIsReferenceButExogNot from f3Connector.csv (in EN: Exogeneous {0} has type {1} but the import definition has {2} with reference type {3}. )
   */
  public RunImportExogenousDataTypeImportIsReferenceButExogNot(values): string {
            let text = this.getStringDef("RunImportExogenousDataTypeImportIsReferenceButExogNot", "Exogeneous {0} has type {1} but the import definition has {2} with reference type {3}.");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportValidatingSourceContents from f3Connector.csv (in EN: --} Validating contents. )
   */
  public key_RunImportValidatingSourceContents = "RunImportValidatingSourceContents";
  /**
   * RunImportValidatingSourceContents from f3Connector.csv (in EN: --} Validating contents. )
   */
  public get RunImportValidatingSourceContents(): string { return this.getStringDef(this.key_RunImportValidatingSourceContents, "--> Validating contents."); }

  /**
   * RunImportExogenousDataTypeUncompatible from f3Connector.csv (in EN: The import file contains values that are not compatible with the target column in the dataset because of improved validation in version 7.3. Contact Pointlogic HR to adjust the according data validation rule for version 7.3. Exogenenous {0} (type {1} can not be adjusted to {2} (these are not compatible). )
   */
  public key_RunImportExogenousDataTypeUncompatible = "RunImportExogenousDataTypeUncompatible";
  /**
   * RunImportExogenousDataTypeUncompatible from f3Connector.csv (in EN: The import file contains values that are not compatible with the target column in the dataset because of improved validation in version 7.3. Contact Pointlogic HR to adjust the according data validation rule for version 7.3. Exogenenous {0} (type {1} can not be adjusted to {2} (these are not compatible). )
   */
  public RunImportExogenousDataTypeUncompatible(val1, val2, val3): string { return this.getStringDef("RunImportExogenousDataTypeUncompatible", "The import file contains values that are not compatible with the target column in the dataset because of improved validation in version 7.3. Contact Pointlogic HR to adjust the according data validation rule for version 7.3. Exogenenous {0} (type {1} can not be adjusted to {2} (these are not compatible).").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportFieldIsNotCorrectPrefix from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid  )
   */
  public key_RunImportFieldIsNotCorrectPrefix = "RunImportFieldIsNotCorrectPrefix";
  /**
   * RunImportFieldIsNotCorrectPrefix from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid  )
   */
  public RunImportFieldIsNotCorrectPrefix(val1, val2, val3): string { return this.getStringDef("RunImportFieldIsNotCorrectPrefix", "--> The value {0} in column {1} in row {2} is not a valid ").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportFieldIsNotCorrectAffix from f3Connector.csv (in EN:  ({3}). )
   */
  public key_RunImportFieldIsNotCorrectAffix = "RunImportFieldIsNotCorrectAffix";
  /**
   * RunImportFieldIsNotCorrectAffix from f3Connector.csv (in EN:  ({3}). )
   */
  public RunImportFieldIsNotCorrectAffix(values): string {
            let text = this.getStringDef("RunImportFieldIsNotCorrectAffix", " ({3}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * ImportErrorDataSourceNotFullyConfigured from f3Connector.csv (in EN: The data source is not fully configured yet. )
   */
  public key_ImportErrorDataSourceNotFullyConfigured = "ImportErrorDataSourceNotFullyConfigured";
  /**
   * ImportErrorDataSourceNotFullyConfigured from f3Connector.csv (in EN: The data source is not fully configured yet. )
   */
  public get ImportErrorDataSourceNotFullyConfigured(): string { return this.getStringDef(this.key_ImportErrorDataSourceNotFullyConfigured, "The data source is not fully configured yet."); }

  /**
   * ImportErrorRowDataRequiresComponentField from f3Connector.csv (in EN: Row imports require a component field to be specified; it is missing for {0}. )
   */
  public key_ImportErrorRowDataRequiresComponentField = "ImportErrorRowDataRequiresComponentField";
  /**
   * ImportErrorRowDataRequiresComponentField from f3Connector.csv (in EN: Row imports require a component field to be specified; it is missing for {0}. )
   */
  public ImportErrorRowDataRequiresComponentField(val1): string { return this.getStringDef("ImportErrorRowDataRequiresComponentField", "Row imports require a component field to be specified; it is missing for {0}.").replace("{0}", val1); }

  /**
   * RunImportErrorFieldIsNotDateTime from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid date. ({3}). )
   */
  public key_RunImportErrorFieldIsNotDateTime = "RunImportErrorFieldIsNotDateTime";
  /**
   * RunImportErrorFieldIsNotDateTime from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid date. ({3}). )
   */
  public RunImportErrorFieldIsNotDateTime(values): string {
            let text = this.getStringDef("RunImportErrorFieldIsNotDateTime", "--> The value {0} in column {1} in row {2} is not a valid date. ({3}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportErrorFieldIsNotFloat from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid number. ({3}). )
   */
  public key_RunImportErrorFieldIsNotFloat = "RunImportErrorFieldIsNotFloat";
  /**
   * RunImportErrorFieldIsNotFloat from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid number. ({3}). )
   */
  public RunImportErrorFieldIsNotFloat(values): string {
            let text = this.getStringDef("RunImportErrorFieldIsNotFloat", "--> The value {0} in column {1} in row {2} is not a valid number. ({3}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportErrorFieldIsNotInteger from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid whole number. ({3}). )
   */
  public key_RunImportErrorFieldIsNotInteger = "RunImportErrorFieldIsNotInteger";
  /**
   * RunImportErrorFieldIsNotInteger from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid whole number. ({3}). )
   */
  public RunImportErrorFieldIsNotInteger(values): string {
            let text = this.getStringDef("RunImportErrorFieldIsNotInteger", "--> The value {0} in column {1} in row {2} is not a valid whole number. ({3}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportErrorFieldIsNotSmallInteger from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid small whole number (+/- 32767). ({3}). )
   */
  public key_RunImportErrorFieldIsNotSmallInteger = "RunImportErrorFieldIsNotSmallInteger";
  /**
   * RunImportErrorFieldIsNotSmallInteger from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid small whole number (+/- 32767). ({3}). )
   */
  public RunImportErrorFieldIsNotSmallInteger(values): string {
            let text = this.getStringDef("RunImportErrorFieldIsNotSmallInteger", "--> The value {0} in column {1} in row {2} is not a valid small whole number (+/- 32767). ({3}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportErrorFieldIsUsedMoreThanOnce from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid is used twice as a target column. ({3}). )
   */
  public key_RunImportErrorFieldIsUsedMoreThanOnce = "RunImportErrorFieldIsUsedMoreThanOnce";
  /**
   * RunImportErrorFieldIsUsedMoreThanOnce from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid is used twice as a target column. ({3}). )
   */
  public RunImportErrorFieldIsUsedMoreThanOnce(values): string {
            let text = this.getStringDef("RunImportErrorFieldIsUsedMoreThanOnce", "--> The value {0} in column {1} in row {2} is not a valid is used twice as a target column. ({3}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportErrorFieldNotDefined from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid the target column does not exist. ({3}). )
   */
  public key_RunImportErrorFieldNotDefined = "RunImportErrorFieldNotDefined";
  /**
   * RunImportErrorFieldNotDefined from f3Connector.csv (in EN: --} The value {0} in column {1} in row {2} is not a valid the target column does not exist. ({3}). )
   */
  public RunImportErrorFieldNotDefined(values): string {
            let text = this.getStringDef("RunImportErrorFieldNotDefined", "--> The value {0} in column {1} in row {2} is not a valid the target column does not exist. ({3}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportErrorWillNotBeChecked from f3Connector.csv (in EN: Column {0} of {1} will not be validated, it targets mutiple data types : {2} )
   */
  public key_RunImportErrorWillNotBeChecked = "RunImportErrorWillNotBeChecked";
  /**
   * RunImportErrorWillNotBeChecked from f3Connector.csv (in EN: Column {0} of {1} will not be validated, it targets mutiple data types : {2} )
   */
  public RunImportErrorWillNotBeChecked(val1, val2, val3): string { return this.getStringDef("RunImportErrorWillNotBeChecked", "Column {0} of {1} will not be validated, it targets mutiple data types : {2}").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportAdjustingDataForEmployment from f3Connector.csv (in EN: * Data of {0} will be adjusted based on period type. )
   */
  public key_RunImportAdjustingDataForEmployment = "RunImportAdjustingDataForEmployment";
  /**
   * RunImportAdjustingDataForEmployment from f3Connector.csv (in EN: * Data of {0} will be adjusted based on period type. )
   */
  public RunImportAdjustingDataForEmployment(val1): string { return this.getStringDef("RunImportAdjustingDataForEmployment", "* Data of {0} will be adjusted based on period type.").replace("{0}", val1); }

  /**
   * RunImportAdjustingdataForEmploymentEntry from f3Connector.csv (in EN: * Adjust {0} (-} {1}) to {2}. )
   */
  public key_RunImportAdjustingdataForEmploymentEntry = "RunImportAdjustingdataForEmploymentEntry";
  /**
   * RunImportAdjustingdataForEmploymentEntry from f3Connector.csv (in EN: * Adjust {0} (-} {1}) to {2}. )
   */
  public RunImportAdjustingdataForEmploymentEntry(val1, val2, val3): string { return this.getStringDef("RunImportAdjustingdataForEmploymentEntry", "* Adjust {0} (-> {1}) to {2}.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * ValidationErrorPrefix from f3Connector.csv (in EN: Field doesn't contain a valid )
   */
  public key_ValidationErrorPrefix = "ValidationErrorPrefix";
  /**
   * ValidationErrorPrefix from f3Connector.csv (in EN: Field doesn't contain a valid )
   */
  public get ValidationErrorPrefix(): string { return this.getStringDef(this.key_ValidationErrorPrefix, "Field doesn't contain a valid"); }

  /**
   * FieldIsNotDateTime from f3Connector.csv (in EN: Field doesn't contain a validdate )
   */
  public key_FieldIsNotDateTime = "FieldIsNotDateTime";
  /**
   * FieldIsNotDateTime from f3Connector.csv (in EN: Field doesn't contain a validdate )
   */
  public get FieldIsNotDateTime(): string { return this.getStringDef(this.key_FieldIsNotDateTime, "Field doesn't contain a validdate"); }

  /**
   * FieldIsNotFloat from f3Connector.csv (in EN: Field doesn't contain a validnumber )
   */
  public key_FieldIsNotFloat = "FieldIsNotFloat";
  /**
   * FieldIsNotFloat from f3Connector.csv (in EN: Field doesn't contain a validnumber )
   */
  public get FieldIsNotFloat(): string { return this.getStringDef(this.key_FieldIsNotFloat, "Field doesn't contain a validnumber"); }

  /**
   * FieldIsNotInteger from f3Connector.csv (in EN: Field doesn't contain a validwhole number )
   */
  public key_FieldIsNotInteger = "FieldIsNotInteger";
  /**
   * FieldIsNotInteger from f3Connector.csv (in EN: Field doesn't contain a validwhole number )
   */
  public get FieldIsNotInteger(): string { return this.getStringDef(this.key_FieldIsNotInteger, "Field doesn't contain a validwhole number"); }

  /**
   * FieldIsNotSmallInteger from f3Connector.csv (in EN: Field doesn't contain a validsmall whole number (+/- 32767) )
   */
  public key_FieldIsNotSmallInteger = "FieldIsNotSmallInteger";
  /**
   * FieldIsNotSmallInteger from f3Connector.csv (in EN: Field doesn't contain a validsmall whole number (+/- 32767) )
   */
  public get FieldIsNotSmallInteger(): string { return this.getStringDef(this.key_FieldIsNotSmallInteger, "Field doesn't contain a validsmall whole number (+/- 32767)"); }

  /**
   * RunImportReferenceTextsReplaceStart from f3Connector.csv (in EN: Replacing reference text with id's ({0}). )
   */
  public key_RunImportReferenceTextsReplaceStart = "RunImportReferenceTextsReplaceStart";
  /**
   * RunImportReferenceTextsReplaceStart from f3Connector.csv (in EN: Replacing reference text with id's ({0}). )
   */
  public RunImportReferenceTextsReplaceStart(val1): string { return this.getStringDef("RunImportReferenceTextsReplaceStart", "Replacing reference text with id's ({0}).").replace("{0}", val1); }

  /**
   * RunImportReferenceTextsReplaceFinish from f3Connector.csv (in EN: --} 'column contents of {0}' replaced by id's in category {1}. )
   */
  public key_RunImportReferenceTextsReplaceFinish = "RunImportReferenceTextsReplaceFinish";
  /**
   * RunImportReferenceTextsReplaceFinish from f3Connector.csv (in EN: --} 'column contents of {0}' replaced by id's in category {1}. )
   */
  public RunImportReferenceTextsReplaceFinish(val1, val2): string { return this.getStringDef("RunImportReferenceTextsReplaceFinish", "--> 'column contents of {0}' replaced by id's in category {1}.").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportDuplicatesRemoved from f3Connector.csv (in EN: Removed duplicate entries from {0}; import contained {1} leaving {2} )
   */
  public key_RunImportDuplicatesRemoved = "RunImportDuplicatesRemoved";
  /**
   * RunImportDuplicatesRemoved from f3Connector.csv (in EN: Removed duplicate entries from {0}; import contained {1} leaving {2} )
   */
  public RunImportDuplicatesRemoved(val1, val2, val3): string { return this.getStringDef("RunImportDuplicatesRemoved", "Removed duplicate entries from {0}; import contained {1} leaving {2}").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportMovingToDestinationTable from f3Connector.csv (in EN: --} from '{0}' ({1}) )
   */
  public key_RunImportMovingToDestinationTable = "RunImportMovingToDestinationTable";
  /**
   * RunImportMovingToDestinationTable from f3Connector.csv (in EN: --} from '{0}' ({1}) )
   */
  public RunImportMovingToDestinationTable(val1, val2): string { return this.getStringDef("RunImportMovingToDestinationTable", "--> from '{0}' ({1})").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportMovingToDestinationTableExogs from f3Connector.csv (in EN:     * copy to exogs )
   */
  public key_RunImportMovingToDestinationTableExogs = "RunImportMovingToDestinationTableExogs";
  /**
   * RunImportMovingToDestinationTableExogs from f3Connector.csv (in EN:     * copy to exogs )
   */
  public get RunImportMovingToDestinationTableExogs(): string { return this.getStringDef(this.key_RunImportMovingToDestinationTableExogs, "    * copy to exogs"); }

  /**
   * RunImportMovingToDestinationTableDetails from f3Connector.csv (in EN:     * column '{0}' to {1} )
   */
  public key_RunImportMovingToDestinationTableDetails = "RunImportMovingToDestinationTableDetails";
  /**
   * RunImportMovingToDestinationTableDetails from f3Connector.csv (in EN:     * column '{0}' to {1} )
   */
  public RunImportMovingToDestinationTableDetails(val1, val2): string { return this.getStringDef("RunImportMovingToDestinationTableDetails", "    * column '{0}' to {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportMovingToDestinationTableComponentDetails from f3Connector.csv (in EN:     * column '{0}' to {1} with value {2} for {3} row(s). )
   */
  public key_RunImportMovingToDestinationTableComponentDetails = "RunImportMovingToDestinationTableComponentDetails";
  /**
   * RunImportMovingToDestinationTableComponentDetails from f3Connector.csv (in EN:     * column '{0}' to {1} with value {2} for {3} row(s). )
   */
  public RunImportMovingToDestinationTableComponentDetails(values): string {
            let text = this.getStringDef("RunImportMovingToDestinationTableComponentDetails", "    * column '{0}' to {1} with value {2} for {3} row(s).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportMovingToDestinationTableNoExogsDefined from f3Connector.csv (in EN:    * no exogs mapped.  )
   */
  public key_RunImportMovingToDestinationTableNoExogsDefined = "RunImportMovingToDestinationTableNoExogsDefined";
  /**
   * RunImportMovingToDestinationTableNoExogsDefined from f3Connector.csv (in EN:    * no exogs mapped.  )
   */
  public get RunImportMovingToDestinationTableNoExogsDefined(): string { return this.getStringDef(this.key_RunImportMovingToDestinationTableNoExogsDefined, "   * no exogs mapped. "); }

  /**
   * RunImportDivideByPeriodsStart from f3Connector.csv (in EN: --} for data from  ({0}). )
   */
  public key_RunImportDivideByPeriodsStart = "RunImportDivideByPeriodsStart";
  /**
   * RunImportDivideByPeriodsStart from f3Connector.csv (in EN: --} for data from  ({0}). )
   */
  public RunImportDivideByPeriodsStart(val1): string { return this.getStringDef("RunImportDivideByPeriodsStart", "--> for data from  ({0}).").replace("{0}", val1); }

  /**
   * RunImportColumnSourcesDivideByYear from f3Connector.csv (in EN:     * divide by number of periods in a year: )
   */
  public key_RunImportColumnSourcesDivideByYear = "RunImportColumnSourcesDivideByYear";
  /**
   * RunImportColumnSourcesDivideByYear from f3Connector.csv (in EN:     * divide by number of periods in a year: )
   */
  public get RunImportColumnSourcesDivideByYear(): string { return this.getStringDef(this.key_RunImportColumnSourcesDivideByYear, "    * divide by number of periods in a year:"); }

  /**
   * RunImportColumnSourcesDivideByYieldToDate from f3Connector.csv (in EN:       * divide by number of periods until now: )
   */
  public key_RunImportColumnSourcesDivideByYieldToDate = "RunImportColumnSourcesDivideByYieldToDate";
  /**
   * RunImportColumnSourcesDivideByYieldToDate from f3Connector.csv (in EN:       * divide by number of periods until now: )
   */
  public get RunImportColumnSourcesDivideByYieldToDate(): string { return this.getStringDef(this.key_RunImportColumnSourcesDivideByYieldToDate, "      * divide by number of periods until now:"); }

  /**
   * RunImportRowSourcesDivideByYear from f3Connector.csv (in EN:         * divide by number of periods in a year) )
   */
  public key_RunImportRowSourcesDivideByYear = "RunImportRowSourcesDivideByYear";
  /**
   * RunImportRowSourcesDivideByYear from f3Connector.csv (in EN:         * divide by number of periods in a year) )
   */
  public get RunImportRowSourcesDivideByYear(): string { return this.getStringDef(this.key_RunImportRowSourcesDivideByYear, "        * divide by number of periods in a year)"); }

  /**
   * RunImportRowSourcesDivideByYieldToDate from f3Connector.csv (in EN:         * divide by number of periods until now) )
   */
  public key_RunImportRowSourcesDivideByYieldToDate = "RunImportRowSourcesDivideByYieldToDate";
  /**
   * RunImportRowSourcesDivideByYieldToDate from f3Connector.csv (in EN:         * divide by number of periods until now) )
   */
  public get RunImportRowSourcesDivideByYieldToDate(): string { return this.getStringDef(this.key_RunImportRowSourcesDivideByYieldToDate, "        * divide by number of periods until now)"); }

  /**
   * RunImportDetermineEmployments from f3Connector.csv (in EN: * determine employment {1} based on {0}. )
   */
  public key_RunImportDetermineEmployments = "RunImportDetermineEmployments";
  /**
   * RunImportDetermineEmployments from f3Connector.csv (in EN: * determine employment {1} based on {0}. )
   */
  public RunImportDetermineEmployments(val1, val2): string { return this.getStringDef("RunImportDetermineEmployments", "* determine employment {1} based on {0}.").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportDivideByPeriodsExogEmploymentStartDateNotConfigured from f3Connector.csv (in EN: --} Exog with employment start date is not specified!!! )
   */
  public key_RunImportDivideByPeriodsExogEmploymentStartDateNotConfigured = "RunImportDivideByPeriodsExogEmploymentStartDateNotConfigured";
  /**
   * RunImportDivideByPeriodsExogEmploymentStartDateNotConfigured from f3Connector.csv (in EN: --} Exog with employment start date is not specified!!! )
   */
  public get RunImportDivideByPeriodsExogEmploymentStartDateNotConfigured(): string { return this.getStringDef(this.key_RunImportDivideByPeriodsExogEmploymentStartDateNotConfigured, "--> Exog with employment start date is not specified!!!"); }

  /**
   * RunImportDivideByPeriodsExogEmploymentEndDateNotConfigured from f3Connector.csv (in EN: --} Exog with employment end date is not specified!!! )
   */
  public key_RunImportDivideByPeriodsExogEmploymentEndDateNotConfigured = "RunImportDivideByPeriodsExogEmploymentEndDateNotConfigured";
  /**
   * RunImportDivideByPeriodsExogEmploymentEndDateNotConfigured from f3Connector.csv (in EN: --} Exog with employment end date is not specified!!! )
   */
  public get RunImportDivideByPeriodsExogEmploymentEndDateNotConfigured(): string { return this.getStringDef(this.key_RunImportDivideByPeriodsExogEmploymentEndDateNotConfigured, "--> Exog with employment end date is not specified!!!"); }

  /**
   * RunImportDivideByPeriodsDividingExogName from f3Connector.csv (in EN:        * {0} )
   */
  public key_RunImportDivideByPeriodsDividingExogName = "RunImportDivideByPeriodsDividingExogName";
  /**
   * RunImportDivideByPeriodsDividingExogName from f3Connector.csv (in EN:        * {0} )
   */
  public RunImportDivideByPeriodsDividingExogName(val1): string { return this.getStringDef("RunImportDivideByPeriodsDividingExogName", "       * {0}").replace("{0}", val1); }

  /**
   * RunImportDivideByPeriodsUseThisYearFirstDate from f3Connector.csv (in EN: We will use the first day of this year, since no explicit date was provided as start date. )
   */
  public key_RunImportDivideByPeriodsUseThisYearFirstDate = "RunImportDivideByPeriodsUseThisYearFirstDate";
  /**
   * RunImportDivideByPeriodsUseThisYearFirstDate from f3Connector.csv (in EN: We will use the first day of this year, since no explicit date was provided as start date. )
   */
  public get RunImportDivideByPeriodsUseThisYearFirstDate(): string { return this.getStringDef(this.key_RunImportDivideByPeriodsUseThisYearFirstDate, "We will use the first day of this year, since no explicit date was provided as start date."); }

  /**
   * RunImportDivideByPeriodsUseTodayAsYieldToDate from f3Connector.csv (in EN: We will use today as cumulation date, since no explicit date was provided as cumulation date. )
   */
  public key_RunImportDivideByPeriodsUseTodayAsYieldToDate = "RunImportDivideByPeriodsUseTodayAsYieldToDate";
  /**
   * RunImportDivideByPeriodsUseTodayAsYieldToDate from f3Connector.csv (in EN: We will use today as cumulation date, since no explicit date was provided as cumulation date. )
   */
  public get RunImportDivideByPeriodsUseTodayAsYieldToDate(): string { return this.getStringDef(this.key_RunImportDivideByPeriodsUseTodayAsYieldToDate, "We will use today as cumulation date, since no explicit date was provided as cumulation date."); }

  /**
   * RunImportDivideByPeriodPeriodsInUse from f3Connector.csv (in EN: We will use {0} as first period, {1} as cumulation period and {2} as last period. )
   */
  public key_RunImportDivideByPeriodPeriodsInUse = "RunImportDivideByPeriodPeriodsInUse";
  /**
   * RunImportDivideByPeriodPeriodsInUse from f3Connector.csv (in EN: We will use {0} as first period, {1} as cumulation period and {2} as last period. )
   */
  public RunImportDivideByPeriodPeriodsInUse(val1, val2, val3): string { return this.getStringDef("RunImportDivideByPeriodPeriodsInUse", "We will use {0} as first period, {1} as cumulation period and {2} as last period.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportDivideByPeriodPeriodsInUseInvalid from f3Connector.csv (in EN: The combination of dates is invalid; {0} as first date, {1} as cumulation date and {2} as last date. )
   */
  public key_RunImportDivideByPeriodPeriodsInUseInvalid = "RunImportDivideByPeriodPeriodsInUseInvalid";
  /**
   * RunImportDivideByPeriodPeriodsInUseInvalid from f3Connector.csv (in EN: The combination of dates is invalid; {0} as first date, {1} as cumulation date and {2} as last date. )
   */
  public RunImportDivideByPeriodPeriodsInUseInvalid(val1, val2, val3): string { return this.getStringDef("RunImportDivideByPeriodPeriodsInUseInvalid", "The combination of dates is invalid; {0} as first date, {1} as cumulation date and {2} as last date.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportFinalCopyStart from f3Connector.csv (in EN: Copy data from {0} to {1} )
   */
  public key_RunImportFinalCopyStart = "RunImportFinalCopyStart";
  /**
   * RunImportFinalCopyStart from f3Connector.csv (in EN: Copy data from {0} to {1} )
   */
  public RunImportFinalCopyStart(val1, val2): string { return this.getStringDef("RunImportFinalCopyStart", "Copy data from {0} to {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportFinalCopyFinish from f3Connector.csv (in EN: --} {0} rows copied )
   */
  public key_RunImportFinalCopyFinish = "RunImportFinalCopyFinish";
  /**
   * RunImportFinalCopyFinish from f3Connector.csv (in EN: --} {0} rows copied )
   */
  public RunImportFinalCopyFinish(val1): string { return this.getStringDef("RunImportFinalCopyFinish", "--> {0} rows copied").replace("{0}", val1); }

  /**
   * RunImportCheckKeyUnicityDuplicatesFound from f3Connector.csv (in EN: Dataset '{0}' contains for key values '{1}' duplicates values )
   */
  public key_RunImportCheckKeyUnicityDuplicatesFound = "RunImportCheckKeyUnicityDuplicatesFound";
  /**
   * RunImportCheckKeyUnicityDuplicatesFound from f3Connector.csv (in EN: Dataset '{0}' contains for key values '{1}' duplicates values )
   */
  public RunImportCheckKeyUnicityDuplicatesFound(val1, val2): string { return this.getStringDef("RunImportCheckKeyUnicityDuplicatesFound", "Dataset '{0}' contains for key values '{1}' duplicates values").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportCheckKeyUnicityDuplicate from f3Connector.csv (in EN: {0} exists {1} time(s). )
   */
  public key_RunImportCheckKeyUnicityDuplicate = "RunImportCheckKeyUnicityDuplicate";
  /**
   * RunImportCheckKeyUnicityDuplicate from f3Connector.csv (in EN: {0} exists {1} time(s). )
   */
  public RunImportCheckKeyUnicityDuplicate(val1, val2): string { return this.getStringDef("RunImportCheckKeyUnicityDuplicate", "{0} exists {1} time(s).").replace("{1}", val2).replace("{0}", val1); }

  /**
   * RunImportScriptStart from f3Connector.csv (in EN: Start )
   */
  public key_RunImportScriptStart = "RunImportScriptStart";
  /**
   * RunImportScriptStart from f3Connector.csv (in EN: Start )
   */
  public get RunImportScriptStart(): string { return this.getStringDef(this.key_RunImportScriptStart, "Start"); }

  /**
   * RunImportScriptUnknown from f3Connector.csv (in EN: Unknown script moment )
   */
  public key_RunImportScriptUnknown = "RunImportScriptUnknown";
  /**
   * RunImportScriptUnknown from f3Connector.csv (in EN: Unknown script moment )
   */
  public get RunImportScriptUnknown(): string { return this.getStringDef(this.key_RunImportScriptUnknown, "Unknown script moment"); }

  /**
   * RunImportScriptExternalAllSourcesToStagingBefore from f3Connector.csv (in EN: Moving all source files to staging (before) )
   */
  public key_RunImportScriptExternalAllSourcesToStagingBefore = "RunImportScriptExternalAllSourcesToStagingBefore";
  /**
   * RunImportScriptExternalAllSourcesToStagingBefore from f3Connector.csv (in EN: Moving all source files to staging (before) )
   */
  public get RunImportScriptExternalAllSourcesToStagingBefore(): string { return this.getStringDef(this.key_RunImportScriptExternalAllSourcesToStagingBefore, "Moving all source files to staging (before)"); }

  /**
   * RunImportScriptExternalAllSourcesToStagingAfter from f3Connector.csv (in EN: Moving all source files to staging (after) )
   */
  public key_RunImportScriptExternalAllSourcesToStagingAfter = "RunImportScriptExternalAllSourcesToStagingAfter";
  /**
   * RunImportScriptExternalAllSourcesToStagingAfter from f3Connector.csv (in EN: Moving all source files to staging (after) )
   */
  public get RunImportScriptExternalAllSourcesToStagingAfter(): string { return this.getStringDef(this.key_RunImportScriptExternalAllSourcesToStagingAfter, "Moving all source files to staging (after)"); }

  /**
   * RunImportScriptExternalSourceToStagingBefore from f3Connector.csv (in EN: Before moving a single file to staging (before) )
   */
  public key_RunImportScriptExternalSourceToStagingBefore = "RunImportScriptExternalSourceToStagingBefore";
  /**
   * RunImportScriptExternalSourceToStagingBefore from f3Connector.csv (in EN: Before moving a single file to staging (before) )
   */
  public get RunImportScriptExternalSourceToStagingBefore(): string { return this.getStringDef(this.key_RunImportScriptExternalSourceToStagingBefore, "Before moving a single file to staging (before)"); }

  /**
   * RunImportScriptExternalSourceToStagingAfter from f3Connector.csv (in EN: After moving a single file to staging (after) )
   */
  public key_RunImportScriptExternalSourceToStagingAfter = "RunImportScriptExternalSourceToStagingAfter";
  /**
   * RunImportScriptExternalSourceToStagingAfter from f3Connector.csv (in EN: After moving a single file to staging (after) )
   */
  public get RunImportScriptExternalSourceToStagingAfter(): string { return this.getStringDef(this.key_RunImportScriptExternalSourceToStagingAfter, "After moving a single file to staging (after)"); }

  /**
   * RunImportScriptCreatingTemporaryDestinationTableBefore from f3Connector.csv (in EN: Creating temporary destination table (before) )
   */
  public key_RunImportScriptCreatingTemporaryDestinationTableBefore = "RunImportScriptCreatingTemporaryDestinationTableBefore";
  /**
   * RunImportScriptCreatingTemporaryDestinationTableBefore from f3Connector.csv (in EN: Creating temporary destination table (before) )
   */
  public get RunImportScriptCreatingTemporaryDestinationTableBefore(): string { return this.getStringDef(this.key_RunImportScriptCreatingTemporaryDestinationTableBefore, "Creating temporary destination table (before)"); }

  /**
   * RunImportScriptCreatingTemporaryDestinationTableAfter from f3Connector.csv (in EN: Creating temporary destination table (after) )
   */
  public key_RunImportScriptCreatingTemporaryDestinationTableAfter = "RunImportScriptCreatingTemporaryDestinationTableAfter";
  /**
   * RunImportScriptCreatingTemporaryDestinationTableAfter from f3Connector.csv (in EN: Creating temporary destination table (after) )
   */
  public get RunImportScriptCreatingTemporaryDestinationTableAfter(): string { return this.getStringDef(this.key_RunImportScriptCreatingTemporaryDestinationTableAfter, "Creating temporary destination table (after)"); }

  /**
   * RunImportScriptExternalAllSourcesImportingBefore from f3Connector.csv (in EN: Import external files (before) )
   */
  public key_RunImportScriptExternalAllSourcesImportingBefore = "RunImportScriptExternalAllSourcesImportingBefore";
  /**
   * RunImportScriptExternalAllSourcesImportingBefore from f3Connector.csv (in EN: Import external files (before) )
   */
  public get RunImportScriptExternalAllSourcesImportingBefore(): string { return this.getStringDef(this.key_RunImportScriptExternalAllSourcesImportingBefore, "Import external files (before)"); }

  /**
   * RunImportScriptExternalSourceImportingBefore from f3Connector.csv (in EN: - Importing external file (before) )
   */
  public key_RunImportScriptExternalSourceImportingBefore = "RunImportScriptExternalSourceImportingBefore";
  /**
   * RunImportScriptExternalSourceImportingBefore from f3Connector.csv (in EN: - Importing external file (before) )
   */
  public get RunImportScriptExternalSourceImportingBefore(): string { return this.getStringDef(this.key_RunImportScriptExternalSourceImportingBefore, "- Importing external file (before)"); }

  /**
   * RunImportScriptExternalRowDataImportingBefore from f3Connector.csv (in EN: -- Importing row data from external file (before) )
   */
  public key_RunImportScriptExternalRowDataImportingBefore = "RunImportScriptExternalRowDataImportingBefore";
  /**
   * RunImportScriptExternalRowDataImportingBefore from f3Connector.csv (in EN: -- Importing row data from external file (before) )
   */
  public get RunImportScriptExternalRowDataImportingBefore(): string { return this.getStringDef(this.key_RunImportScriptExternalRowDataImportingBefore, "-- Importing row data from external file (before)"); }

  /**
   * RunImportScriptExternalRowDataImportingAfter from f3Connector.csv (in EN: -- Importing row data from external file (after) )
   */
  public key_RunImportScriptExternalRowDataImportingAfter = "RunImportScriptExternalRowDataImportingAfter";
  /**
   * RunImportScriptExternalRowDataImportingAfter from f3Connector.csv (in EN: -- Importing row data from external file (after) )
   */
  public get RunImportScriptExternalRowDataImportingAfter(): string { return this.getStringDef(this.key_RunImportScriptExternalRowDataImportingAfter, "-- Importing row data from external file (after)"); }

  /**
   * RunImportScriptExternalSourceImportingAfter from f3Connector.csv (in EN: - Importing external file (after) )
   */
  public key_RunImportScriptExternalSourceImportingAfter = "RunImportScriptExternalSourceImportingAfter";
  /**
   * RunImportScriptExternalSourceImportingAfter from f3Connector.csv (in EN: - Importing external file (after) )
   */
  public get RunImportScriptExternalSourceImportingAfter(): string { return this.getStringDef(this.key_RunImportScriptExternalSourceImportingAfter, "- Importing external file (after)"); }

  /**
   * RunImportScriptExternalAllSourcesImportingAfter from f3Connector.csv (in EN: Import external files (after) )
   */
  public key_RunImportScriptExternalAllSourcesImportingAfter = "RunImportScriptExternalAllSourcesImportingAfter";
  /**
   * RunImportScriptExternalAllSourcesImportingAfter from f3Connector.csv (in EN: Import external files (after) )
   */
  public get RunImportScriptExternalAllSourcesImportingAfter(): string { return this.getStringDef(this.key_RunImportScriptExternalAllSourcesImportingAfter, "Import external files (after)"); }

  /**
   * RunImportScriptExternalAllSourcesApplyTransformationsBefore from f3Connector.csv (in EN: Applying transformations to all files (before) )
   */
  public key_RunImportScriptExternalAllSourcesApplyTransformationsBefore = "RunImportScriptExternalAllSourcesApplyTransformationsBefore";
  /**
   * RunImportScriptExternalAllSourcesApplyTransformationsBefore from f3Connector.csv (in EN: Applying transformations to all files (before) )
   */
  public get RunImportScriptExternalAllSourcesApplyTransformationsBefore(): string { return this.getStringDef(this.key_RunImportScriptExternalAllSourcesApplyTransformationsBefore, "Applying transformations to all files (before)"); }

  /**
   * RunImportScriptExternalSourceToApplyTransformationsBefore from f3Connector.csv (in EN: Applying transformations to a single file (before) )
   */
  public key_RunImportScriptExternalSourceToApplyTransformationsBefore = "RunImportScriptExternalSourceToApplyTransformationsBefore";
  /**
   * RunImportScriptExternalSourceToApplyTransformationsBefore from f3Connector.csv (in EN: Applying transformations to a single file (before) )
   */
  public get RunImportScriptExternalSourceToApplyTransformationsBefore(): string { return this.getStringDef(this.key_RunImportScriptExternalSourceToApplyTransformationsBefore, "Applying transformations to a single file (before)"); }

  /**
   * RunImportScriptExternalSourceToApplyTransformationsAfter from f3Connector.csv (in EN: Applying transformations to a single file (after) )
   */
  public key_RunImportScriptExternalSourceToApplyTransformationsAfter = "RunImportScriptExternalSourceToApplyTransformationsAfter";
  /**
   * RunImportScriptExternalSourceToApplyTransformationsAfter from f3Connector.csv (in EN: Applying transformations to a single file (after) )
   */
  public get RunImportScriptExternalSourceToApplyTransformationsAfter(): string { return this.getStringDef(this.key_RunImportScriptExternalSourceToApplyTransformationsAfter, "Applying transformations to a single file (after)"); }

  /**
   * RunImportScriptExternalAllSourcesApplyTransformationsAfter from f3Connector.csv (in EN: Applying transformations to all files (after) )
   */
  public key_RunImportScriptExternalAllSourcesApplyTransformationsAfter = "RunImportScriptExternalAllSourcesApplyTransformationsAfter";
  /**
   * RunImportScriptExternalAllSourcesApplyTransformationsAfter from f3Connector.csv (in EN: Applying transformations to all files (after) )
   */
  public get RunImportScriptExternalAllSourcesApplyTransformationsAfter(): string { return this.getStringDef(this.key_RunImportScriptExternalAllSourcesApplyTransformationsAfter, "Applying transformations to all files (after)"); }

  /**
   * RunImportScriptExtendingReferentialCategoriesBefore from f3Connector.csv (in EN: Extending referential categories (before) )
   */
  public key_RunImportScriptExtendingReferentialCategoriesBefore = "RunImportScriptExtendingReferentialCategoriesBefore";
  /**
   * RunImportScriptExtendingReferentialCategoriesBefore from f3Connector.csv (in EN: Extending referential categories (before) )
   */
  public get RunImportScriptExtendingReferentialCategoriesBefore(): string { return this.getStringDef(this.key_RunImportScriptExtendingReferentialCategoriesBefore, "Extending referential categories (before)"); }

  /**
   * RunImportScriptExtendingReferentialCategoriesAfter from f3Connector.csv (in EN: Extending referential categories (after) )
   */
  public key_RunImportScriptExtendingReferentialCategoriesAfter = "RunImportScriptExtendingReferentialCategoriesAfter";
  /**
   * RunImportScriptExtendingReferentialCategoriesAfter from f3Connector.csv (in EN: Extending referential categories (after) )
   */
  public get RunImportScriptExtendingReferentialCategoriesAfter(): string { return this.getStringDef(this.key_RunImportScriptExtendingReferentialCategoriesAfter, "Extending referential categories (after)"); }

  /**
   * RunImportScriptFillingTemporaryDestinationTableBefore from f3Connector.csv (in EN: Filling temporary destination table (before) )
   */
  public key_RunImportScriptFillingTemporaryDestinationTableBefore = "RunImportScriptFillingTemporaryDestinationTableBefore";
  /**
   * RunImportScriptFillingTemporaryDestinationTableBefore from f3Connector.csv (in EN: Filling temporary destination table (before) )
   */
  public get RunImportScriptFillingTemporaryDestinationTableBefore(): string { return this.getStringDef(this.key_RunImportScriptFillingTemporaryDestinationTableBefore, "Filling temporary destination table (before)"); }

  /**
   * RunImportScriptMovingDataIntoTemporaryDestinationTableBefore from f3Connector.csv (in EN: - Copying data from import table to temporary destination table (before) )
   */
  public key_RunImportScriptMovingDataIntoTemporaryDestinationTableBefore = "RunImportScriptMovingDataIntoTemporaryDestinationTableBefore";
  /**
   * RunImportScriptMovingDataIntoTemporaryDestinationTableBefore from f3Connector.csv (in EN: - Copying data from import table to temporary destination table (before) )
   */
  public get RunImportScriptMovingDataIntoTemporaryDestinationTableBefore(): string { return this.getStringDef(this.key_RunImportScriptMovingDataIntoTemporaryDestinationTableBefore, "- Copying data from import table to temporary destination table (before)"); }

  /**
   * RunImportScriptMovingDataIntoTemporaryDestinationTableAfter from f3Connector.csv (in EN: - Copying data from import table to temporary destination table (after) )
   */
  public key_RunImportScriptMovingDataIntoTemporaryDestinationTableAfter = "RunImportScriptMovingDataIntoTemporaryDestinationTableAfter";
  /**
   * RunImportScriptMovingDataIntoTemporaryDestinationTableAfter from f3Connector.csv (in EN: - Copying data from import table to temporary destination table (after) )
   */
  public get RunImportScriptMovingDataIntoTemporaryDestinationTableAfter(): string { return this.getStringDef(this.key_RunImportScriptMovingDataIntoTemporaryDestinationTableAfter, "- Copying data from import table to temporary destination table (after)"); }

  /**
   * RunImportScriptFillingTemporaryDestinationTableAfter from f3Connector.csv (in EN: Filling temporary destination table (after) )
   */
  public key_RunImportScriptFillingTemporaryDestinationTableAfter = "RunImportScriptFillingTemporaryDestinationTableAfter";
  /**
   * RunImportScriptFillingTemporaryDestinationTableAfter from f3Connector.csv (in EN: Filling temporary destination table (after) )
   */
  public get RunImportScriptFillingTemporaryDestinationTableAfter(): string { return this.getStringDef(this.key_RunImportScriptFillingTemporaryDestinationTableAfter, "Filling temporary destination table (after)"); }

  /**
   * RunImportScriptMovingDataIntoFinalDestinationTableBefore from f3Connector.csv (in EN: Filling final table (before) )
   */
  public key_RunImportScriptMovingDataIntoFinalDestinationTableBefore = "RunImportScriptMovingDataIntoFinalDestinationTableBefore";
  /**
   * RunImportScriptMovingDataIntoFinalDestinationTableBefore from f3Connector.csv (in EN: Filling final table (before) )
   */
  public get RunImportScriptMovingDataIntoFinalDestinationTableBefore(): string { return this.getStringDef(this.key_RunImportScriptMovingDataIntoFinalDestinationTableBefore, "Filling final table (before)"); }

  /**
   * RunImportScriptMovingDataIntoFinalDestinationTableAfter from f3Connector.csv (in EN: Filling final table (after) )
   */
  public key_RunImportScriptMovingDataIntoFinalDestinationTableAfter = "RunImportScriptMovingDataIntoFinalDestinationTableAfter";
  /**
   * RunImportScriptMovingDataIntoFinalDestinationTableAfter from f3Connector.csv (in EN: Filling final table (after) )
   */
  public get RunImportScriptMovingDataIntoFinalDestinationTableAfter(): string { return this.getStringDef(this.key_RunImportScriptMovingDataIntoFinalDestinationTableAfter, "Filling final table (after)"); }

  /**
   * RunImportScriptDroppingTemporaryTablesBefore from f3Connector.csv (in EN: Removing temporary tables (before) )
   */
  public key_RunImportScriptDroppingTemporaryTablesBefore = "RunImportScriptDroppingTemporaryTablesBefore";
  /**
   * RunImportScriptDroppingTemporaryTablesBefore from f3Connector.csv (in EN: Removing temporary tables (before) )
   */
  public get RunImportScriptDroppingTemporaryTablesBefore(): string { return this.getStringDef(this.key_RunImportScriptDroppingTemporaryTablesBefore, "Removing temporary tables (before)"); }

  /**
   * RunImportScriptDroppingTemporaryTablesAfter from f3Connector.csv (in EN: Removing temporary tables (after) )
   */
  public key_RunImportScriptDroppingTemporaryTablesAfter = "RunImportScriptDroppingTemporaryTablesAfter";
  /**
   * RunImportScriptDroppingTemporaryTablesAfter from f3Connector.csv (in EN: Removing temporary tables (after) )
   */
  public get RunImportScriptDroppingTemporaryTablesAfter(): string { return this.getStringDef(this.key_RunImportScriptDroppingTemporaryTablesAfter, "Removing temporary tables (after)"); }

  /**
   * RunImportScriptFinish from f3Connector.csv (in EN: Finish )
   */
  public key_RunImportScriptFinish = "RunImportScriptFinish";
  /**
   * RunImportScriptFinish from f3Connector.csv (in EN: Finish )
   */
  public get RunImportScriptFinish(): string { return this.getStringDef(this.key_RunImportScriptFinish, "Finish"); }

  /**
   * RunImportScriptVariableSeparated from f3Connector.csv (in EN: , separated by a semicolon. )
   */
  public key_RunImportScriptVariableSeparated = "RunImportScriptVariableSeparated";
  /**
   * RunImportScriptVariableSeparated from f3Connector.csv (in EN: , separated by a semicolon. )
   */
  public get RunImportScriptVariableSeparated(): string { return this.getStringDef(this.key_RunImportScriptVariableSeparated, ", separated by a semicolon."); }

  /**
   * RunImportScriptVariableSourceName from f3Connector.csv (in EN: {0}: the name of the external source file, for instance the name of an Excel file. )
   */
  public key_RunImportScriptVariableSourceName = "RunImportScriptVariableSourceName";
  /**
   * RunImportScriptVariableSourceName from f3Connector.csv (in EN: {0}: the name of the external source file, for instance the name of an Excel file. )
   */
  public RunImportScriptVariableSourceName(val1): string { return this.getStringDef("RunImportScriptVariableSourceName", "{0}: the name of the external source file, for instance the name of an Excel file.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableSourceNames from f3Connector.csv (in EN: {0}: all names of the external source files,, separated by a semicolon. )
   */
  public key_RunImportScriptVariableSourceNames = "RunImportScriptVariableSourceNames";
  /**
   * RunImportScriptVariableSourceNames from f3Connector.csv (in EN: {0}: all names of the external source files,, separated by a semicolon. )
   */
  public RunImportScriptVariableSourceNames(val1): string { return this.getStringDef("RunImportScriptVariableSourceNames", "{0}: all names of the external source files,, separated by a semicolon.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableTemporarySourceTableName from f3Connector.csv (in EN: {0}: the name of the temporary table in which the content of the external source file is stored. )
   */
  public key_RunImportScriptVariableTemporarySourceTableName = "RunImportScriptVariableTemporarySourceTableName";
  /**
   * RunImportScriptVariableTemporarySourceTableName from f3Connector.csv (in EN: {0}: the name of the temporary table in which the content of the external source file is stored. )
   */
  public RunImportScriptVariableTemporarySourceTableName(val1): string { return this.getStringDef("RunImportScriptVariableTemporarySourceTableName", "{0}: the name of the temporary table in which the content of the external source file is stored.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableTemporarySourceTableNames from f3Connector.csv (in EN: {0}: the names of the temporary tables in which the content of the external source files are stored, separated by a semicolon. )
   */
  public key_RunImportScriptVariableTemporarySourceTableNames = "RunImportScriptVariableTemporarySourceTableNames";
  /**
   * RunImportScriptVariableTemporarySourceTableNames from f3Connector.csv (in EN: {0}: the names of the temporary tables in which the content of the external source files are stored, separated by a semicolon. )
   */
  public RunImportScriptVariableTemporarySourceTableNames(val1): string { return this.getStringDef("RunImportScriptVariableTemporarySourceTableNames", "{0}: the names of the temporary tables in which the content of the external source files are stored, separated by a semicolon.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableTemporarySourceKeyFieldNames from f3Connector.csv (in EN: {0}: the names of the key fields in the temporary tabel, separated by a semicolon. )
   */
  public key_RunImportScriptVariableTemporarySourceKeyFieldNames = "RunImportScriptVariableTemporarySourceKeyFieldNames";
  /**
   * RunImportScriptVariableTemporarySourceKeyFieldNames from f3Connector.csv (in EN: {0}: the names of the key fields in the temporary tabel, separated by a semicolon. )
   */
  public RunImportScriptVariableTemporarySourceKeyFieldNames(val1): string { return this.getStringDef("RunImportScriptVariableTemporarySourceKeyFieldNames", "{0}: the names of the key fields in the temporary tabel, separated by a semicolon.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableTemporarySourceKeyFieldValues from f3Connector.csv (in EN: {0}: the values of the key fields of the data that is added to the temporary table, separated by a semicolon. )
   */
  public key_RunImportScriptVariableTemporarySourceKeyFieldValues = "RunImportScriptVariableTemporarySourceKeyFieldValues";
  /**
   * RunImportScriptVariableTemporarySourceKeyFieldValues from f3Connector.csv (in EN: {0}: the values of the key fields of the data that is added to the temporary table, separated by a semicolon. )
   */
  public RunImportScriptVariableTemporarySourceKeyFieldValues(val1): string { return this.getStringDef("RunImportScriptVariableTemporarySourceKeyFieldValues", "{0}: the values of the key fields of the data that is added to the temporary table, separated by a semicolon.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableTemporaryDestinationTableName from f3Connector.csv (in EN: {0}: the name of the temporary destination table where all values are stored, after transformations but before dividing by number of periods. )
   */
  public key_RunImportScriptVariableTemporaryDestinationTableName = "RunImportScriptVariableTemporaryDestinationTableName";
  /**
   * RunImportScriptVariableTemporaryDestinationTableName from f3Connector.csv (in EN: {0}: the name of the temporary destination table where all values are stored, after transformations but before dividing by number of periods. )
   */
  public RunImportScriptVariableTemporaryDestinationTableName(val1): string { return this.getStringDef("RunImportScriptVariableTemporaryDestinationTableName", "{0}: the name of the temporary destination table where all values are stored, after transformations but before dividing by number of periods.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableDestinationKeyFieldNames from f3Connector.csv (in EN: {0}: the names of the key fields in the target tabel, separated by a semicolon. )
   */
  public key_RunImportScriptVariableDestinationKeyFieldNames = "RunImportScriptVariableDestinationKeyFieldNames";
  /**
   * RunImportScriptVariableDestinationKeyFieldNames from f3Connector.csv (in EN: {0}: the names of the key fields in the target tabel, separated by a semicolon. )
   */
  public RunImportScriptVariableDestinationKeyFieldNames(val1): string { return this.getStringDef("RunImportScriptVariableDestinationKeyFieldNames", "{0}: the names of the key fields in the target tabel, separated by a semicolon.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableDestinationKeyFieldValues from f3Connector.csv (in EN: {0}: the values of the key fields in the target tabel, separated by a semicolon. )
   */
  public key_RunImportScriptVariableDestinationKeyFieldValues = "RunImportScriptVariableDestinationKeyFieldValues";
  /**
   * RunImportScriptVariableDestinationKeyFieldValues from f3Connector.csv (in EN: {0}: the values of the key fields in the target tabel, separated by a semicolon. )
   */
  public RunImportScriptVariableDestinationKeyFieldValues(val1): string { return this.getStringDef("RunImportScriptVariableDestinationKeyFieldValues", "{0}: the values of the key fields in the target tabel, separated by a semicolon.").replace("{0}", val1); }

  /**
   * RunImportScriptVariableFinalDestinationTableName from f3Connector.csv (in EN: {0}: The name of the final destination table where all values are stored after. This is the dataset table. The values have been divided by number of periods. )
   */
  public key_RunImportScriptVariableFinalDestinationTableName = "RunImportScriptVariableFinalDestinationTableName";
  /**
   * RunImportScriptVariableFinalDestinationTableName from f3Connector.csv (in EN: {0}: The name of the final destination table where all values are stored after. This is the dataset table. The values have been divided by number of periods. )
   */
  public RunImportScriptVariableFinalDestinationTableName(val1): string { return this.getStringDef("RunImportScriptVariableFinalDestinationTableName", "{0}: The name of the final destination table where all values are stored after. This is the dataset table. The values have been divided by number of periods.").replace("{0}", val1); }

  /**
   * RunImportScriptSplitVariables from f3Connector.csv (in EN: In case a variable contains multiple values )
   */
  public key_RunImportScriptSplitVariables = "RunImportScriptSplitVariables";
  /**
   * RunImportScriptSplitVariables from f3Connector.csv (in EN: In case a variable contains multiple values )
   */
  public get RunImportScriptSplitVariables(): string { return this.getStringDef(this.key_RunImportScriptSplitVariables, "In case a variable contains multiple values"); }

  /**
   * RunImportScriptVariableImportTablePrefix from f3Connector.csv (in EN: {0}: the table prefix used to make temporary tables unique. See examples below. )
   */
  public key_RunImportScriptVariableImportTablePrefix = "RunImportScriptVariableImportTablePrefix";
  /**
   * RunImportScriptVariableImportTablePrefix from f3Connector.csv (in EN: {0}: the table prefix used to make temporary tables unique. See examples below. )
   */
  public RunImportScriptVariableImportTablePrefix(val1): string { return this.getStringDef("RunImportScriptVariableImportTablePrefix", "{0}: the table prefix used to make temporary tables unique. See examples below.").replace("{0}", val1); }

  /**
   * RunImportScriptExamples from f3Connector.csv (in EN: Examples: )
   */
  public key_RunImportScriptExamples = "RunImportScriptExamples";
  /**
   * RunImportScriptExamples from f3Connector.csv (in EN: Examples: )
   */
  public get RunImportScriptExamples(): string { return this.getStringDef(this.key_RunImportScriptExamples, "Examples:"); }

  /**
   * RunImportScriptExamples1 from f3Connector.csv (in EN: exec('select * from ' + @ImportTablePrefix + 'ColumnBased_1') )
   */
  public key_RunImportScriptExamples1 = "RunImportScriptExamples1";
  /**
   * RunImportScriptExamples1 from f3Connector.csv (in EN: exec('select * from ' + @ImportTablePrefix + 'ColumnBased_1') )
   */
  public get RunImportScriptExamples1(): string { return this.getStringDef(this.key_RunImportScriptExamples1, "exec('select * from ' + @ImportTablePrefix + 'ColumnBased_1')"); }

  /**
   * RunImportScriptExamples2 from f3Connector.csv (in EN: exec('select * into MyTable from ' + @ImportTablePrefix + 'ColumnBased_1') )
   */
  public key_RunImportScriptExamples2 = "RunImportScriptExamples2";
  /**
   * RunImportScriptExamples2 from f3Connector.csv (in EN: exec('select * into MyTable from ' + @ImportTablePrefix + 'ColumnBased_1') )
   */
  public get RunImportScriptExamples2(): string { return this.getStringDef(this.key_RunImportScriptExamples2, "exec('select * into MyTable from ' + @ImportTablePrefix + 'ColumnBased_1')"); }

  /**
   * RunImportScriptExamples3 from f3Connector.csv (in EN: exec('delete from ' + @ImportTablePrefix + 'ColumnBased_1 where [Pers.Nr.] { 21000000225') )
   */
  public key_RunImportScriptExamples3 = "RunImportScriptExamples3";
  /**
   * RunImportScriptExamples3 from f3Connector.csv (in EN: exec('delete from ' + @ImportTablePrefix + 'ColumnBased_1 where [Pers.Nr.] { 21000000225') )
   */
  public get RunImportScriptExamples3(): string { return this.getStringDef(this.key_RunImportScriptExamples3, "exec('delete from ' + @ImportTablePrefix + 'ColumnBased_1 where [Pers.Nr.] < 21000000225')"); }

  /**
   * RunImportScriptSplitExamples from f3Connector.csv (in EN: Examples: )
   */
  public key_RunImportScriptSplitExamples = "RunImportScriptSplitExamples";
  /**
   * RunImportScriptSplitExamples from f3Connector.csv (in EN: Examples: )
   */
  public get RunImportScriptSplitExamples(): string { return this.getStringDef(this.key_RunImportScriptSplitExamples, "Examples:"); }

  /**
   * RunDataSetDefinitionKeyCountDoesNotMatch from f3Connector.csv (in EN: The number of key fields in  '{0}' does not match the number of key fields in  '{1}' ({2} versus {3}). )
   */
  public key_RunDataSetDefinitionKeyCountDoesNotMatch = "RunDataSetDefinitionKeyCountDoesNotMatch";
  /**
   * RunDataSetDefinitionKeyCountDoesNotMatch from f3Connector.csv (in EN: The number of key fields in  '{0}' does not match the number of key fields in  '{1}' ({2} versus {3}). )
   */
  public RunDataSetDefinitionKeyCountDoesNotMatch(values): string {
            let text = this.getStringDef("RunDataSetDefinitionKeyCountDoesNotMatch", "The number of key fields in  '{0}' does not match the number of key fields in  '{1}' ({2} versus {3}).");
            for (let i = 0; i < values.length; i++) {
                text = text.replace("{" + i + "}", values[i]);
            }
            return text;
        }

  /**
   * RunImportSourceFileWasNotFound from f3Connector.csv (in EN: File {0} was not found. )
   */
  public key_RunImportSourceFileWasNotFound = "RunImportSourceFileWasNotFound";
  /**
   * RunImportSourceFileWasNotFound from f3Connector.csv (in EN: File {0} was not found. )
   */
  public RunImportSourceFileWasNotFound(val1): string { return this.getStringDef("RunImportSourceFileWasNotFound", "File {0} was not found.").replace("{0}", val1); }

  /**
   * RunImportSourceProviderNotSet from f3Connector.csv (in EN: No dataprovider loaded for file {0}. )
   */
  public key_RunImportSourceProviderNotSet = "RunImportSourceProviderNotSet";
  /**
   * RunImportSourceProviderNotSet from f3Connector.csv (in EN: No dataprovider loaded for file {0}. )
   */
  public RunImportSourceProviderNotSet(val1): string { return this.getStringDef("RunImportSourceProviderNotSet", "No dataprovider loaded for file {0}.").replace("{0}", val1); }

  /**
   * ScriptFormCaption from f3Connector.csv (in EN: Scripts )
   */
  public key_ScriptFormCaption = "ScriptFormCaption";
  /**
   * ScriptFormCaption from f3Connector.csv (in EN: Scripts )
   */
  public get ScriptFormCaption(): string { return this.getStringDef(this.key_ScriptFormCaption, "Scripts"); }

  /**
   * ScriptFormHeaderAvailableScripts from f3Connector.csv (in EN: Available scripts: )
   */
  public key_ScriptFormHeaderAvailableScripts = "ScriptFormHeaderAvailableScripts";
  /**
   * ScriptFormHeaderAvailableScripts from f3Connector.csv (in EN: Available scripts: )
   */
  public get ScriptFormHeaderAvailableScripts(): string { return this.getStringDef(this.key_ScriptFormHeaderAvailableScripts, "Available scripts:"); }

  /**
   * ScriptFormHeaderScript from f3Connector.csv (in EN: Script: )
   */
  public key_ScriptFormHeaderScript = "ScriptFormHeaderScript";
  /**
   * ScriptFormHeaderScript from f3Connector.csv (in EN: Script: )
   */
  public get ScriptFormHeaderScript(): string { return this.getStringDef(this.key_ScriptFormHeaderScript, "Script:"); }

  /**
   * ScriptFormHeaderVariables from f3Connector.csv (in EN: Available variables (are assigned during import): )
   */
  public key_ScriptFormHeaderVariables = "ScriptFormHeaderVariables";
  /**
   * ScriptFormHeaderVariables from f3Connector.csv (in EN: Available variables (are assigned during import): )
   */
  public get ScriptFormHeaderVariables(): string { return this.getStringDef(this.key_ScriptFormHeaderVariables, "Available variables (are assigned during import):"); }

  /**
   * ScriptFormColumnMoment from f3Connector.csv (in EN: Moment )
   */
  public key_ScriptFormColumnMoment = "ScriptFormColumnMoment";
  /**
   * ScriptFormColumnMoment from f3Connector.csv (in EN: Moment )
   */
  public get ScriptFormColumnMoment(): string { return this.getStringDef(this.key_ScriptFormColumnMoment, "Moment"); }

  /**
   * ScriptFormColumnEnabled from f3Connector.csv (in EN: Enabled )
   */
  public key_ScriptFormColumnEnabled = "ScriptFormColumnEnabled";
  /**
   * ScriptFormColumnEnabled from f3Connector.csv (in EN: Enabled )
   */
  public get ScriptFormColumnEnabled(): string { return this.getStringDef(this.key_ScriptFormColumnEnabled, "Enabled"); }

  /**
   * ExogsTypeSelectionCaption from f3Connector.csv (in EN: Adjust exogenous types )
   */
  public key_ExogsTypeSelectionCaption = "ExogsTypeSelectionCaption";
  /**
   * ExogsTypeSelectionCaption from f3Connector.csv (in EN: Adjust exogenous types )
   */
  public get ExogsTypeSelectionCaption(): string { return this.getStringDef(this.key_ExogsTypeSelectionCaption, "Adjust exogenous types"); }

  /**
   * ExogTypeSelectionDateType from f3Connector.csv (in EN: Date )
   */
  public key_ExogTypeSelectionDateType = "ExogTypeSelectionDateType";
  /**
   * ExogTypeSelectionDateType from f3Connector.csv (in EN: Date )
   */
  public get ExogTypeSelectionDateType(): string { return this.getStringDef(this.key_ExogTypeSelectionDateType, "Date"); }

  /**
   * ExogTypeSelectionFloatType from f3Connector.csv (in EN: Float )
   */
  public key_ExogTypeSelectionFloatType = "ExogTypeSelectionFloatType";
  /**
   * ExogTypeSelectionFloatType from f3Connector.csv (in EN: Float )
   */
  public get ExogTypeSelectionFloatType(): string { return this.getStringDef(this.key_ExogTypeSelectionFloatType, "Float"); }

  /**
   * ExogTypeSelectionIntegerType from f3Connector.csv (in EN: Integer )
   */
  public key_ExogTypeSelectionIntegerType = "ExogTypeSelectionIntegerType";
  /**
   * ExogTypeSelectionIntegerType from f3Connector.csv (in EN: Integer )
   */
  public get ExogTypeSelectionIntegerType(): string { return this.getStringDef(this.key_ExogTypeSelectionIntegerType, "Integer"); }

  /**
   * ExogTypeSelectionPercentageType from f3Connector.csv (in EN: Percentage )
   */
  public key_ExogTypeSelectionPercentageType = "ExogTypeSelectionPercentageType";
  /**
   * ExogTypeSelectionPercentageType from f3Connector.csv (in EN: Percentage )
   */
  public get ExogTypeSelectionPercentageType(): string { return this.getStringDef(this.key_ExogTypeSelectionPercentageType, "Percentage"); }

  /**
   * ExogTypeSelectionStringType from f3Connector.csv (in EN: String )
   */
  public key_ExogTypeSelectionStringType = "ExogTypeSelectionStringType";
  /**
   * ExogTypeSelectionStringType from f3Connector.csv (in EN: String )
   */
  public get ExogTypeSelectionStringType(): string { return this.getStringDef(this.key_ExogTypeSelectionStringType, "String"); }

  /**
   * ExogTypeSelectionSmallIntType from f3Connector.csv (in EN: Small integer (+/- 32767) )
   */
  public key_ExogTypeSelectionSmallIntType = "ExogTypeSelectionSmallIntType";
  /**
   * ExogTypeSelectionSmallIntType from f3Connector.csv (in EN: Small integer (+/- 32767) )
   */
  public get ExogTypeSelectionSmallIntType(): string { return this.getStringDef(this.key_ExogTypeSelectionSmallIntType, "Small integer (+/- 32767)"); }

  /**
   * ExogTypeSelectionReferenceType from f3Connector.csv (in EN: Reference )
   */
  public key_ExogTypeSelectionReferenceType = "ExogTypeSelectionReferenceType";
  /**
   * ExogTypeSelectionReferenceType from f3Connector.csv (in EN: Reference )
   */
  public get ExogTypeSelectionReferenceType(): string { return this.getStringDef(this.key_ExogTypeSelectionReferenceType, "Reference"); }

  /**
   * ExogTypeSelectionShortNameColumnName from f3Connector.csv (in EN: Short name )
   */
  public key_ExogTypeSelectionShortNameColumnName = "ExogTypeSelectionShortNameColumnName";
  /**
   * ExogTypeSelectionShortNameColumnName from f3Connector.csv (in EN: Short name )
   */
  public get ExogTypeSelectionShortNameColumnName(): string { return this.getStringDef(this.key_ExogTypeSelectionShortNameColumnName, "Short name"); }

  /**
   * ExogTypeSelectionLongNameColumnName from f3Connector.csv (in EN: Long name )
   */
  public key_ExogTypeSelectionLongNameColumnName = "ExogTypeSelectionLongNameColumnName";
  /**
   * ExogTypeSelectionLongNameColumnName from f3Connector.csv (in EN: Long name )
   */
  public get ExogTypeSelectionLongNameColumnName(): string { return this.getStringDef(this.key_ExogTypeSelectionLongNameColumnName, "Long name"); }

  /**
   * ExogTypeSelectionDataTypeColumnName from f3Connector.csv (in EN: Type )
   */
  public key_ExogTypeSelectionDataTypeColumnName = "ExogTypeSelectionDataTypeColumnName";
  /**
   * ExogTypeSelectionDataTypeColumnName from f3Connector.csv (in EN: Type )
   */
  public get ExogTypeSelectionDataTypeColumnName(): string { return this.getStringDef(this.key_ExogTypeSelectionDataTypeColumnName, "Type"); }

  /**
   * ExogTypeSelectionDisplayUnitColumnName from f3Connector.csv (in EN: Display type )
   */
  public key_ExogTypeSelectionDisplayUnitColumnName = "ExogTypeSelectionDisplayUnitColumnName";
  /**
   * ExogTypeSelectionDisplayUnitColumnName from f3Connector.csv (in EN: Display type )
   */
  public get ExogTypeSelectionDisplayUnitColumnName(): string { return this.getStringDef(this.key_ExogTypeSelectionDisplayUnitColumnName, "Display type"); }

  /**
   * ExogTypeSelectionReferenceCategoryColumnName from f3Connector.csv (in EN: Reference category )
   */
  public key_ExogTypeSelectionReferenceCategoryColumnName = "ExogTypeSelectionReferenceCategoryColumnName";
  /**
   * ExogTypeSelectionReferenceCategoryColumnName from f3Connector.csv (in EN: Reference category )
   */
  public get ExogTypeSelectionReferenceCategoryColumnName(): string { return this.getStringDef(this.key_ExogTypeSelectionReferenceCategoryColumnName, "Reference category"); }

  /**
   * ExogenousAndSourceTypeMisMatchAdjustWithTransform from f3Connector.csv (in EN: The exogenous data type {0} of {1} and the source data type {2} do not match, add default tranformation? )
   */
  public key_ExogenousAndSourceTypeMisMatchAdjustWithTransform = "ExogenousAndSourceTypeMisMatchAdjustWithTransform";
  /**
   * ExogenousAndSourceTypeMisMatchAdjustWithTransform from f3Connector.csv (in EN: The exogenous data type {0} of {1} and the source data type {2} do not match, add default tranformation? )
   */
  public ExogenousAndSourceTypeMisMatchAdjustWithTransform(val1, val2, val3): string { return this.getStringDef("ExogenousAndSourceTypeMisMatchAdjustWithTransform", "The exogenous data type {0} of {1} and the source data type {2} do not match, add default tranformation?").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * ImportDataSetExistUseTimeStamp from f3Connector.csv (in EN: The dataset with name {0} already exists, continue with name {1}? )
   */
  public key_ImportDataSetExistUseTimeStamp = "ImportDataSetExistUseTimeStamp";
  /**
   * ImportDataSetExistUseTimeStamp from f3Connector.csv (in EN: The dataset with name {0} already exists, continue with name {1}? )
   */
  public ImportDataSetExistUseTimeStamp(val1, val2): string { return this.getStringDef("ImportDataSetExistUseTimeStamp", "The dataset with name {0} already exists, continue with name {1}?").replace("{1}", val2).replace("{0}", val1); }

  /**
   * MainMenu_SaveToModel from f3Connector.csv (in EN: Save in model )
   */
  public key_MainMenu_SaveToModel = "MainMenu_SaveToModel";
  /**
   * MainMenu_SaveToModel from f3Connector.csv (in EN: Save in model )
   */
  public get MainMenu_SaveToModel(): string { return this.getStringDef(this.key_MainMenu_SaveToModel, "Save in model"); }

  /**
   * MainMenu_LoadFromModel from f3Connector.csv (in EN: Load from model )
   */
  public key_MainMenu_LoadFromModel = "MainMenu_LoadFromModel";
  /**
   * MainMenu_LoadFromModel from f3Connector.csv (in EN: Load from model )
   */
  public get MainMenu_LoadFromModel(): string { return this.getStringDef(this.key_MainMenu_LoadFromModel, "Load from model"); }

  /**
   * LoadProjectFromModel_Header from f3Connector.csv (in EN: Select which project you want to use. )
   */
  public key_LoadProjectFromModel_Header = "LoadProjectFromModel_Header";
  /**
   * LoadProjectFromModel_Header from f3Connector.csv (in EN: Select which project you want to use. )
   */
  public get LoadProjectFromModel_Header(): string { return this.getStringDef(this.key_LoadProjectFromModel_Header, "Select which project you want to use."); }

  /**
   * EditProjectFromModel_Header from f3Connector.csv (in EN: Choose the name by which you want to save the import project )
   */
  public key_EditProjectFromModel_Header = "EditProjectFromModel_Header";
  /**
   * EditProjectFromModel_Header from f3Connector.csv (in EN: Choose the name by which you want to save the import project )
   */
  public get EditProjectFromModel_Header(): string { return this.getStringDef(this.key_EditProjectFromModel_Header, "Choose the name by which you want to save the import project"); }

  /**
   * EditProjectFromModel_NameIsRequired from f3Connector.csv (in EN: Please enter a valid name )
   */
  public key_EditProjectFromModel_NameIsRequired = "EditProjectFromModel_NameIsRequired";
  /**
   * EditProjectFromModel_NameIsRequired from f3Connector.csv (in EN: Please enter a valid name )
   */
  public get EditProjectFromModel_NameIsRequired(): string { return this.getStringDef(this.key_EditProjectFromModel_NameIsRequired, "Please enter a valid name"); }

  /**
   * EditProjectFromModel_Override from f3Connector.csv (in EN: An existing import project exists, do you want to override that one? )
   */
  public key_EditProjectFromModel_Override = "EditProjectFromModel_Override";
  /**
   * EditProjectFromModel_Override from f3Connector.csv (in EN: An existing import project exists, do you want to override that one? )
   */
  public get EditProjectFromModel_Override(): string { return this.getStringDef(this.key_EditProjectFromModel_Override, "An existing import project exists, do you want to override that one?"); }

  /**
   * EditProject_Published from f3Connector.csv (in EN: Published )
   */
  public key_EditProject_Published = "EditProject_Published";
  /**
   * EditProject_Published from f3Connector.csv (in EN: Published )
   */
  public get EditProject_Published(): string { return this.getStringDef(this.key_EditProject_Published, "Published"); }

  /**
   * EditProject_InsightNotSupported from f3Connector.csv (in EN: The file type {0} used by {1} is not supported by insights, do you want to continue? )
   */
  public key_EditProject_InsightNotSupported = "EditProject_InsightNotSupported";
  /**
   * EditProject_InsightNotSupported from f3Connector.csv (in EN: The file type {0} used by {1} is not supported by insights, do you want to continue? )
   */
  public EditProject_InsightNotSupported(val1, val2): string { return this.getStringDef("EditProject_InsightNotSupported", "The file type {0} used by {1} is not supported by insights, do you want to continue?").replace("{1}", val2).replace("{0}", val1); }

  /**
   * ImportSpecificationNotFound from f3Connector.csv (in EN: Could not find import specification with id {0} )
   */
  public key_ImportSpecificationNotFound = "ImportSpecificationNotFound";
  /**
   * ImportSpecificationNotFound from f3Connector.csv (in EN: Could not find import specification with id {0} )
   */
  public ImportSpecificationNotFound(val1): string { return this.getStringDef("ImportSpecificationNotFound", "Could not find import specification with id {0}").replace("{0}", val1); }

  /**
   * ImportSpecificationImportNotFound from f3Connector.csv (in EN: Could not find import project for import specification with id {0} )
   */
  public key_ImportSpecificationImportNotFound = "ImportSpecificationImportNotFound";
  /**
   * ImportSpecificationImportNotFound from f3Connector.csv (in EN: Could not find import project for import specification with id {0} )
   */
  public ImportSpecificationImportNotFound(val1): string { return this.getStringDef("ImportSpecificationImportNotFound", "Could not find import project for import specification with id {0}").replace("{0}", val1); }

  /**
   * ImportSpecificationNone from f3Connector.csv (in EN: No import specifications present )
   */
  public key_ImportSpecificationNone = "ImportSpecificationNone";
  /**
   * ImportSpecificationNone from f3Connector.csv (in EN: No import specifications present )
   */
  public get ImportSpecificationNone(): string { return this.getStringDef(this.key_ImportSpecificationNone, "No import specifications present"); }

  /**
   * ImportApiError from f3Connector.csv (in EN: {0} API error: {1} )
   */
  public key_ImportApiError = "ImportApiError";
  /**
   * ImportApiError from f3Connector.csv (in EN: {0} API error: {1} )
   */
  public ImportApiError(val1, val2): string { return this.getStringDef("ImportApiError", "{0} API error: {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * Service_ImportFailed from f3Connector.csv (in EN: Import failed because {0} )
   */
  public key_Service_ImportFailed = "Service_ImportFailed";
  /**
   * Service_ImportFailed from f3Connector.csv (in EN: Import failed because {0} )
   */
  public Service_ImportFailed(val1): string { return this.getStringDef("Service_ImportFailed", "Import failed because {0}").replace("{0}", val1); }

  /**
   * Service_ImportStart from f3Connector.csv (in EN: Start running import )
   */
  public key_Service_ImportStart = "Service_ImportStart";
  /**
   * Service_ImportStart from f3Connector.csv (in EN: Start running import )
   */
  public get Service_ImportStart(): string { return this.getStringDef(this.key_Service_ImportStart, "Start running import"); }

  /**
   * Service_ImportRun_Download from f3Connector.csv (in EN: Downloading files )
   */
  public key_Service_ImportRun_Download = "Service_ImportRun_Download";
  /**
   * Service_ImportRun_Download from f3Connector.csv (in EN: Downloading files )
   */
  public get Service_ImportRun_Download(): string { return this.getStringDef(this.key_Service_ImportRun_Download, "Downloading files"); }

  /**
   * Service_Import_Name from f3Connector.csv (in EN: Import.PointlogicHR )
   */
  public key_Service_Import_Name = "Service_Import_Name";
  /**
   * Service_Import_Name from f3Connector.csv (in EN: Import.PointlogicHR )
   */
  public get Service_Import_Name(): string { return this.getStringDef(this.key_Service_Import_Name, "Import.PointlogicHR"); }

  /**
   * Service_Import_DisplayName from f3Connector.csv (in EN: Import from Pointlogic HR )
   */
  public key_Service_Import_DisplayName = "Service_Import_DisplayName";
  /**
   * Service_Import_DisplayName from f3Connector.csv (in EN: Import from Pointlogic HR )
   */
  public get Service_Import_DisplayName(): string { return this.getStringDef(this.key_Service_Import_DisplayName, "Import from Pointlogic HR"); }

  /**
   * Service_Import_Description from f3Connector.csv (in EN: Import data into datasets in the model databases )
   */
  public key_Service_Import_Description = "Service_Import_Description";
  /**
   * Service_Import_Description from f3Connector.csv (in EN: Import data into datasets in the model databases )
   */
  public get Service_Import_Description(): string { return this.getStringDef(this.key_Service_Import_Description, "Import data into datasets in the model databases"); }

  /**
   * Service_Import_Files_SingleMatched from f3Connector.csv (in EN: Single file supplied, this file will be used for datasource {0} )
   */
  public key_Service_Import_Files_SingleMatched = "Service_Import_Files_SingleMatched";
  /**
   * Service_Import_Files_SingleMatched from f3Connector.csv (in EN: Single file supplied, this file will be used for datasource {0} )
   */
  public Service_Import_Files_SingleMatched(val1): string { return this.getStringDef("Service_Import_Files_SingleMatched", "Single file supplied, this file will be used for datasource {0}").replace("{0}", val1); }

  /**
   * Service_Import_Files_MatchFile from f3Connector.csv (in EN: File {0} supplied, this file will be used for datasource {0} )
   */
  public key_Service_Import_Files_MatchFile = "Service_Import_Files_MatchFile";
  /**
   * Service_Import_Files_MatchFile from f3Connector.csv (in EN: File {0} supplied, this file will be used for datasource {0} )
   */
  public Service_Import_Files_MatchFile(val1): string { return this.getStringDef("Service_Import_Files_MatchFile", "File {0} supplied, this file will be used for datasource {0}").replace("{0}", val1); }

  /**
   * ConnectDataSetToVariant from f3Connector.csv (in EN: Dataset {0} connected to varaint {1} )
   */
  public key_ConnectDataSetToVariant = "ConnectDataSetToVariant";
  /**
   * ConnectDataSetToVariant from f3Connector.csv (in EN: Dataset {0} connected to varaint {1} )
   */
  public ConnectDataSetToVariant(val1, val2): string { return this.getStringDef("ConnectDataSetToVariant", "Dataset {0} connected to varaint {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * CreatedCalculator from f3Connector.csv (in EN: Calculator created )
   */
  public key_CreatedCalculator = "CreatedCalculator";
  /**
   * CreatedCalculator from f3Connector.csv (in EN: Calculator created )
   */
  public get CreatedCalculator(): string { return this.getStringDef(this.key_CreatedCalculator, "Calculator created"); }

  /**
   * CreatingCalculator from f3Connector.csv (in EN: Creating calculator )
   */
  public key_CreatingCalculator = "CreatingCalculator";
  /**
   * CreatingCalculator from f3Connector.csv (in EN: Creating calculator )
   */
  public get CreatingCalculator(): string { return this.getStringDef(this.key_CreatingCalculator, "Creating calculator"); }

  /**
   * ComputeError from f3Connector.csv (in EN: Error with compute: {0} )
   */
  public key_ComputeError = "ComputeError";
  /**
   * ComputeError from f3Connector.csv (in EN: Error with compute: {0} )
   */
  public ComputeError(val1): string { return this.getStringDef("ComputeError", "Error with compute: {0}").replace("{0}", val1); }

  /**
   * ComputeInitialize from f3Connector.csv (in EN: Prepare to compute )
   */
  public key_ComputeInitialize = "ComputeInitialize";
  /**
   * ComputeInitialize from f3Connector.csv (in EN: Prepare to compute )
   */
  public get ComputeInitialize(): string { return this.getStringDef(this.key_ComputeInitialize, "Prepare to compute"); }

  /**
   * ComputeNotifyProgress from f3Connector.csv (in EN: Number of employees computed: {0} )
   */
  public key_ComputeNotifyProgress = "ComputeNotifyProgress";
  /**
   * ComputeNotifyProgress from f3Connector.csv (in EN: Number of employees computed: {0} )
   */
  public ComputeNotifyProgress(val1): string { return this.getStringDef("ComputeNotifyProgress", "Number of employees computed: {0}").replace("{0}", val1); }

  /**
   * ComputeFinished from f3Connector.csv (in EN: Compute finished )
   */
  public key_ComputeFinished = "ComputeFinished";
  /**
   * ComputeFinished from f3Connector.csv (in EN: Compute finished )
   */
  public get ComputeFinished(): string { return this.getStringDef(this.key_ComputeFinished, "Compute finished"); }

  /**
   * datasupplyHeader from f3Connector.csv (in EN: Supply data to import )
   */
  public key_datasupplyHeader = "datasupplyHeader";
  /**
   * datasupplyHeader from f3Connector.csv (in EN: Supply data to import )
   */
  public get datasupplyHeader(): string { return this.getStringDef(this.key_datasupplyHeader, "Supply data to import"); }

  /**
   * datasupplyNoFilesExpected from f3Connector.csv (in EN: There are no files selected for this import )
   */
  public key_datasupplyNoFilesExpected = "datasupplyNoFilesExpected";
  /**
   * datasupplyNoFilesExpected from f3Connector.csv (in EN: There are no files selected for this import )
   */
  public get datasupplyNoFilesExpected(): string { return this.getStringDef(this.key_datasupplyNoFilesExpected, "There are no files selected for this import"); }

  /**
   * datasupplyFileExpected from f3Connector.csv (in EN: There is 1 file needed for this import. )
   */
  public key_datasupplyFileExpected = "datasupplyFileExpected";
  /**
   * datasupplyFileExpected from f3Connector.csv (in EN: There is 1 file needed for this import. )
   */
  public get datasupplyFileExpected(): string { return this.getStringDef(this.key_datasupplyFileExpected, "There is 1 file needed for this import."); }

  /**
   * datasupplyNumberOfFilesExpected from f3Connector.csv (in EN: There are {0} files needed for this import. )
   */
  public key_datasupplyNumberOfFilesExpected = "datasupplyNumberOfFilesExpected";
  /**
   * datasupplyNumberOfFilesExpected from f3Connector.csv (in EN: There are {0} files needed for this import. )
   */
  public datasupplyNumberOfFilesExpected(val1): string { return this.getStringDef("datasupplyNumberOfFilesExpected", "There are {0} files needed for this import.").replace("{0}", val1); }

  /**
   * datasupplyStartImport from f3Connector.csv (in EN: Start import )
   */
  public key_datasupplyStartImport = "datasupplyStartImport";
  /**
   * datasupplyStartImport from f3Connector.csv (in EN: Start import )
   */
  public get datasupplyStartImport(): string { return this.getStringDef(this.key_datasupplyStartImport, "Start import"); }

  /**
   * datasupplyStatesHeader from f3Connector.csv (in EN: States of the imports )
   */
  public key_datasupplyStatesHeader = "datasupplyStatesHeader";
  /**
   * datasupplyStatesHeader from f3Connector.csv (in EN: States of the imports )
   */
  public get datasupplyStatesHeader(): string { return this.getStringDef(this.key_datasupplyStatesHeader, "States of the imports"); }

  /**
   * datasupplyStateInfo from f3Connector.csv (in EN: State:  )
   */
  public key_datasupplyStateInfo = "datasupplyStateInfo";
  /**
   * datasupplyStateInfo from f3Connector.csv (in EN: State:  )
   */
  public get datasupplyStateInfo(): string { return this.getStringDef(this.key_datasupplyStateInfo, "State: "); }

  /**
   * datasupplyStateQueuedHeader from f3Connector.csv (in EN: Queued )
   */
  public key_datasupplyStateQueuedHeader = "datasupplyStateQueuedHeader";
  /**
   * datasupplyStateQueuedHeader from f3Connector.csv (in EN: Queued )
   */
  public get datasupplyStateQueuedHeader(): string { return this.getStringDef(this.key_datasupplyStateQueuedHeader, "Queued"); }

  /**
   * datasupplyStateProcessingHeader from f3Connector.csv (in EN: Processing )
   */
  public key_datasupplyStateProcessingHeader = "datasupplyStateProcessingHeader";
  /**
   * datasupplyStateProcessingHeader from f3Connector.csv (in EN: Processing )
   */
  public get datasupplyStateProcessingHeader(): string { return this.getStringDef(this.key_datasupplyStateProcessingHeader, "Processing"); }

  /**
   * datasupplyStateDoneHeader from f3Connector.csv (in EN: Done )
   */
  public key_datasupplyStateDoneHeader = "datasupplyStateDoneHeader";
  /**
   * datasupplyStateDoneHeader from f3Connector.csv (in EN: Done )
   */
  public get datasupplyStateDoneHeader(): string { return this.getStringDef(this.key_datasupplyStateDoneHeader, "Done"); }

  /**
   * datasupplyStateErrorHeader from f3Connector.csv (in EN: Error )
   */
  public key_datasupplyStateErrorHeader = "datasupplyStateErrorHeader";
  /**
   * datasupplyStateErrorHeader from f3Connector.csv (in EN: Error )
   */
  public get datasupplyStateErrorHeader(): string { return this.getStringDef(this.key_datasupplyStateErrorHeader, "Error"); }

  /**
   * datasupplyStateFileNoLongerPresent from f3Connector.csv (in EN: File has been processed )
   */
  public key_datasupplyStateFileNoLongerPresent = "datasupplyStateFileNoLongerPresent";
  /**
   * datasupplyStateFileNoLongerPresent from f3Connector.csv (in EN: File has been processed )
   */
  public get datasupplyStateFileNoLongerPresent(): string { return this.getStringDef(this.key_datasupplyStateFileNoLongerPresent, "File has been processed"); }

  /**
   * datasupplyDetailsRequestedBy from f3Connector.csv (in EN: Requested by )
   */
  public key_datasupplyDetailsRequestedBy = "datasupplyDetailsRequestedBy";
  /**
   * datasupplyDetailsRequestedBy from f3Connector.csv (in EN: Requested by )
   */
  public get datasupplyDetailsRequestedBy(): string { return this.getStringDef(this.key_datasupplyDetailsRequestedBy, "Requested by"); }

  /**
   * datasupplyDetailsImportedFiles from f3Connector.csv (in EN: Imported files )
   */
  public key_datasupplyDetailsImportedFiles = "datasupplyDetailsImportedFiles";
  /**
   * datasupplyDetailsImportedFiles from f3Connector.csv (in EN: Imported files )
   */
  public get datasupplyDetailsImportedFiles(): string { return this.getStringDef(this.key_datasupplyDetailsImportedFiles, "Imported files"); }

  /**
   * datasupplyDatasetBasedOn from f3Connector.csv (in EN: Based on dataset: )
   */
  public key_datasupplyDatasetBasedOn = "datasupplyDatasetBasedOn";
  /**
   * datasupplyDatasetBasedOn from f3Connector.csv (in EN: Based on dataset: )
   */
  public get datasupplyDatasetBasedOn(): string { return this.getStringDef(this.key_datasupplyDatasetBasedOn, "Based on dataset:"); }

  /**
   * datasupplyDatasetEnterNewPlaceholder from f3Connector.csv (in EN: Enter new name... )
   */
  public key_datasupplyDatasetEnterNewPlaceholder = "datasupplyDatasetEnterNewPlaceholder";
  /**
   * datasupplyDatasetEnterNewPlaceholder from f3Connector.csv (in EN: Enter new name... )
   */
  public get datasupplyDatasetEnterNewPlaceholder(): string { return this.getStringDef(this.key_datasupplyDatasetEnterNewPlaceholder, "Enter new name..."); }

  /**
   * datasupplyDatasetAddToExisting from f3Connector.csv (in EN: Add to an existing dataset: )
   */
  public key_datasupplyDatasetAddToExisting = "datasupplyDatasetAddToExisting";
  /**
   * datasupplyDatasetAddToExisting from f3Connector.csv (in EN: Add to an existing dataset: )
   */
  public get datasupplyDatasetAddToExisting(): string { return this.getStringDef(this.key_datasupplyDatasetAddToExisting, "Add to an existing dataset:"); }

  /**
   * datasupplyDatasetAddToNew from f3Connector.csv (in EN: Add to a new dataset:  )
   */
  public key_datasupplyDatasetAddToNew = "datasupplyDatasetAddToNew";
  /**
   * datasupplyDatasetAddToNew from f3Connector.csv (in EN: Add to a new dataset:  )
   */
  public get datasupplyDatasetAddToNew(): string { return this.getStringDef(this.key_datasupplyDatasetAddToNew, "Add to a new dataset: "); }

  /**
   * datasupplyDatasetQueueSuccesfull from f3Connector.csv (in EN: Import added to queue this may take some time )
   */
  public key_datasupplyDatasetQueueSuccesfull = "datasupplyDatasetQueueSuccesfull";
  /**
   * datasupplyDatasetQueueSuccesfull from f3Connector.csv (in EN: Import added to queue this may take some time )
   */
  public get datasupplyDatasetQueueSuccesfull(): string { return this.getStringDef(this.key_datasupplyDatasetQueueSuccesfull, "Import added to queue this may take some time"); }

  /**
   * datasupplyDatasetNoDatasetSelected from f3Connector.csv (in EN: No dataset selected using default )
   */
  public key_datasupplyDatasetNoDatasetSelected = "datasupplyDatasetNoDatasetSelected";
  /**
   * datasupplyDatasetNoDatasetSelected from f3Connector.csv (in EN: No dataset selected using default )
   */
  public get datasupplyDatasetNoDatasetSelected(): string { return this.getStringDef(this.key_datasupplyDatasetNoDatasetSelected, "No dataset selected using default"); }

  /**
   * datasupplyDatasetNoDatasetName from f3Connector.csv (in EN: No dataset name provided using default name )
   */
  public key_datasupplyDatasetNoDatasetName = "datasupplyDatasetNoDatasetName";
  /**
   * datasupplyDatasetNoDatasetName from f3Connector.csv (in EN: No dataset name provided using default name )
   */
  public get datasupplyDatasetNoDatasetName(): string { return this.getStringDef(this.key_datasupplyDatasetNoDatasetName, "No dataset name provided using default name"); }

  /**
   * datasupplyMutationsSelectDate from f3Connector.csv (in EN: Select mutation date )
   */
  public key_datasupplyMutationsSelectDate = "datasupplyMutationsSelectDate";
  /**
   * datasupplyMutationsSelectDate from f3Connector.csv (in EN: Select mutation date )
   */
  public get datasupplyMutationsSelectDate(): string { return this.getStringDef(this.key_datasupplyMutationsSelectDate, "Select mutation date"); }

  /**
   * datasupplyShowDetails from f3Connector.csv (in EN: Details )
   */
  public key_datasupplyShowDetails = "datasupplyShowDetails";
  /**
   * datasupplyShowDetails from f3Connector.csv (in EN: Details )
   */
  public get datasupplyShowDetails(): string { return this.getStringDef(this.key_datasupplyShowDetails, "Details"); }

  /**
   * datasupplyShowDetailsHint from f3Connector.csv (in EN: Show details of the import )
   */
  public key_datasupplyShowDetailsHint = "datasupplyShowDetailsHint";
  /**
   * datasupplyShowDetailsHint from f3Connector.csv (in EN: Show details of the import )
   */
  public get datasupplyShowDetailsHint(): string { return this.getStringDef(this.key_datasupplyShowDetailsHint, "Show details of the import"); }

  /**
   * datasupplyRefreshHint from f3Connector.csv (in EN: Refresh import status )
   */
  public key_datasupplyRefreshHint = "datasupplyRefreshHint";
  /**
   * datasupplyRefreshHint from f3Connector.csv (in EN: Refresh import status )
   */
  public get datasupplyRefreshHint(): string { return this.getStringDef(this.key_datasupplyRefreshHint, "Refresh import status"); }

  /**
   * datasupplyStopProcessing from f3Connector.csv (in EN: Block from processing )
   */
  public key_datasupplyStopProcessing = "datasupplyStopProcessing";
  /**
   * datasupplyStopProcessing from f3Connector.csv (in EN: Block from processing )
   */
  public get datasupplyStopProcessing(): string { return this.getStringDef(this.key_datasupplyStopProcessing, "Block from processing"); }

  /**
   * datasupplyRemoveTask from f3Connector.csv (in EN: Remove processing )
   */
  public key_datasupplyRemoveTask = "datasupplyRemoveTask";
  /**
   * datasupplyRemoveTask from f3Connector.csv (in EN: Remove processing )
   */
  public get datasupplyRemoveTask(): string { return this.getStringDef(this.key_datasupplyRemoveTask, "Remove processing"); }

  /**
   * datasupplyContinueProcessing from f3Connector.csv (in EN: Continue processing )
   */
  public key_datasupplyContinueProcessing = "datasupplyContinueProcessing";
  /**
   * datasupplyContinueProcessing from f3Connector.csv (in EN: Continue processing )
   */
  public get datasupplyContinueProcessing(): string { return this.getStringDef(this.key_datasupplyContinueProcessing, "Continue processing"); }

  /**
   * menuDataSupply from f3Connector.csv (in EN: Data supply )
   */
  public key_menuDataSupply = "menuDataSupply";
  /**
   * menuDataSupply from f3Connector.csv (in EN: Data supply )
   */
  public get menuDataSupply(): string { return this.getStringDef(this.key_menuDataSupply, "Data supply"); }

  /**
   * menuDataSupplyImportStatus from f3Connector.csv (in EN: Upload status )
   */
  public key_menuDataSupplyImportStatus = "menuDataSupplyImportStatus";
  /**
   * menuDataSupplyImportStatus from f3Connector.csv (in EN: Upload status )
   */
  public get menuDataSupplyImportStatus(): string { return this.getStringDef(this.key_menuDataSupplyImportStatus, "Upload status"); }

  /**
   * menuDataSupplyImports from f3Connector.csv (in EN: Imports )
   */
  public key_menuDataSupplyImports = "menuDataSupplyImports";
  /**
   * menuDataSupplyImports from f3Connector.csv (in EN: Imports )
   */
  public get menuDataSupplyImports(): string { return this.getStringDef(this.key_menuDataSupplyImports, "Imports"); }

  /**
   * TransformTypeUnknown from f3Connector.csv (in EN: Unknown )
   */
  public key_TransformTypeUnknown = "TransformTypeUnknown";
  /**
   * TransformTypeUnknown from f3Connector.csv (in EN: Unknown )
   */
  public get TransformTypeUnknown(): string { return this.getStringDef(this.key_TransformTypeUnknown, "Unknown"); }

  /**
   * TransformTypeNone from f3Connector.csv (in EN: None )
   */
  public key_TransformTypeNone = "TransformTypeNone";
  /**
   * TransformTypeNone from f3Connector.csv (in EN: None )
   */
  public get TransformTypeNone(): string { return this.getStringDef(this.key_TransformTypeNone, "None"); }

  /**
   * TransformTypeEncryptedPGP from f3Connector.csv (in EN: PGP Encryption )
   */
  public key_TransformTypeEncryptedPGP = "TransformTypeEncryptedPGP";
  /**
   * TransformTypeEncryptedPGP from f3Connector.csv (in EN: PGP Encryption )
   */
  public get TransformTypeEncryptedPGP(): string { return this.getStringDef(this.key_TransformTypeEncryptedPGP, "PGP Encryption"); }

  /**
   * TransformTypeDecryptedPGP from f3Connector.csv (in EN: PGP Decryprion )
   */
  public key_TransformTypeDecryptedPGP = "TransformTypeDecryptedPGP";
  /**
   * TransformTypeDecryptedPGP from f3Connector.csv (in EN: PGP Decryprion )
   */
  public get TransformTypeDecryptedPGP(): string { return this.getStringDef(this.key_TransformTypeDecryptedPGP, "PGP Decryprion"); }

  /**
   * TransformTypeEncryptedAndSignedPGP from f3Connector.csv (in EN: Signed PGP Encryption )
   */
  public key_TransformTypeEncryptedAndSignedPGP = "TransformTypeEncryptedAndSignedPGP";
  /**
   * TransformTypeEncryptedAndSignedPGP from f3Connector.csv (in EN: Signed PGP Encryption )
   */
  public get TransformTypeEncryptedAndSignedPGP(): string { return this.getStringDef(this.key_TransformTypeEncryptedAndSignedPGP, "Signed PGP Encryption"); }

  /**
   * TransformTypeDecryptedAndVerifiyPGP from f3Connector.csv (in EN: Verifyed PGP Decryprion )
   */
  public key_TransformTypeDecryptedAndVerifiyPGP = "TransformTypeDecryptedAndVerifiyPGP";
  /**
   * TransformTypeDecryptedAndVerifiyPGP from f3Connector.csv (in EN: Verifyed PGP Decryprion )
   */
  public get TransformTypeDecryptedAndVerifiyPGP(): string { return this.getStringDef(this.key_TransformTypeDecryptedAndVerifiyPGP, "Verifyed PGP Decryprion"); }

  /**
   * TransformTypeCompressed from f3Connector.csv (in EN: Zipped )
   */
  public key_TransformTypeCompressed = "TransformTypeCompressed";
  /**
   * TransformTypeCompressed from f3Connector.csv (in EN: Zipped )
   */
  public get TransformTypeCompressed(): string { return this.getStringDef(this.key_TransformTypeCompressed, "Zipped"); }

  /**
   * TransformTypeExtract from f3Connector.csv (in EN: Unzipped )
   */
  public key_TransformTypeExtract = "TransformTypeExtract";
  /**
   * TransformTypeExtract from f3Connector.csv (in EN: Unzipped )
   */
  public get TransformTypeExtract(): string { return this.getStringDef(this.key_TransformTypeExtract, "Unzipped"); }

  /**
   * TransformTypeTextAppender from f3Connector.csv (in EN: Add text )
   */
  public key_TransformTypeTextAppender = "TransformTypeTextAppender";
  /**
   * TransformTypeTextAppender from f3Connector.csv (in EN: Add text )
   */
  public get TransformTypeTextAppender(): string { return this.getStringDef(this.key_TransformTypeTextAppender, "Add text"); }

  /**
   * TransformSelectionScreen from f3Connector.csv (in EN: Transformation selection )
   */
  public key_TransformSelectionScreen = "TransformSelectionScreen";
  /**
   * TransformSelectionScreen from f3Connector.csv (in EN: Transformation selection )
   */
  public get TransformSelectionScreen(): string { return this.getStringDef(this.key_TransformSelectionScreen, "Transformation selection"); }

  /**
   * TransformEditingScreen from f3Connector.csv (in EN: Transformation editing )
   */
  public key_TransformEditingScreen = "TransformEditingScreen";
  /**
   * TransformEditingScreen from f3Connector.csv (in EN: Transformation editing )
   */
  public get TransformEditingScreen(): string { return this.getStringDef(this.key_TransformEditingScreen, "Transformation editing"); }

  /**
   * TransformConfiguration from f3Connector.csv (in EN: Transformation configuration )
   */
  public key_TransformConfiguration = "TransformConfiguration";
  /**
   * TransformConfiguration from f3Connector.csv (in EN: Transformation configuration )
   */
  public get TransformConfiguration(): string { return this.getStringDef(this.key_TransformConfiguration, "Transformation configuration"); }

  /**
   * PgpPassphrase from f3Connector.csv (in EN: Passphrase )
   */
  public key_PgpPassphrase = "PgpPassphrase";
  /**
   * PgpPassphrase from f3Connector.csv (in EN: Passphrase )
   */
  public get PgpPassphrase(): string { return this.getStringDef(this.key_PgpPassphrase, "Passphrase"); }

  /**
   * PgpPublicKeyPath from f3Connector.csv (in EN: Location of public key )
   */
  public key_PgpPublicKeyPath = "PgpPublicKeyPath";
  /**
   * PgpPublicKeyPath from f3Connector.csv (in EN: Location of public key )
   */
  public get PgpPublicKeyPath(): string { return this.getStringDef(this.key_PgpPublicKeyPath, "Location of public key"); }

  /**
   * PgpPrivatekeyPath from f3Connector.csv (in EN: Location of private key )
   */
  public key_PgpPrivatekeyPath = "PgpPrivatekeyPath";
  /**
   * PgpPrivatekeyPath from f3Connector.csv (in EN: Location of private key )
   */
  public get PgpPrivatekeyPath(): string { return this.getStringDef(this.key_PgpPrivatekeyPath, "Location of private key"); }

  /**
   * ImportStep_AppendMutationInformation from f3Connector.csv (in EN: Add mutation information to temporary target tabel )
   */
  public key_ImportStep_AppendMutationInformation = "ImportStep_AppendMutationInformation";
  /**
   * ImportStep_AppendMutationInformation from f3Connector.csv (in EN: Add mutation information to temporary target tabel )
   */
  public get ImportStep_AppendMutationInformation(): string { return this.getStringDef(this.key_ImportStep_AppendMutationInformation, "Add mutation information to temporary target tabel"); }

  /**
   * ImportStep_AppendNewExogenousToSource from f3Connector.csv (in EN: Adding new exogenous to target table )
   */
  public key_ImportStep_AppendNewExogenousToSource = "ImportStep_AppendNewExogenousToSource";
  /**
   * ImportStep_AppendNewExogenousToSource from f3Connector.csv (in EN: Adding new exogenous to target table )
   */
  public get ImportStep_AppendNewExogenousToSource(): string { return this.getStringDef(this.key_ImportStep_AppendNewExogenousToSource, "Adding new exogenous to target table"); }

  /**
   * ImportStep_CheckMutationInformation from f3Connector.csv (in EN: Check mutafrefretion date not empty )
   */
  public key_ImportStep_CheckMutationInformation = "ImportStep_CheckMutationInformation";
  /**
   * ImportStep_CheckMutationInformation from f3Connector.csv (in EN: Check mutafrefretion date not empty )
   */
  public get ImportStep_CheckMutationInformation(): string { return this.getStringDef(this.key_ImportStep_CheckMutationInformation, "Check mutafrefretion date not empty"); }

  /**
   * ImportStep_ComputeVariant from f3Connector.csv (in EN: Compute variant )
   */
  public key_ImportStep_ComputeVariant = "ImportStep_ComputeVariant";
  /**
   * ImportStep_ComputeVariant from f3Connector.csv (in EN: Compute variant )
   */
  public get ImportStep_ComputeVariant(): string { return this.getStringDef(this.key_ImportStep_ComputeVariant, "Compute variant"); }

  /**
   * ImportStep_ExtendModelWithReferenceCategories from f3Connector.csv (in EN: Add new reference categories )
   */
  public key_ImportStep_ExtendModelWithReferenceCategories = "ImportStep_ExtendModelWithReferenceCategories";
  /**
   * ImportStep_ExtendModelWithReferenceCategories from f3Connector.csv (in EN: Add new reference categories )
   */
  public get ImportStep_ExtendModelWithReferenceCategories(): string { return this.getStringDef(this.key_ImportStep_ExtendModelWithReferenceCategories, "Add new reference categories"); }

  /**
   * ImportStep_ValidateClearingMutation from f3Connector.csv (in EN: Confirmation removal mutation data )
   */
  public key_ImportStep_ValidateClearingMutation = "ImportStep_ValidateClearingMutation";
  /**
   * ImportStep_ValidateClearingMutation from f3Connector.csv (in EN: Confirmation removal mutation data )
   */
  public get ImportStep_ValidateClearingMutation(): string { return this.getStringDef(this.key_ImportStep_ValidateClearingMutation, "Confirmation removal mutation data"); }

  /**
   * ImportStep_AppendImportedDataWithCurrentValues from f3Connector.csv (in EN: Add latest mutation data to data that will be imported )
   */
  public key_ImportStep_AppendImportedDataWithCurrentValues = "ImportStep_AppendImportedDataWithCurrentValues";
  /**
   * ImportStep_AppendImportedDataWithCurrentValues from f3Connector.csv (in EN: Add latest mutation data to data that will be imported )
   */
  public get ImportStep_AppendImportedDataWithCurrentValues(): string { return this.getStringDef(this.key_ImportStep_AppendImportedDataWithCurrentValues, "Add latest mutation data to data that will be imported"); }

  /**
   * ImportStep_CreateDataSetAndExogs from f3Connector.csv (in EN: Add new exogs to dataset defintion )
   */
  public key_ImportStep_CreateDataSetAndExogs = "ImportStep_CreateDataSetAndExogs";
  /**
   * ImportStep_CreateDataSetAndExogs from f3Connector.csv (in EN: Add new exogs to dataset defintion )
   */
  public get ImportStep_CreateDataSetAndExogs(): string { return this.getStringDef(this.key_ImportStep_CreateDataSetAndExogs, "Add new exogs to dataset defintion"); }

  /**
   * ImportStep_TemporaryDestinationAddIndex from f3Connector.csv (in EN: Adding index on temporary destination table for faster processing )
   */
  public key_ImportStep_TemporaryDestinationAddIndex = "ImportStep_TemporaryDestinationAddIndex";
  /**
   * ImportStep_TemporaryDestinationAddIndex from f3Connector.csv (in EN: Adding index on temporary destination table for faster processing )
   */
  public get ImportStep_TemporaryDestinationAddIndex(): string { return this.getStringDef(this.key_ImportStep_TemporaryDestinationAddIndex, "Adding index on temporary destination table for faster processing"); }

  /**
   * ImportStep_TemporaryDestinationTableAddSourceId from f3Connector.csv (in EN: Add source reference to temporary destination table )
   */
  public key_ImportStep_TemporaryDestinationTableAddSourceId = "ImportStep_TemporaryDestinationTableAddSourceId";
  /**
   * ImportStep_TemporaryDestinationTableAddSourceId from f3Connector.csv (in EN: Add source reference to temporary destination table )
   */
  public get ImportStep_TemporaryDestinationTableAddSourceId(): string { return this.getStringDef(this.key_ImportStep_TemporaryDestinationTableAddSourceId, "Add source reference to temporary destination table"); }

  /**
   * ImportStep_KeyDataCreateTable from f3Connector.csv (in EN: Create table for key fields )
   */
  public key_ImportStep_KeyDataCreateTable = "ImportStep_KeyDataCreateTable";
  /**
   * ImportStep_KeyDataCreateTable from f3Connector.csv (in EN: Create table for key fields )
   */
  public get ImportStep_KeyDataCreateTable(): string { return this.getStringDef(this.key_ImportStep_KeyDataCreateTable, "Create table for key fields"); }

  /**
   * ImportStep_KeyDataLoadFromNonPrimarySources from f3Connector.csv (in EN: Key fields from non-primairy sources )
   */
  public key_ImportStep_KeyDataLoadFromNonPrimarySources = "ImportStep_KeyDataLoadFromNonPrimarySources";
  /**
   * ImportStep_KeyDataLoadFromNonPrimarySources from f3Connector.csv (in EN: Key fields from non-primairy sources )
   */
  public get ImportStep_KeyDataLoadFromNonPrimarySources(): string { return this.getStringDef(this.key_ImportStep_KeyDataLoadFromNonPrimarySources, "Key fields from non-primairy sources"); }

  /**
   * ImportStep_KeyDataLoadFromPrimarySources from f3Connector.csv (in EN: Key fields from primairy sources )
   */
  public key_ImportStep_KeyDataLoadFromPrimarySources = "ImportStep_KeyDataLoadFromPrimarySources";
  /**
   * ImportStep_KeyDataLoadFromPrimarySources from f3Connector.csv (in EN: Key fields from primairy sources )
   */
  public get ImportStep_KeyDataLoadFromPrimarySources(): string { return this.getStringDef(this.key_ImportStep_KeyDataLoadFromPrimarySources, "Key fields from primairy sources"); }

  /**
   * ImportStep_LastExternalData from f3Connector.csv (in EN: Step used for testing )
   */
  public key_ImportStep_LastExternalData = "ImportStep_LastExternalData";
  /**
   * ImportStep_LastExternalData from f3Connector.csv (in EN: Step used for testing )
   */
  public get ImportStep_LastExternalData(): string { return this.getStringDef(this.key_ImportStep_LastExternalData, "Step used for testing"); }

  /**
   * ImportStep_KeyDataLoadForEnrichtment from f3Connector.csv (in EN: Load key data for enrichtments )
   */
  public key_ImportStep_KeyDataLoadForEnrichtment = "ImportStep_KeyDataLoadForEnrichtment";
  /**
   * ImportStep_KeyDataLoadForEnrichtment from f3Connector.csv (in EN: Load key data for enrichtments )
   */
  public get ImportStep_KeyDataLoadForEnrichtment(): string { return this.getStringDef(this.key_ImportStep_KeyDataLoadForEnrichtment, "Load key data for enrichtments"); }

  /**
   * ImportStep_LoadDataToEnrichtToTemporaryDestinationTable from f3Connector.csv (in EN: Add source data to temporary destination table for enrichtment )
   */
  public key_ImportStep_LoadDataToEnrichtToTemporaryDestinationTable = "ImportStep_LoadDataToEnrichtToTemporaryDestinationTable";
  /**
   * ImportStep_LoadDataToEnrichtToTemporaryDestinationTable from f3Connector.csv (in EN: Add source data to temporary destination table for enrichtment )
   */
  public get ImportStep_LoadDataToEnrichtToTemporaryDestinationTable(): string { return this.getStringDef(this.key_ImportStep_LoadDataToEnrichtToTemporaryDestinationTable, "Add source data to temporary destination table for enrichtment"); }

  /**
   * ImportStep_MoveDataToDestinationTable from f3Connector.csv (in EN: Move data to destination table )
   */
  public key_ImportStep_MoveDataToDestinationTable = "ImportStep_MoveDataToDestinationTable";
  /**
   * ImportStep_MoveDataToDestinationTable from f3Connector.csv (in EN: Move data to destination table )
   */
  public get ImportStep_MoveDataToDestinationTable(): string { return this.getStringDef(this.key_ImportStep_MoveDataToDestinationTable, "Move data to destination table"); }

  /**
   * ImportStep_MoveImportKeyDataToTemporaryDestination from f3Connector.csv (in EN: Move key fields to temporary destination table )
   */
  public key_ImportStep_MoveImportKeyDataToTemporaryDestination = "ImportStep_MoveImportKeyDataToTemporaryDestination";
  /**
   * ImportStep_MoveImportKeyDataToTemporaryDestination from f3Connector.csv (in EN: Move key fields to temporary destination table )
   */
  public get ImportStep_MoveImportKeyDataToTemporaryDestination(): string { return this.getStringDef(this.key_ImportStep_MoveImportKeyDataToTemporaryDestination, "Move key fields to temporary destination table"); }

  /**
   * ImportStep_PeriodDataLoadEmployeeStartEndDateStepToKeyData from f3Connector.csv (in EN: Extend key fields with start and end dates )
   */
  public key_ImportStep_PeriodDataLoadEmployeeStartEndDateStepToKeyData = "ImportStep_PeriodDataLoadEmployeeStartEndDateStepToKeyData";
  /**
   * ImportStep_PeriodDataLoadEmployeeStartEndDateStepToKeyData from f3Connector.csv (in EN: Extend key fields with start and end dates )
   */
  public get ImportStep_PeriodDataLoadEmployeeStartEndDateStepToKeyData(): string { return this.getStringDef(this.key_ImportStep_PeriodDataLoadEmployeeStartEndDateStepToKeyData, "Extend key fields with start and end dates"); }

  /**
   * ImportStep_RemoveUnchangedEntries from f3Connector.csv (in EN: Remove records with no changes from temporary destination table )
   */
  public key_ImportStep_RemoveUnchangedEntries = "ImportStep_RemoveUnchangedEntries";
  /**
   * ImportStep_RemoveUnchangedEntries from f3Connector.csv (in EN: Remove records with no changes from temporary destination table )
   */
  public get ImportStep_RemoveUnchangedEntries(): string { return this.getStringDef(this.key_ImportStep_RemoveUnchangedEntries, "Remove records with no changes from temporary destination table"); }

  /**
   * ImportStep_StartDateAddAsKey from f3Connector.csv (in EN: Temporary configure start date as key field )
   */
  public key_ImportStep_StartDateAddAsKey = "ImportStep_StartDateAddAsKey";
  /**
   * ImportStep_StartDateAddAsKey from f3Connector.csv (in EN: Temporary configure start date as key field )
   */
  public get ImportStep_StartDateAddAsKey(): string { return this.getStringDef(this.key_ImportStep_StartDateAddAsKey, "Temporary configure start date as key field"); }

  /**
   * ImportStep_StartDateRemoveAsKey from f3Connector.csv (in EN: Remove start date as key field )
   */
  public key_ImportStep_StartDateRemoveAsKey = "ImportStep_StartDateRemoveAsKey";
  /**
   * ImportStep_StartDateRemoveAsKey from f3Connector.csv (in EN: Remove start date as key field )
   */
  public get ImportStep_StartDateRemoveAsKey(): string { return this.getStringDef(this.key_ImportStep_StartDateRemoveAsKey, "Remove start date as key field"); }

  /**
   * ImportStep_CreateTemporaryDestinationTable from f3Connector.csv (in EN: Create temporary source table )
   */
  public key_ImportStep_CreateTemporaryDestinationTable = "ImportStep_CreateTemporaryDestinationTable";
  /**
   * ImportStep_CreateTemporaryDestinationTable from f3Connector.csv (in EN: Create temporary source table )
   */
  public get ImportStep_CreateTemporaryDestinationTable(): string { return this.getStringDef(this.key_ImportStep_CreateTemporaryDestinationTable, "Create temporary source table"); }

  /**
   * ImportStep_AppendTransformationColumnsForColumns from f3Connector.csv (in EN: Include column transformations on {0} )
   */
  public key_ImportStep_AppendTransformationColumnsForColumns = "ImportStep_AppendTransformationColumnsForColumns";
  /**
   * ImportStep_AppendTransformationColumnsForColumns from f3Connector.csv (in EN: Include column transformations on {0} )
   */
  public ImportStep_AppendTransformationColumnsForColumns(val1): string { return this.getStringDef("ImportStep_AppendTransformationColumnsForColumns", "Include column transformations on {0}").replace("{0}", val1); }

  /**
   * ImportStep_AppendTransformationColumnsForRows from f3Connector.csv (in EN: Include row transformations on {0} )
   */
  public key_ImportStep_AppendTransformationColumnsForRows = "ImportStep_AppendTransformationColumnsForRows";
  /**
   * ImportStep_AppendTransformationColumnsForRows from f3Connector.csv (in EN: Include row transformations on {0} )
   */
  public ImportStep_AppendTransformationColumnsForRows(val1): string { return this.getStringDef("ImportStep_AppendTransformationColumnsForRows", "Include row transformations on {0}").replace("{0}", val1); }

  /**
   * ImportStep_DropTemporaryTablesPrepare from f3Connector.csv (in EN: Prepare for removing temporary tables )
   */
  public key_ImportStep_DropTemporaryTablesPrepare = "ImportStep_DropTemporaryTablesPrepare";
  /**
   * ImportStep_DropTemporaryTablesPrepare from f3Connector.csv (in EN: Prepare for removing temporary tables )
   */
  public get ImportStep_DropTemporaryTablesPrepare(): string { return this.getStringDef(this.key_ImportStep_DropTemporaryTablesPrepare, "Prepare for removing temporary tables"); }

  /**
   * ImportStep_DropTemporaryTable from f3Connector.csv (in EN: Remove temporary table {0} )
   */
  public key_ImportStep_DropTemporaryTable = "ImportStep_DropTemporaryTable";
  /**
   * ImportStep_DropTemporaryTable from f3Connector.csv (in EN: Remove temporary table {0} )
   */
  public ImportStep_DropTemporaryTable(val1): string { return this.getStringDef("ImportStep_DropTemporaryTable", "Remove temporary table {0}").replace("{0}", val1); }

  /**
   * ImportStep_PeriodDataAppendDevidedValueForRowImport from f3Connector.csv (in EN: Add period value calculations for row {0} )
   */
  public key_ImportStep_PeriodDataAppendDevidedValueForRowImport = "ImportStep_PeriodDataAppendDevidedValueForRowImport";
  /**
   * ImportStep_PeriodDataAppendDevidedValueForRowImport from f3Connector.csv (in EN: Add period value calculations for row {0} )
   */
  public ImportStep_PeriodDataAppendDevidedValueForRowImport(val1): string { return this.getStringDef("ImportStep_PeriodDataAppendDevidedValueForRowImport", "Add period value calculations for row {0}").replace("{0}", val1); }

  /**
   * ImportStep_PeriodDataAppendDevidedValueForColumnImport from f3Connector.csv (in EN: Add period value calculations for column {0} )
   */
  public key_ImportStep_PeriodDataAppendDevidedValueForColumnImport = "ImportStep_PeriodDataAppendDevidedValueForColumnImport";
  /**
   * ImportStep_PeriodDataAppendDevidedValueForColumnImport from f3Connector.csv (in EN: Add period value calculations for column {0} )
   */
  public ImportStep_PeriodDataAppendDevidedValueForColumnImport(val1): string { return this.getStringDef("ImportStep_PeriodDataAppendDevidedValueForColumnImport", "Add period value calculations for column {0}").replace("{0}", val1); }

  /**
   * ImportStep_MoveRowBasedDataToTemporaryDestinationTable from f3Connector.csv (in EN: Move row data {0} to temporary destination table )
   */
  public key_ImportStep_MoveRowBasedDataToTemporaryDestinationTable = "ImportStep_MoveRowBasedDataToTemporaryDestinationTable";
  /**
   * ImportStep_MoveRowBasedDataToTemporaryDestinationTable from f3Connector.csv (in EN: Move row data {0} to temporary destination table )
   */
  public ImportStep_MoveRowBasedDataToTemporaryDestinationTable(val1): string { return this.getStringDef("ImportStep_MoveRowBasedDataToTemporaryDestinationTable", "Move row data {0} to temporary destination table").replace("{0}", val1); }

  /**
   * ImportStep_MovePrimarySourcesToTemporaryDestinationTable from f3Connector.csv (in EN: Move primary data {0} to temporary target table )
   */
  public key_ImportStep_MovePrimarySourcesToTemporaryDestinationTable = "ImportStep_MovePrimarySourcesToTemporaryDestinationTable";
  /**
   * ImportStep_MovePrimarySourcesToTemporaryDestinationTable from f3Connector.csv (in EN: Move primary data {0} to temporary target table )
   */
  public ImportStep_MovePrimarySourcesToTemporaryDestinationTable(val1): string { return this.getStringDef("ImportStep_MovePrimarySourcesToTemporaryDestinationTable", "Move primary data {0} to temporary target table").replace("{0}", val1); }

  /**
   * ImportStep_MoveNonPrimarySourceDataToTemporaryDestinationTable from f3Connector.csv (in EN: Move non-primary data {0} to temporary target table )
   */
  public key_ImportStep_MoveNonPrimarySourceDataToTemporaryDestinationTable = "ImportStep_MoveNonPrimarySourceDataToTemporaryDestinationTable";
  /**
   * ImportStep_MoveNonPrimarySourceDataToTemporaryDestinationTable from f3Connector.csv (in EN: Move non-primary data {0} to temporary target table )
   */
  public ImportStep_MoveNonPrimarySourceDataToTemporaryDestinationTable(val1): string { return this.getStringDef("ImportStep_MoveNonPrimarySourceDataToTemporaryDestinationTable", "Move non-primary data {0} to temporary target table").replace("{0}", val1); }

  /**
   * ImportStep_LoadExternalSourcesInDatabaseIntoStagingTable from f3Connector.csv (in EN: Move source data {0} to staging table )
   */
  public key_ImportStep_LoadExternalSourcesInDatabaseIntoStagingTable = "ImportStep_LoadExternalSourcesInDatabaseIntoStagingTable";
  /**
   * ImportStep_LoadExternalSourcesInDatabaseIntoStagingTable from f3Connector.csv (in EN: Move source data {0} to staging table )
   */
  public ImportStep_LoadExternalSourcesInDatabaseIntoStagingTable(val1): string { return this.getStringDef("ImportStep_LoadExternalSourcesInDatabaseIntoStagingTable", "Move source data {0} to staging table").replace("{0}", val1); }

  /**
   * ImportStep_LoadExternalDataSourceSingleTableValidate from f3Connector.csv (in EN: External data {0} validation )
   */
  public key_ImportStep_LoadExternalDataSourceSingleTableValidate = "ImportStep_LoadExternalDataSourceSingleTableValidate";
  /**
   * ImportStep_LoadExternalDataSourceSingleTableValidate from f3Connector.csv (in EN: External data {0} validation )
   */
  public ImportStep_LoadExternalDataSourceSingleTableValidate(val1): string { return this.getStringDef("ImportStep_LoadExternalDataSourceSingleTableValidate", "External data {0} validation").replace("{0}", val1); }

  /**
   * ImportStep_LoadExternalDataSourceSingleTableValidateBefore from f3Connector.csv (in EN: External data {0} usage validation )
   */
  public key_ImportStep_LoadExternalDataSourceSingleTableValidateBefore = "ImportStep_LoadExternalDataSourceSingleTableValidateBefore";
  /**
   * ImportStep_LoadExternalDataSourceSingleTableValidateBefore from f3Connector.csv (in EN: External data {0} usage validation )
   */
  public ImportStep_LoadExternalDataSourceSingleTableValidateBefore(val1): string { return this.getStringDef("ImportStep_LoadExternalDataSourceSingleTableValidateBefore", "External data {0} usage validation").replace("{0}", val1); }

  /**
   * ImportStep_LoadExternalDataSourceSingleTableIntoDatabase from f3Connector.csv (in EN: Load external data {0} into the database )
   */
  public key_ImportStep_LoadExternalDataSourceSingleTableIntoDatabase = "ImportStep_LoadExternalDataSourceSingleTableIntoDatabase";
  /**
   * ImportStep_LoadExternalDataSourceSingleTableIntoDatabase from f3Connector.csv (in EN: Load external data {0} into the database )
   */
  public ImportStep_LoadExternalDataSourceSingleTableIntoDatabase(val1): string { return this.getStringDef("ImportStep_LoadExternalDataSourceSingleTableIntoDatabase", "Load external data {0} into the database").replace("{0}", val1); }

  /**
   * ImportStep_ExtendModelWithReferentialValuesFromImportShared from f3Connector.csv (in EN: Update references from external sources shared step )
   */
  public key_ImportStep_ExtendModelWithReferentialValuesFromImportShared = "ImportStep_ExtendModelWithReferentialValuesFromImportShared";
  /**
   * ImportStep_ExtendModelWithReferentialValuesFromImportShared from f3Connector.csv (in EN: Update references from external sources shared step )
   */
  public get ImportStep_ExtendModelWithReferentialValuesFromImportShared(): string { return this.getStringDef(this.key_ImportStep_ExtendModelWithReferentialValuesFromImportShared, "Update references from external sources shared step"); }

  /**
   * ImportStep_ExtendModelWithReferentialValues from f3Connector.csv (in EN: Extend references with data from {0} )
   */
  public key_ImportStep_ExtendModelWithReferentialValues = "ImportStep_ExtendModelWithReferentialValues";
  /**
   * ImportStep_ExtendModelWithReferentialValues from f3Connector.csv (in EN: Extend references with data from {0} )
   */
  public ImportStep_ExtendModelWithReferentialValues(val1): string { return this.getStringDef("ImportStep_ExtendModelWithReferentialValues", "Extend references with data from {0}").replace("{0}", val1); }

  /**
   * ImportStep_CreateImportVariablesTable from f3Connector.csv (in EN: Create table for import variables )
   */
  public key_ImportStep_CreateImportVariablesTable = "ImportStep_CreateImportVariablesTable";
  /**
   * ImportStep_CreateImportVariablesTable from f3Connector.csv (in EN: Create table for import variables )
   */
  public get ImportStep_CreateImportVariablesTable(): string { return this.getStringDef(this.key_ImportStep_CreateImportVariablesTable, "Create table for import variables"); }

  /**
   * ImportStep_FinalDataAddClusteredIndex from f3Connector.csv (in EN: Add clusted index on key fields )
   */
  public key_ImportStep_FinalDataAddClusteredIndex = "ImportStep_FinalDataAddClusteredIndex";
  /**
   * ImportStep_FinalDataAddClusteredIndex from f3Connector.csv (in EN: Add clusted index on key fields )
   */
  public get ImportStep_FinalDataAddClusteredIndex(): string { return this.getStringDef(this.key_ImportStep_FinalDataAddClusteredIndex, "Add clusted index on key fields"); }

  /**
   * ImportStep_CloseDataProviders from f3Connector.csv (in EN: Closing connections to datasources )
   */
  public key_ImportStep_CloseDataProviders = "ImportStep_CloseDataProviders";
  /**
   * ImportStep_CloseDataProviders from f3Connector.csv (in EN: Closing connections to datasources )
   */
  public get ImportStep_CloseDataProviders(): string { return this.getStringDef(this.key_ImportStep_CloseDataProviders, "Closing connections to datasources"); }

  /**
   * ImportStepExecute from f3Connector.csv (in EN: {0}/{1} Execute: {2} )
   */
  public key_ImportStepExecute = "ImportStepExecute";
  /**
   * ImportStepExecute from f3Connector.csv (in EN: {0}/{1} Execute: {2} )
   */
  public ImportStepExecute(val1, val2, val3): string { return this.getStringDef("ImportStepExecute", "{0}/{1} Execute: {2}").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * ImportStepSkipped from f3Connector.csv (in EN: {0}/{1} Skipped: {2} )
   */
  public key_ImportStepSkipped = "ImportStepSkipped";
  /**
   * ImportStepSkipped from f3Connector.csv (in EN: {0}/{1} Skipped: {2} )
   */
  public ImportStepSkipped(val1, val2, val3): string { return this.getStringDef("ImportStepSkipped", "{0}/{1} Skipped: {2}").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * NoConnectorRequired from f3Connector.csv (in EN: No connector required )
   */
  public key_NoConnectorRequired = "NoConnectorRequired";
  /**
   * NoConnectorRequired from f3Connector.csv (in EN: No connector required )
   */
  public get NoConnectorRequired(): string { return this.getStringDef(this.key_NoConnectorRequired, "No connector required"); }

  /**
   * StaticImportRunnerCanNotBeEditted from f3Connector.csv (in EN: This type of import can not be editted )
   */
  public key_StaticImportRunnerCanNotBeEditted = "StaticImportRunnerCanNotBeEditted";
  /**
   * StaticImportRunnerCanNotBeEditted from f3Connector.csv (in EN: This type of import can not be editted )
   */
  public get StaticImportRunnerCanNotBeEditted(): string { return this.getStringDef(this.key_StaticImportRunnerCanNotBeEditted, "This type of import can not be editted"); }


}
